import React from 'react';
import "./styleNetError.css";
function NetworkError() {
    return (
        <div className='netError '>
            <div className='netError-container'>
                <div className='netError-containt-container'>
                    <div className='netError-class-image-container'></div>
                    <h1 className='dayHeading'>Ooops! No Internet</h1>
                    <p>Internet connection not found<br />
                        Check the connection</p>
                    <button onClick={() => window.history.back()}>Go Back</button>
                </div>
            </div>
        </div>
    )
}

export default NetworkError
