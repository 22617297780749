import React, { useState } from 'react'
import "./styleAddPhase.css"
import { setAddPhase, setApiUrl } from '../../../../erpStore/actions'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';

function AddMilestone() {
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.data.project.projectId)
  const apiUrl = useSelector((state) => state.data.apiUrl)
  const stringHeaders = window.localStorage.getItem('myHeaders');
  const storedHeaders = JSON.parse(stringHeaders);
  const [mObj, setMObj] = useState(
    {
      milestoneName: "",
      milestoneStartDate: "",
      project: projectId,
      milestoneEndDate: "",
      milestoneDays: ""
    }

  );
  const calculateDays = (start, end) => {
    var startDate = new Date(start);
    var endDate = new Date(end);

    var difference = endDate - startDate;
    var daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));
    if (daysDifference < 0) {
      return "0";
    }
    setMObj({
      ...mObj,
      milestoneDays: daysDifference + ""
    })
  }
  const submiMilestone = () => {
    if (mObj.milestoneName === "") {
      toast.error('Please Input valid Milestone name', { autoClose: 2000 })
      return
    } else if (mObj.milestoneStartDate === "") {
      toast.error('Please Enter valid start date', { autoClose: 2000 })
      return
    } else if (mObj.milestoneEndDate === "") {
      toast.error('Please Enter valid end date', { autoClose: 2000 })
      return
    } else if (mObj.milestoneDays === "") {
      toast.error('Enter valid days', { autoClose: 2000 })
      return
    }
    axios.post(`${apiUrl}project/milestone/add`, mObj, {
      headers: storedHeaders
    })
      .then((response) => {
        if (response.data) {
          dispatch(setAddPhase("none"))
          toast.success('Added Successfully', { autoClose: 2000 })
        }
      })
      .catch((error) => toast.error('try again', { autoClose: 2000 }))
  }
  return (
    <div className='add-phase-form-container'>
      <div className='add-phase-form'>
        <div className='add-phase-heading'>Add Milestone</div>
        <div className='phase-form'>
          <input className='phase-header' value={mObj.milestoneName}
            onChange={(event) => setMObj((prev) => ({ ...prev, milestoneName: event.target.value }))}
            type='text' placeholder='Enter Milestone Name' />
          <div className='phase-description'>
            <label>Description</label>
            <textarea value=""></textarea>
          </div>
          <div className='phase-dates'>
            <div className='phase-date-part'>
              <label>Start Date</label>
              <input type='date'
                value={mObj.milestoneStartDate}
                onChange={(event) => setMObj((prev) => ({ ...prev, milestoneStartDate: event.target.value }))}
                placeholder='DD-MM-YYYY'></input>
            </div>
            <div className='phase-date-part'>
              <label>End Date</label>
              <input type='date'
                value={mObj.milestoneEndDate}
                onChange={(event) => {
                  const endDate = event.target.value;
                  calculateDays(mObj.milestoneStartDate, endDate);
                  setMObj((prev) => ({ ...prev, milestoneEndDate: endDate }));
                }}
                placeholder='DD-MM-YYYY'></input>
            </div>
          </div>
          <div className='phase-day-count'>
            <label>Number of days</label>
            <input type='number'
              value={mObj.milestoneDays}
              onChange={(event) => {
                const strVal = event.target.value + "";
                if (strVal.length > 3) {
                  return
                }
                setMObj((prev) => ({ ...prev, milestoneDays: event.target.value }))
              }} disabled={true} />
          </div>
        </div>
        <div className='add-phase-buttons'>
          <div className='add-phase-button' onClick={submiMilestone}>Add Milestone</div>
          <div className='cancel-phase-button' onClick={() => {
            dispatch(setAddPhase("none"))
          }}>Cancel</div>
        </div>
      </div>
    </div>
  )
}

export default AddMilestone
