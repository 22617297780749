import React from 'react';
import './styleNavbar.css';
function Navbar() {
  const routeHome = () => {
    window.localStorage.clear()
    window.location.href = '/'
  }
  return (
    <div className='navbar' id='navbar' >
      <div className='navbar-container'>
        <a onClick={routeHome} href='/'><div className='logo'>
          <img id='logoImg' src="/images/logo.png" alt="logo" />
        </div>
        </a>
      </div>
    </div>
  )
}

export default Navbar;
