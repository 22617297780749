import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Calendar, globalizeLocalizer } from 'react-big-calendar';
import globalize from 'globalize';
import './LeaveCalenderView.css';
import WeekView from '../../Weekview/WeekView';
import moment from 'moment';
import axios from 'axios';
import BasicTable from '../../Dayview/DayView';
import { useDispatch, useSelector } from 'react-redux';
import { setView, setAddTask, setFullDate, setDate, setSearchedDate, setSearchedMonth, setWeekData, setMonthData, setTaskEmp, setAddHolidayFlag, setAddLeaveFlag, setLeaveDayViewFlag, setOnLeaveDate, setHolidayListFlag, setLeaveList, setLeaveManageHolidayDay } from '../../../../erpStore/actions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Calendar as RouteCalender } from 'primereact/calendar'
import LeaveDayView from '../LeaveDayView/LeaveDayView';

const localizer = globalizeLocalizer(globalize);

function LeveCalenderView() {
    const culture = globalize.culture('en-US');
    culture.calendar.firstDay = 1;
    const dispatch = useDispatch();
    const empView = useSelector((state) => state.data.empView)
    const view = useSelector((state) => state.data.view)
    const taskUpdate = useSelector((state) => state.data.newTask)
    const viewM = useSelector((state) => state.data.view)
    const self = useSelector((state) => state.data.selfData)
    const empInfo = useSelector((state) => state.data.empInfo)
    const taskEmp = useSelector((state) => state.data.taskEmp)
    // const selfInfo = empView ? empInfo : self
    const selfInfo = empView ? taskEmp : self
    const url = useSelector((state) => state.data.apiUrl)
    const [dayView, setDayView] = useState(true);
    const monthData = useSelector((state) => state.data.monthData);
    // const [monthData, setMonthData] = useState({});
    const searchedTaskMonth = useSelector((state) => state.data.searchedTaskMonth)
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const [isDateRoute, setIsDateRoute] = useState(false)
    const mothDateRouteWrapper = useRef(null)
    let nDate = []
    const [holidayId, setHolidayId] = useState()
    const [leaveDecFlag, setLeaveDecFlag] = useState(false)
    const leaveDayViewFlag = useSelector((state)=> state.data.leaveDayViewFlag)
    const leaveList = useSelector((state) => state.data.leaveList)
    const [temLeaveList, setTempLeaveList] = useState([...leaveList])
    const leaveManageHolidayDay  = useSelector((state) => state.data.leaveManageHolidayDay)

    
    useEffect(() => {
        dispatch(setTaskEmp(taskEmp))
    }, [])

    useEffect(() => {
        if (window.localStorage.getItem("myHeaders") === "") {
            navigate('/login')
            return
        }

        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        setLoader(true)
        if (parseInt(nDate[3]) && parseInt(getMonthNumber(nDate[1]))) {
            axios.post(`${url}leave/data-month`, { "monthId": parseInt(getMonthNumber(nDate[1])), "year": parseInt(nDate[3]) }, {
                headers: storedHeaders,
            })
                .then((response) => {
                    setLoader(false)
                    dispatch(setMonthData(response.data))
                })
                .catch((error) => {
                    if (error.response && error.response.status === 500) {
                        navigate("/server-error")
                    }
                })
            return
        }
    }, [view])

    useEffect(() => {
        function handleOutsideClick(event) {
            if (mothDateRouteWrapper && mothDateRouteWrapper.current && !mothDateRouteWrapper.current.contains(event.target)) {
                setIsDateRoute(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])
    const getMonthNumber = (monthName) => {
        if (monthName) {
            const monthMoment = moment().month(monthName).startOf('month');
            return monthMoment.format('MM');
        }
    };

    const navigateNextMonth = (onNavigate) => {
        setLoader(true)
        onNavigate("NEXT")
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        if (nDate) {
            axios.post(`${url}leave/data-month`, { 'year': parseInt(getMonthNumber(nDate[1])) + 1 === 13 ? parseInt(nDate[3]) + 1 : parseInt(nDate[3]), "monthId": parseInt(getMonthNumber(nDate[1])) + 1 === 13 ? 1 : parseInt(getMonthNumber(nDate[1])) + 1 }, { headers: storedHeaders })
                .then((response) => {
                    setLoader(false)
                    dispatch(setSearchedMonth([]))
                    dispatch(setMonthData(response.data))
                })
                .catch((error) => {
                    if (error.response && error.response.status === 500) {
                        navigate("/server-error")
                    }
                })
        }
    }

    const navigatePrevMonth = (onNavigate) => {
        setLoader(true)
        onNavigate("PREV")
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        if (nDate) {
            axios.post(`${url}leave/data-month`, { "monthId": parseInt(getMonthNumber(nDate[1])) - 1 === 0 ? 12 : parseInt(getMonthNumber(nDate[1])) - 1, "year": parseInt(getMonthNumber(nDate[1])) - 1 === 0 ? parseInt(nDate[3]) - 1 : parseInt(nDate[3]) }, {
                headers: storedHeaders,
            })
                .then((response) => {
                    setLoader(false)
                    dispatch(setMonthData(response.data))
                })
                .catch((error) => {
                    if (error.response && error.response.status === 500) {
                        navigate("/server-error")
                    }
                })
        }
    }
    const onNavigateToday = () => {
        const date = new Date();
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const dayName = days[date.getUTCDay()];
        const day = String(date.getUTCDate()).padStart(2, '0');
        const monthName = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        const tempOnLeave = `${dayName}, ${day} ${monthName} ${year}`;
        dispatch(setOnLeaveDate(tempOnLeave))
        dispatch(setView('month'))
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        nDate = new Date().toString().split(" ")
        if (nDate) {
            setLoader(true)
            axios.post(`${url}leave/data-month`, { "monthId": parseInt(getMonthNumber(nDate[1])), "year": parseInt(nDate[3]) }, { headers: storedHeaders })
                .then((response) => {
                    setLoader(false)
                    dispatch(setSearchedMonth([]))
                    dispatch(setMonthData(response.data))
                })
                .catch((error) => {
                    if (error.response && error.response.status === 500) {
                        navigate("/server-error")
                        setLoader(false)
                    }
                })
        }
    }

    //comparison of date to date is less than joining date for add task condition 
    const isDateLessThanCurrent = (date) => {
        const givenDate = moment(date.value); // Convert string to Date object
        const currentDate = moment(selfInfo.empJoiningDate); // Get current date
        // Compare the dates
        return givenDate < currentDate;
    };

    
    const handleSearchEmpTrns = (e) => {
        const searchQuery = e.target.value.toLowerCase();
        const results = temLeaveList?.filter(employee =>
            employee?.empFullName && employee?.empFullName.toLowerCase().includes(searchQuery)
        );
        if (e.target.value === '') {
            dispatch(setLeaveList(temLeaveList));
        } else {
            dispatch(setLeaveList(results));
        }
    };
   
    const renderCustomMonthCell = (date) => {
        if (!date) {
            return <div className='rbc-day-bg'>No date</div>;
        }
        const dateString = date.value + "";
        const dateSplit = dateString?.split(" ");
        const calendarDay = dateSplit[2].toString();
        const newDate = new Date();
        const listDate = newDate.toString().split(" ");
        const getToday = listDate[2].toString();
        const currentMonth = dateSplit[1].toString() + dateSplit[3].toString();
        const todayMonth = listDate[1].toString() + listDate[3].toString();
        const isToday = calendarDay === getToday && currentMonth === todayMonth;
        const gtDate = (parseInt(getMonthNumber(dateSplit[1])) === parseInt(getMonthNumber(listDate[1])) && parseInt(dateSplit[2]) < parseInt(listDate[2]));
        let monthNum = getMonthNumber(dateSplit[1]);
        const fullDate = dateSplit[3] + "-" + monthNum + "-" + dateSplit[2].padStart(2, '0');
        if (fullDate === (dateSplit[3] + "-" + monthNum + "-" + 15)) {
            dispatch(setSearchedDate(fullDate))
        }
        const taskList = searchedTaskMonth?.length > 0 ? searchedTaskMonth[fullDate] : monthData[fullDate];
        
        if (!labelData[1].includes(dateSplit[1])) {
            if (dateSplit[0] === "Sun") {
                document.querySelector(".rbc-month-header").children[6].setAttribute("style", "min-width:4.8%")
                return <div className='rbc-day-bg off-day sunday-block'><div className='dateContainer'>{dateSplit[2]}</div></div>;
            }
            return <div className='rbc-day-bg off-day'></div>;
        }

        if (dateSplit[0] === "Sun") {
            return (
                <div className='rbc-day-bg sunday-block leaveMang-sunday-block'>
                    <div className='dateContainer' onClick={() => {
                        if (isDateLessThanCurrent(date)) {
                            toast.warning("No task available before employee's joining date");
                            return
                        }
                        const previousDate = moment(date.value)
                        // dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                        setDayView(!dayView);
                        dispatch(setView('day'))
                        window.sessionStorage.setItem("view", 'day')
                    }}>{dateSplit[2]}</div>
                    <div className='innerDiv-addTask-sunday' onClick={(e) => {
                        if (isDateLessThanCurrent(date)) {
                            toast.warning("No task available before employee's joining date");
                            return
                        }
                        const previousDate = moment(date.value)
                        // dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                        // dispatch(setAddTask(true))
                        dispatch(setDate(fullDate))
                    }} style={{ cursor: 'pointer' }}>
                        <div className='add-task-container-sunday' style={{ display: isDateLessThanCurrent(date) ? "none" : "flex" }}>
                            <p className='leaveMngSunday'>Sunday</p>
                        </div>
                    </div>
                </div>

            );
        }
        if (loader) {
            return (
                <div className='rbc-day-bg'>
                    <div className='innerDiv-day'
                    // style={{
                    //     background: isToday ? '#FFF6DF' : 'none', opacity: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "1" : "1"
                    // }}
                    ><div className='innerDiv-addTask' style={{ borderBottom: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "null" : "null", cursor: 'pointer' }}>
                            <div className='add-task-container' style={{ display: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "flex" : "flex" }}>
                                <div class="loader-month loader-addTask"></div>
                            </div>
                        </div>
                        <div className='innerDivTask task-loader' onClick={() => {
                            const previousDate = moment(date.value)
                            // dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                            window.sessionStorage.setItem("view", 'day')
                        }}>
                            <div class="loader-month"></div>
                            <div class="loader-month"></div>
                            <div class="loader-month"></div>
                        </div>
                    </div>
                </div>
            );
        }
        if (true) {
            return (
                <div className='rbc-day-bg'>
                    <div className='innerDiv-day innerDiv-leaveCal' onClick={() => {
                        const date = new Date(fullDate);
                        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        const dayName = days[date.getUTCDay()];
                        const day = String(date.getUTCDate()).padStart(2, '0');
                        const monthName = months[date.getUTCMonth()];
                        const year = date.getUTCFullYear();
                        const tempOnLeave = `${dayName}, ${day} ${monthName} ${year}`;
                        dispatch(setOnLeaveDate(tempOnLeave))
                    }}
                        style={{
                            background: isToday ? '#FFF6DF' : taskList?.length >= 0 ? (Object?.keys(taskList?.holidayData)?.length !== 0  ? '#E3E3E3' : 'none') : null, opacity: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "1" : "1",
                            border: "1.px solid grey"
                        }}
                    >
                        {  Object?.keys(taskList?.holidayData)?.length === 0 &&
                            <div className='innerDiv-addTask' onClick={(e) => {
                                const date = new Date(fullDate);
                                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                                const dayName = days[date.getUTCDay()];
                                const day = String(date.getUTCDate()).padStart(2, '0');
                                const monthName = months[date.getUTCMonth()];
                                const year = date.getUTCFullYear();
                                const tempOnLeave = `${dayName}, ${day} ${monthName} ${year}`;
                                const previousDate = moment(date.value)
                                dispatch(setOnLeaveDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                                dispatch(setOnLeaveDate(tempOnLeave))

                                dispatch(setAddLeaveFlag(true))
                            }} style={{ cursor: 'pointer' }}>
                                <div className='add-task-container' style={{ display: isDateLessThanCurrent(date) ? "none" : "flex" }}>
                                    <svg width="10" height="10" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.4 3C0.4 1.56406 1.56406 0.4 3 0.4C4.43594 0.4 5.6 1.56406 5.6 3C5.6 4.43594 4.43594 5.6 3 5.6C1.56406 5.6 0.4 4.43594 0.4 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM3 1.63636C3.15062 1.63636 3.27273 1.72358 3.27273 1.83117V2.72727H4.16883C4.27642 2.72727 4.36364 2.84938 4.36364 3C4.36364 3.15062 4.27642 3.27273 4.16883 3.27273H3.27273V4.16883C3.27273 4.27642 3.15062 4.36364 3 4.36364C2.84938 4.36364 2.72727 4.27642 2.72727 4.16883V3.27273H1.83117C1.72358 3.27273 1.63636 3.15062 1.63636 3C1.63636 2.84938 1.72358 2.72727 1.83117 2.72727H2.72727V1.83117C2.72727 1.72358 2.84938 1.63636 3 1.63636Z" fill="#263238" />
                                    </svg>
                                    <p>Add Leaves</p>
                                </div>
                            </div>
                        }
                        <div className='innerDivTask-count' style={{ display: 'flex', justifyContent: Object.keys(taskList?.holidayData).length !== 0 ? 'center' : 'center', alignItems: Object.keys(taskList?.holidayData)?.length !== 0 ? 'center' : 'center' }} onClick={() => {
                            const previousDate = moment(date.value)
                            // dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                            setDayView(!dayView);
                            dispatch(setView('day'))
                            window.sessionStorage.setItem("view", 'day')
                        }}>
                            {taskList ?
                                <div className='taskListCount-container' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: Object.keys(taskList?.holidayData)?.length === 0 ?  'flex-start' : 'center', alignItems: 'center', flexDirection: 'column',}}>
                                    { (typeof taskList?.holidayData === 'object') && Object?.keys(taskList?.holidayData).length === 0 &&
                                        <div className='taskDivCount' style={{ background: "rgba(234, 22, 22, 0.15)" }}>
                                            <div className='taskDiv-half-container'
                                                onMouseEnter={() => {
                                                }}
                                                onMouseLeave={() => {
                                                }}>
                                                <div title="Assign" className="status" style={{ backgroundColor: "rgba(234, 22, 22, 1)" }}></div>
                                                <div className='task-holder'>
                                                    <p>Absent</p>
                                                </div>
                                            </div>
                                            <div className='priority-container'>
                                                <p className='priority'>{taskList?.absentCount}</p>
                                            </div>
                                        </div>
                                    }

                                    { (typeof taskList?.holidayData === 'object') && Object.keys(taskList?.holidayData)?.length === 0 &&
                                        <div className='taskDivCount' style={{ background: " rgba(98, 63, 173, 0.15)" }}>
                                            <div className='taskDiv-half-container'>
                                                <div title="Assign" className="status" style={{ background: " rgba(98, 63, 173, 1)" }}></div>
                                                <div className='task-holder'>
                                                    <p>Half Days</p>
                                                </div>
                                            </div>
                                            <div className='priority-container'>
                                                <p className='priority'>{taskList?.halfDayEmployeeCount}</p>
                                            </div>
                                        </div>
                                    }


                                    {Object.keys(taskList?.holidayData).length === 0 &&
                                        <div className='taskDivCount' style={{ background: "rgba(1, 159, 55, 0.15)" }}>
                                            <div className='taskDiv-half-container'>
                                                <div title="Assign" className="status" style={{ background: "rgba(1, 159, 55, 1)" }}></div>
                                                <div className='task-holder'>
                                                    <p>Present</p>
                                                </div>
                                            </div>
                                            <div className='priority-container'>
                                                <p className='priority'>{taskList.presentCount}</p>
                                            </div>
                                        </div>
                                    }

                                    {Object.keys(taskList?.holidayData).length !== 0 &&
                                        <div className='taskDivCount holidayStatusDay' style={{ background: "#4B555A" , height: 'auto'}} onMouseEnter={() => {
                                            setLeaveManageHolidayDay(taskList?.holidayData)
                                       }}>
                                            <div className='taskDiv-half-container'>
                                                <div className='task-holder' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div title={`${taskList.holidayData.holidayDescription}`}>{taskList?.holidayData?.holidayTitle.length > 15 ? taskList?.holidayData?.holidayTitle.substring(0, 15) + '...' : taskList?.holidayData?.holidayTitle}</div>
                                                    {/* <svg width="3" height="12" viewBox="0 0 3 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                        // dispatch(setView('month'))
                                                    }}>
                                                        <circle cx="1.4" cy="10.6" r="1.4" transform="rotate(-90 1.4 10.6)" fill="white" />
                                                        <circle cx="1.4" cy="6.00039" r="1.4" transform="rotate(-90 1.4 6.00039)" fill="white" />
                                                        <circle cx="1.4" cy="1.40078" r="1.4" transform="rotate(-90 1.4 1.40078)" fill="white" />
                                                    </svg> */}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* {isToday ?
                                        <div className='taskDivCount' style={{ background: "rgba(0, 95, 182, 0.15)" }}>
                                            <div className='taskDiv-half-container'>
                                                <div title="Assign" className="status" style={{ backgroundColor: getStatusColor("done") }}></div>
                                                <div className='task-holder'>
                                                    <p title="Assign">In Progress</p>
                                                </div>
                                            </div>
                                            <div className='priority-container'>
                                                <p className='priority'>{taskList.taskProgress}</p>
                                            </div>
                                        </div>
                                        : null
                                    } */}
                                </div>
                                : null}
                        </div>
                        {/* 
                        { 
                            <div className='hoverAbsentList'>
                                <div className='hoverAbsentListScroller'>

                                </div>
                            </div>
                        } */}

                    </div>
                </div>
            );
        }

    };

    const handleDayView = () => {

        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Get the day of the week, date, month, and year
        const dayOfWeek = weekdays[new Date().getDay()];
        const day = new Date().getDate();
        const month = months[new Date().getMonth()];
        const year = new Date().getFullYear();

        // Format the date string
        const formattedDate = `${dayOfWeek}, ${day} ${month} ${year}`;
        setDayView(!dayView);
        dispatch(setOnLeaveDate(formattedDate))
        dispatch(setView('day'))
        window.sessionStorage.setItem("view", 'day')
    };

    const onViewChange = (onView, view, date) => {
        dispatch(setView(view))
        window.sessionStorage.setItem("view", view)
        onView(view)
    }


    const [routeDate, setRouteDate] = useState(new Date())
    const handleDateRoute = (e, onNavigate) => {
        setRouteDate(e.target.value)
        setLoader(true)
        onNavigate('DATE', e.target.value)
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        nDate = moment(e.target.value)
        const month = moment(e.target.value).month() + 1;
        const year = moment(e.target.value).year();
        setIsDateRoute(false)
        if (nDate) {
            axios.post(`${url}leave/data-month`, { "year": year, "monthId": month }, {
                headers: storedHeaders,
            })
                .then((response) => {
                    setLoader(false)
                    dispatch(setMonthData(response.data))

                })
                .catch((error) => {
                    if (error.response && error.response.status === 500) {
                        navigate("/server-error")
                    }
                })
        }
    }

    let labelData = [];
    const CustomMonthCell = React.memo(renderCustomMonthCell);

    const CustomToolbar = (toolbar) => {
        const { label, onView, onNavigate, view, date } = toolbar;
        const month = label.split(' ');
        labelData = month;
        const dateString = toolbar.date + "";
        const dateSplit = dateString.split(" ");
        nDate = dateSplit
        const calendarDay = dateSplit[2].toString();
        const newDate = new Date();
        const listDate = newDate.toString().split(" ");
        const getToday = listDate[2].toString();
        const currentMonth = dateSplit[1].toString() + dateSplit[3].toString();
        const todayMonth = listDate[1].toString() + listDate[3].toString();
        const isToday = calendarDay === getToday && currentMonth === todayMonth;
        const weekNumberInMonth = moment(toolbar.date).isoWeek() - moment(toolbar.date).startOf('month').isoWeek() + 1;

        if (viewM === "month") {
            return (
                <div className='monthView-toolbar'>
                    <div className='label'>
                        <svg onClick={() => navigatePrevMonth(onNavigate)} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.19231 0L6 0.916667L1.73077 5.5L6 10.0833L5.19231 11L4.76837e-07 5.5L5.19231 0Z" fill="#FF8402" />
                        </svg>
                        &nbsp;
                        <div className='label-container' onClick={() => setIsDateRoute(true)}>{month[1]}, {month[0]}</div>
                        &nbsp;
                        <svg onClick={() => navigateNextMonth(onNavigate)} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.807692 0L0 0.916667L4.26923 5.5L0 10.0833L0.807692 11L6 5.5L0.807692 0Z" fill="#FF8402" />
                        </svg>
                        <div className='date-holder-dashboard month-dateRoute'>
                            {
                                isDateRoute ? <div className='calender-holder-dashboard' ref={mothDateRouteWrapper}>
                                    <RouteCalender value={routeDate} inline view='month' unstyled={false} onChange={(e) => handleDateRoute(e, onNavigate)} />
                                </div> : null
                            }

                        </div>
                    </div>
                    <div className='viewBtns'>
                        <button className='todayView-btn' onClick={() => {
                            dispatch(setHolidayListFlag(true))
                        }}>Holiday</button>
                        <button className='todayView-btn' style={{ background: isToday ? '#FFF6DF' : 'none' }} onClick={() => onNavigateToday()}>Today</button>
                        <button className='monthView-btn monthView-btn-view' style={{ background: view === "month" ? '#FFF6DF' : 'none' }} onClick={() => onViewChange(onView, 'month', date)}>Month</button>
                        {/* <button className='weekView-btn monthView-btn-view' style={{ background: view === "week" ? '#FFF6DF' : 'none' }} onClick={() => onViewChange(onView, 'week', date)}>Week</button> */}
                        <button className="dayView-btn monthView-btn-view" style={{ background: view === "day" ? '#FFF6DF' : 'none' }} onClick={handleDayView}>Day</button>
                    </div>
                </div>
            );
        }
    };


    return (
        <div className="LeaveCalMainView">
            <div className='leaveManagement-header'>
                <div className='leaveManHeading'>
                    Leaves Management
                </div>
                {  (viewM === "day" &&  leaveManageHolidayDay !== null)  && 
                    <div className='holidayStatusLeaveManageDay'>
                        <div className='leavemanageholidayinfo' title={leaveManageHolidayDay.holidayDescription}>
                            {leaveManageHolidayDay.holidayTitle.length > 15 ? leaveManageHolidayDay.holidayTitle.substring(0, 15) + '...' : leaveManageHolidayDay.holidayTitle}
                        </div>
                    </div>
                }
                <div className='headerSerchBtnDiv'>
                        <div className='serachLeave'>
                            { viewM === "day" && 
                                <input type='text' placeholder='search employee' onChange={(e) => handleSearchEmpTrns(e)}/>
                            }
                        </div>
                        
                    <div className='LeaveOptionBtn'>
                        <button onClick={() => {
                            dispatch(setAddHolidayFlag(true))
                            const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            // Get the day of the week, date, month, and year
                            const dayOfWeek = weekdays[new Date().getDay()];
                            const day = new Date().getDate();
                            const month = months[new Date().getMonth()];
                            const year = new Date().getFullYear();
                            // Format the date string
                            const formattedDate = `${dayOfWeek}, ${day} ${month} ${year}`;
                            setDayView(!dayView);
                            if( view === 'month'){
                                dispatch(setOnLeaveDate(formattedDate))
                            }
                        }}>Add Holiday</button>
                        <button onClick={() => {
                            dispatch(setAddLeaveFlag(true))
                            const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            // Get the day of the week, date, month, and year
                            const dayOfWeek = weekdays[new Date().getDay()];
                            const day = new Date().getDate();
                            const month = months[new Date().getMonth()];
                            const year = new Date().getFullYear();
                            // Format the date string
                            const formattedDate = `${dayOfWeek}, ${day} ${month} ${year}`;
                            setDayView(!dayView);
                            if( view === 'month'){
                                dispatch(setOnLeaveDate(formattedDate))
                            }
                        }}>Add Leave</button>
                    </div>
                </div>
            </div>
            <div className='Leavesviews'>
                {viewM !== 'day' ?
                    <Calendar
                        localizer={localizer}
                        events={[]}
                        startAccessor="start"
                        endAccessor="end"
                        cultures={{ 'en-US': culture }}
                        components={{
                            toolbar: CustomToolbar,
                            month: {
                                dateCellWrapper: CustomMonthCell,
                            },
                            week: {
                                header: null,
                            },
                        }}
                    /> : <LeaveDayView />
                }
            </div>
        </div>
    );

}

export default LeveCalenderView