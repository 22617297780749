import React from 'react'
import { useDispatch } from 'react-redux'
import { setView } from '../../../../erpStore/actions'
import { useSelector } from 'react-redux'

function AttendenceDayView() {

  const dispatch = useDispatch()
  const selfInfo = useSelector((state) => state.data.selfData)

  // const handlePrevDate = () => {
  //   const currentDate = moment(fdSplit[1]);
  //   dispatch(setLoader(true))
  //   const previousDate = currentDate.subtract(1, 'day');
  //   dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date().toString().padStart(2, "0") + " " + previousDate.format('MMM') + " " + previousDate.year()))
  //   window.sessionStorage.setItem('date', previousDate.format('dddd') + ", " + previousDate.date().toString().padStart(2, "0") + " " + previousDate.format('MMM') + " " + previousDate.year())
  //   setRouteDate(previousDate._d)
  // }
  // const handleNextDate = () => {
  //     const currentDate = moment(fdSplit[1]);
  //     dispatch(setLoader(true))
  //     const nextDate = currentDate.add(1, 'day')
  //     dispatch(setFullDate(nextDate.format('dddd') + ", " + nextDate.date().toString().padStart(2, "0") + " " + nextDate.format('MMM') + " " + nextDate.year()))
  //     window.sessionStorage.setItem('date', nextDate.format('dddd') + ", " + nextDate.date().toString().padStart(2, "0") + " " + nextDate.format('MMM') + " " + nextDate.year())
  //     setRouteDate(nextDate._d)
  // }

  return (
    <div className='LeaveDayViewMainContainer'>
      <div className='leaveDayViewCalenderHeader'>
      <div className='dayViewDateSeletor LeavedayViewDateSelector'>
            {/* <svg onClick={handlePrevDate} width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }}>
                <path d="M5.19231 0L6 0.916667L1.73077 5.5L6 10.0833L5.19231 11L4.76837e-07 5.5L5.19231 0Z" fill="#FF8402" />
            </svg>
            <div className='date-holder-dashboard'>
                <p onClick={() => setIsDateRoute(true)}>{fullDateF}</p>
                {
                    isDateRoute ? <div className='calender-holder-dashboard' ref={dateRouteRef}>
                        <Calendar value={routeDate} inline onChange={(e) => {
                            handleRouteDate(e)
                            setRouteDate(e.target.value)
                        }} unstyled={false} />
                    </div> : null
                }
            </div>
            <svg onClick={handleNextDate} width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }}>
                <path d="M0.807692 0L0 0.916667L4.26923 5.5L0 10.0833L0.807692 11L6 5.5L0.807692 0Z" fill="#FF8402" />
            </svg> */}
        </div>
        <div className="viewBtns viewDayView-btn">
          {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="refresh-icon"
            // onClick={
            //   () => {
            //     setSortBy('')
            //     setRotating(true)
            //     const splitFullDate = getFullDate.split(" ")
            //     const mNumber = getMonthNumber(splitFullDate[2])
            //     const fullDateF = splitFullDate[3] + "-" + (mNumber.length > 1 ? mNumber : '0' + mNumber) + "-" + splitFullDate[1]
            //     setFullDate(fullDateF)
            //     setFullDateForDue(fullDateF)
            //     axios.post(`${apiUrl}task/card/get/day`, {
            //       "empId": selfInfoEmp.empId,
            //       "date": fullDateF
            //     }
            //       , { headers: storedHeaders })
            //       .then(response => {
            //         if (response) {
            //           dispatch(setInitialDataObject(response))
            //           dispatch(setReset(0))
            //           setRotating(false)
            //           setTaskOfListPut([])
            //           setPriorityOption(false)
            //           setStatusOption(false)
            //           setProjectOption(false)
            //           setDueDateOption(false)
            //           setSelectAllCheckBox(false)
            //           var completedTaskCount = response.data?.filter(taskObj => taskObj.taskDone === 1).length;
            //           setTotalAnalyatics(response.data.length)
            //           setCompleteAnalytics(completedTaskCount);
            //           setAllTask(response.data)
            //           setTaskSeletedCount(0)
            //           setTaskListMultiplePut([])
            //           dispatch(setDispatchAllTask(response.data))
            //           setAllTask(response.data)
            //         }
            //       })
            //       .catch(err => {})
            //   }
            // }
            >
            <path d="M1 4V10H7" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23 20V14H17" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.49 8.99959C19.9828 7.56637 19.1209 6.28499 17.9845 5.27501C16.8482 4.26502 15.4745 3.55935 13.9917 3.22385C12.5089 2.88834 10.9652 2.93393 9.50481 3.35636C8.04437 3.77879 6.71475 4.5643 5.64 5.63959L1 9.99959M23 13.9996L18.36 18.3596C17.2853 19.4349 15.9556 20.2204 14.4952 20.6428C13.0348 21.0652 11.4911 21.1108 10.0083 20.7753C8.52547 20.4398 7.1518 19.7342 6.01547 18.7242C4.87913 17.7142 4.01717 16.4328 3.51 14.9996" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg> */}
          <button style={{ backgroundColor: '#FFF6DF'}} >Today</button>
          <button onClick={() => {
            dispatch(setView('month'))
            window.sessionStorage.setItem("view", 'month')
          }} style={{ marginLeft: '30px' }}>Month</button>
          <button style={{ background: '#FFF6DF', marginLeft: '3px' }}>Day</button>
        </div>
      </div>
        <div className='leaveDayViewTableView'>
          <div className='leaveTableViewHead'>
            <div className='sr-scanner'>sr</div>
            <div className='empId-scanner'>empId</div>
            <div className='nameMyRow-scanner'>Name</div>
            <div className='InTime-scanner'>In Time</div>
            <div className='outTime-scanner'>Out Time</div>
            <div className='status-scanner'>Status</div>
          </div>
          <div className='leaveDayViewTbody'>
            <div className='myRow'>
              <div className='leaveTableViewHeadTbody'>
                <div className='sr-scanner'>1</div>
                <div className='empId-scanner'>
                  INC008
                </div>
                <div className='nameMyRow-scanner'>
                  <div className='taskOwnerImage'><img src={selfInfo.empImg}  alt=''/></div>
                  <div>{selfInfo.empFullName}</div> 
                </div>
                <div className='InTime-scanner'>12:24 AM</div>
                <div className='outTime-scanner'>12:24 PM</div>
                <div className='status-scanner'>Present</div>
              </div>
            </div>
            <div className='myRow'>
              <div className='leaveTableViewHeadTbody' style={{background: '#FFDFDF'}}>
                <div className='sr-scanner'>1</div>
                <div className='empId-scanner'>
                  INC008
                </div>
                <div className='nameMyRow-scanner'>
                  <div className='taskOwnerImage'><img src={selfInfo.empImg}  alt=''/></div>
                  <div>{selfInfo.empFullName}</div> 
                </div>
                <div className='InTime-scanner'>12:24 AM</div>
                <div className='outTime-scanner'>12:24 PM</div>
                <div className='status-scanner'>Present</div>
              </div>
            </div>
            <div className='myRow'>
              <div className='leaveTableViewHeadTbody'>
                <div className='sr-scanner'>1</div>
                <div className='empId-scanner'>
                  INC008
                </div>
                <div className='nameMyRow-scanner'>
                  <div className='taskOwnerImage'><img src={selfInfo.empImg}  alt=''/></div>
                  <div>{selfInfo.empFullName}</div> 
                </div>
                <div className='InTime-scanner'>12:24 AM</div>
                <div className='outTime-scanner'>12:24 PM</div>
                <div className='status-scanner'>Present</div>
              </div>
            </div>
            <div className='endOfList'>End of the list Load More </div>
          </div>
      </div>
    </div>
  )
}

export default AttendenceDayView