import React, { useEffect, useState } from 'react';
import "./styleLogsView.css";
import { useDispatch, useSelector } from 'react-redux';
import { setGetLogs } from '../../../erpStore/actions';
import axios from 'axios';
import noLogs from "../../../assets/images/noLogs.jpg";
import { getMessaging, getToken } from "firebase/messaging";
import moment from 'moment';
import { messaging } from '../../../firebase/FirebaseContactData';
// // import firebase from 'firebase/app';
// import { initializeApp } from 'firebase/app';
// import { getMessaging, onMessage, getToken } from 'firebase/messaging';
// import { current } from '@reduxjs/toolkit';
// // importScripts('https://www.gstatic.com/firebasejs/9.6.7/firebase-app.js');
// // importScripts('https://www.gstatic.com/firebasejs/9.6.7/firebase-messaging.js');

function LogsView() {
    const selfInfo = useSelector((state) => state.data.selfData);
    const empInfo = useSelector((state) => state.data.empInfo);
    const route = useSelector((state) => state.data.route);
    const dispatch = useDispatch();
    const apiUrl = useSelector((state) => state.data.apiUrl);
    const [logs, setLogs] = useState([]);
    const getFullDate = useSelector((state) => state.data.fullDate);
    const [clicked, setClicked] = useState(true);

    const getMonthNumber = (monthName) => {
        const monthMoment = moment().month(monthName).startOf('month');
        return monthMoment.format('MM');
    };
    const dummyTasks = [
        { id: 1, name: 'Task 1' },
        { id: 2, name: 'Task 2' },
        { id: 3, name: 'Task 3' },
        { id: 4, name: 'Task 4' },
        { id: 4, name: 'Task 4' },
        { id: 4, name: 'Task 4' },
        { id: 4, name: 'Task 4' },

    ];

    useEffect(() => {
        const fetchData = async () => {
            const splitFullDate = getFullDate.split(" ")
            const mNumber = getMonthNumber(splitFullDate[2])
            const fullDateF = splitFullDate[3] + "-" + (mNumber.length > 1 ? mNumber : '0' + mNumber) + "-" + (splitFullDate[1].length > 1 ? splitFullDate[1] : '0' + splitFullDate[1])
            try {
                const stringHeaders = window.localStorage.getItem('myHeaders');
                const storedHeaders = JSON.parse(stringHeaders);
                var empId = route === 'mytask' ? selfInfo.empId : empInfo.empId;
                const response = await axios.post(`${apiUrl}task/user/logs/for-day`, { empId: empId, date: fullDateF }, {
                    headers: storedHeaders
                });
                if (response && response.data) {
                    setLogs(response.data);
                    setClicked(false)
                }
            } catch (error) {
            }


        };

        fetchData();
    }, []);
    const timeCalculator = (lastUpdated) => {
        const parsedDate = new Date(lastUpdated);
        const formattedDate = `${parsedDate.getUTCFullYear()}-${(parsedDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getUTCDate().toString().padStart(2, '0')}T${parsedDate.getUTCHours().toString().padStart(2, '0')}:${parsedDate.getUTCMinutes().toString().padStart(2, '0')}:${parsedDate.getUTCSeconds().toString().padStart(2, '0')}Z`;
        const currentDateTime = new Date();
        const lastUpdatedDateTime = new Date(formattedDate);
        const timeDifference = (currentDateTime - lastUpdatedDateTime) / 1000;

        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInWeek = 604800;
        const secondsInMonth = 2629800;
        const secondsInYear = 31557600;

        if (timeDifference >= secondsInYear) {
            const yearsAgo = Math.floor(timeDifference / secondsInYear);
            return `${yearsAgo >= 0 ? yearsAgo : 0} ${yearsAgo === 1 ? 'year' : 'years'} ago`;
        } else if (timeDifference >= secondsInMonth) {
            const monthsAgo = Math.floor(timeDifference / secondsInMonth);
            return `${monthsAgo >= 0 ? monthsAgo : 0} ${monthsAgo === 1 ? 'month' : 'months'} ago`;
        } else if (timeDifference >= secondsInWeek) {
            const weeksAgo = Math.floor(timeDifference / secondsInWeek);
            return `${weeksAgo >= 0 ? weeksAgo : 0} ${weeksAgo === 1 ? 'week' : 'weeks'} ago`;
        } else if (timeDifference >= secondsInDay) {
            const daysAgo = Math.floor(timeDifference / secondsInDay);
            return `${daysAgo ? daysAgo : 0} ${daysAgo === 1 ? 'day' : 'days'} ago`;
        } else if (timeDifference >= secondsInHour) {
            const hoursAgo = Math.floor(timeDifference / secondsInHour);
            return `${hoursAgo >= 0 ? hoursAgo : 0} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
        } else {
            const minutesAgo = Math.floor(timeDifference / secondsInMinute);
            return `${minutesAgo >= 0 ? minutesAgo : 0} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
        }
    };

    return (
        <div className='logs-container' onClick={() => {
            dispatch(setGetLogs(false));
        }}>
            {clicked ? <div className='logs-view-loader'>
                <div className='logs-header'>Activities</div>
                <div className='logs-view-container'>
                    {dummyTasks && dummyTasks.map((log, index) => (
                        <div className='log' key={index} style={{ background: "#ffffff" }}>
                            <div className='logInfo'><span><div class="log-loader-activity"></div></span></div>
                            <p><div class="log-loader-history"></div></p>
                            <div className='logTime'><div class="log-loader-time"></div></div>
                        </div>
                    ))}
                </div>
            </div> :


                <div className='logs-view' onClick={(e) => {
                    e.stopPropagation();
                }}><div className='logs-header'>Activities</div>
                    {logs && logs.length > 0 ?
                        <div className='logs-view-container'>
                            {
                                logs && logs.map((log, index) => (
                                    <div className='log' key={index} style={{ background: "#fffff" }}>
                                        <div className='logInfo'>
                                            <div style={{color: 'black', fontWeight: '600'}}>{log.Authority}</div> 
                                            <div className='logTime' title={log.assignedDate + " " + log.assignedTime}>{timeCalculator(log.assignedDate + " " + log.assignedTime)}</div>
                                        </div>
                                    
                                        <div className='loginfotext'>
                                            <div className='log-history'><p>{log.log_history}</p></div>
                                            <div className='log-taskName'>{log.taskName}</div></div>
                                    </div>
                                ))
                            }
                        </div> : <div className='empty-logs'>
                            <img src={noLogs} alt='/' />
                            <div className='empty-logs-text'>No Activity Available</div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default LogsView;
