import React, { useEffect, useState } from 'react';
import Landing1 from "./pages/home/Landing1";
import Navbar from './components/navbar/Navbar';
function HomeNavigate() {

  return (
    <div style={{ height: "100vh", width: "100%", overflow: "auto", position: "relative" }} id="home-navigate">
      <Navbar />
      <Landing1 />
    </div>
  );
}

export default HomeNavigate;

