import React, { useEffect, useRef, useState } from 'react'
import './HolidayList.css'
import axios from 'axios'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setEditeHolidayFlag, setHolidayCrudObj, setHolidayListFlag, setMonthData } from '../../../../erpStore/actions';
import { toast } from 'react-toastify';

function HolidayList() {

    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);
    const url = useSelector((state) => state.data.apiUrl)
    const dispatch = useDispatch()
    const [crudCardIndex, setCrudCardIndex] = useState(null)
    const [holidayFlag, setHolidayFlag] = useState(false)
    const [holidayList, setHolidayList] = useState([])
    const templateRef = useRef(null)
    const crudRef = useRef(null)
    const [deleHolidayFlag, setHolDeleteFlag] = useState(false)
    const selfInfo = useSelector((state) => state.data.selfData)
    const [tempDeleteHolObj, setTempDeleHolObj] = useState(null)
    const [clicked, setClicked] = useState(false)
    const monthData = useSelector((state) => state.data.monthData)

    const getHolidayList = () => {
        axios.get(`${url}holiday/get-year?year=${'2024'}`, { headers: storedHeaders })
            .then(response => {
                if (response.request.status === 200) {
                    setHolidayList(response.data)
                }
            })
            .catch(error => {
            })
    }

    useEffect(() => {
        getHolidayList()
    }, [])

    useEffect(() => {
        function handleOutsideClick(event) {
            if (templateRef && templateRef.current && !templateRef.current.contains(event.target)) {
                dispatch(setHolidayListFlag(false))
            } else if (crudRef && crudRef.current && !crudRef.current.contains(event.target)) {
                setHolidayFlag(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])

    const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ];

    const dateFormat = (date) => {
        const month = new Date(date).getMonth()
        const day = new Date(date).getDate()
        const formattedDate = ` ${day} ${monthNames[month]}`

        return formattedDate
    }


    return (
        <div className='HolidayListMainContainer'>
            <div className='subMainTemplateList' ref={templateRef}>
                {holidayList.length > 0 &&
                    <div className='sunTempScroller'>
                        {holidayList && holidayList.length > 0 && holidayList.map((holidayObj, index) => (
                            <div className='holidayCard'>
                                <div style={{ width: '60px', textAlign: 'left', boxSizing: 'border-box', paddingLeft: '5px' }}>{index + 1}</div>
                                <div style={{ width: '130px', textAlign: 'left' }}>{dateFormat(holidayObj.holidayDate)}</div>
                                <div title={holidayObj.holidayDescription} style={{ width: '260px', textAlign: 'left', lineBreak: 'anywhere' }}>{holidayObj.holidayTitle}</div>
                                { (selfInfo.role[0].roleLevel === 1 || selfInfo.role[0].roleLevel === 2) &&
                                    <div style={{ width: '20px' }} onClick={() => {
                                        
                                        setCrudCardIndex(index)
                                        setHolidayFlag(true)
                                        dispatch(setHolidayCrudObj(holidayObj))
                                    }}>
                                        <svg width="7" height="14" viewBox="0 0 2 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="1" cy="7.57031" r="1" transform="rotate(-90 1 7.57031)" fill="#263238" />
                                            <circle cx="1" cy="4.28516" r="1" transform="rotate(-90 1 4.28516)" fill="#263238" />
                                            <circle cx="1" cy="1" r="1" transform="rotate(-90 1 1)" fill="#263238" />
                                        </svg>
                                    </div>
                                }
                                {holidayFlag && index === crudCardIndex &&
                                    <div className='holidayCrud' ref={crudRef}>
                                        <div className='project-editecrd' onClick={() => {
                                            dispatch(setEditeHolidayFlag(true))
                                        }}>
                                            <svg width="15" height="15" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.78813 0.954054C10.2602 0.492902 10.8951 0.236434 11.5551 0.240277C12.215 0.24412 12.8469 0.507963 13.3135 0.974582C13.7802 1.4412 14.0441 2.07297 14.0479 2.73285C14.0518 3.39274 13.7953 4.02753 13.3341 4.49956L12.7702 5.06336L9.22425 1.51786L9.78813 0.954054ZM8.62834 2.11454L1.88624 8.85579C1.61036 9.13197 1.41886 9.48101 1.33416 9.86205L0.594127 13.191C0.578648 13.2602 0.580917 13.3323 0.600723 13.4004C0.620528 13.4686 0.657229 13.5306 0.707419 13.5808C0.75761 13.631 0.819665 13.6677 0.887826 13.6875C0.955987 13.7073 1.02805 13.7096 1.09732 13.6941L4.40642 12.9584C4.80041 12.8707 5.16121 12.6724 5.44652 12.387L12.1743 5.66004L8.62834 2.11454Z" fill="#FF8402" />
                                            </svg>
                                            <p style={{ fontSize: '15px' }}>Edit Holiday</p>
                                        </div>

                                        <div className='project-editecrd' onClick={() =>{
                                            setHolDeleteFlag(true)
                                            setTempDeleHolObj(holidayObj)
                                        }}> 
                                            <svg width="15" height="15" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.5935 12.3586C1.5935 13.1814 2.30167 13.8546 3.16721 13.8546H9.46207C10.3276 13.8546 11.0358 13.1814 11.0358 12.3586V4.87862C11.0358 4.05582 10.3276 3.38262 9.46207 3.38262H3.16721C2.30167 3.38262 1.5935 4.05582 1.5935 4.87862V12.3586ZM11.0358 1.13862H9.06864L8.50997 0.607545C8.36834 0.472905 8.16375 0.390625 7.95917 0.390625H4.67011C4.46553 0.390625 4.26094 0.472905 4.11931 0.607545L3.56064 1.13862H1.5935C1.16073 1.13862 0.806641 1.47522 0.806641 1.88662C0.806641 2.29803 1.16073 2.63462 1.5935 2.63462H11.0358C11.4686 2.63462 11.8226 2.29803 11.8226 1.88662C11.8226 1.47522 11.4686 1.13862 11.0358 1.13862Z" fill="#FF8402" />
                                            </svg>

                                            <p style={{ fontSize: '15px' }}>Delete Holiday</p>

                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>

                }


            </div>
            { deleHolidayFlag && 
                <div className="deleteTaskPopupforHoliday">
                    <div className="deleteTaskSurityCard"  ref={templateRef}>
                        <div className="deleteTaskSurityCard_heading"><p>Are you Sure ?</p></div>
                        <div className="confirm_btn_deletetask">
                        <button style={{ backgroundColor: 'rgba(255, 132, 2, 1)', color: 'rgba(255, 255, 255, 1)' }} onClick={() => {
                            setClicked(true)
                            if(monthData.hasOwnProperty(tempDeleteHolObj.holidayDate)){
                                monthData[tempDeleteHolObj.holidayDate].holidayData = {}
                                dispatch(setMonthData(monthData))
                            }else{
                            }
                            axios.put(`${url}holiday/remove`, {"userId": selfInfo.userId, "holidayId" : tempDeleteHolObj.holidayId}, {headers: storedHeaders})
                            .then(response => {
                                if(response.request.status === 200){
                                    toast.success('holiday deleted succesfully', {autoClose: 2000})
                                    setHolDeleteFlag(false)
                                    dispatch(setHolidayListFlag(false))
                                    setClicked(false)
                                    setTempDeleHolObj(null)
                                }
                            })
                            .catch(error => {
                                toast.error('error occured', {autoClose: 2000})
                                setHolDeleteFlag(false)
                                setClicked(false)
                            })
                        }}>{ clicked ? <div className='taskLoader'></div> : 'Yes'}</button>
                        <button style={{ backgroundColor: 'rgba(232, 232, 232, 1)', color: 'rgba(38, 50, 56, 1)' }}
                            onClick={() => {
                                setHolDeleteFlag(false)
                                setTempDeleHolObj(null)
                                dispatch(setHolidayListFlag(false)) 
                                setClicked(false)
                            }}>No</button>
                        </div>
                    </div>
                </div>
        }
        </div>
    )
}

export default HolidayList
