import React, { useEffect, useState } from 'react'
import "./styleRequestCountSection.css"
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import profile from "../../../assets/images/profile.png";
import { setReqCountData } from '../../../erpStore/actions';
import empEmpty from "../../../assets/images/Group 1069addEmpEmpty.png"
function RequestCountSection() {
    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);
    const reqCountData = useSelector((state) => state.data.reqCountData);
    const selfInfo = useSelector((state) => state.data.selfData)
    const url = useSelector((state) => state.data.apiUrl)
    const dispatch = useDispatch()

    // const fetchData = async () => {
    //     try {
    //         const response = await axios.post(`${url}task/req-overview`, {
    //             userId: selfInfo.userId,
    //         }, { headers: storedHeaders })
    //         if (response && response.data) {
    //             dispatch(setReqCountData(response.data))
    //         }
    //     }
    //     catch (err) {
    //     }
    // }
    // useEffect(() => {
    //     if (selfInfo) {
    //         fetchData();
    //     }
    // }, [])
    return (
        <div className='request-count-section'>
            <div className='request-count-section-container'>
                {!reqCountData || reqCountData.length === 0 ? <div className='noemp-container'><div className='emptyContainer'>
                    <img src={empEmpty} alt='/' />
                    <p> No Data</p>

                </div></div> :
                    <div className='req-table-container'>
                        <table className='table request-table'>
                            <thead className='req-tbody'>
                                <tr>
                                    <th>sr.</th>
                                    <th style={{ width: "425px" }}>Name</th>
                                    <th>Pending</th>
                                    <th>Accepted</th>
                                    <th>Rejected</th>
                                    <th>Total Requests</th>
                                    <th>Rejected by admin</th>
                           
                                </tr>
                            </thead>
                            <tbody >
                                {reqCountData && reqCountData.length > 0 && reqCountData.map((emp, index) => <tr>
                                    <td>{index + 1}</td>
                                    <td><div className='Level_employee_profile'>
                                        <div className='img-holder'><img src={emp.empImg ? emp.empImg : profile} className='userProfileImage' alt='' /></div>
                                        <div className='level_employee_designation'><p>{emp.empName}</p>{emp.department} {emp.designation}</div>
                                    </div></td>
                                    <td>{emp.pendingCount}</td>
                                    <td>{emp.acceptedCount}</td>
                                    <td>{emp.rejectedCount}</td>
                                    <td style={{background: "#faebd761" }}>{emp.totalRequestCount}</td>
                                    <td>{emp.myRejectedCount}</td>
                                </tr>)

                                }

                            </tbody>
                        </table>
                    </div>
                }

            </div>
        </div>
    )
}

export default RequestCountSection
