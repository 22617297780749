import React, { useState,  } from 'react'
import './Reminders.css'
import axios from "axios";
import {useSelector, useDispatch} from 'react-redux';
import { setReminders } from '../../../erpStore/actions';

function Reminders() {

  const apiUrl = useSelector((state) => state.data.apiUrl)
  const stringHeaders = window.localStorage.getItem('myHeaders');
  const storedHeaders = JSON.parse(stringHeaders);

  const [addRmdFlag, setAddRmdFlag] = useState(false)
  const [timeOptFlag, setTimeOptFlag] = useState(false)

  const dispatch = useDispatch()

  const POSTREMD = () => {
    axios.post(`${apiUrl}rem/set-reminder`, { 'remName' : "Commands Document", 'remTimeDate': '2024-01-20 11:50:30', 'remDaily' : '0'}, {headers: storedHeaders})
    .then(response => {
      if(response.request.status === 201){
      }
    })
    .catch(error => {
    })
  }

  return (
    <div className='reminders_main_Container' onClick={() => {
      const unloadElement = document.getElementById('reminderTemplateUnload');
      if (unloadElement) {
        unloadElement.classList.add('fade-out');
      }
      setTimeout(function () {
        dispatch(setReminders(false))
      }, 1000)
      // dispatch(setNotifications(false))
    }}>
        <div className='reminders_template' id="reminderTemplateUnload" onClick={(e) => {
          e.stopPropagation()
          }} >
            <div className='reminder-heading'>Reminders</div>
            { addRmdFlag === false && 
            <div className='addrmd-holder'>
              <div className='addrmd-iconDiv'>
                <div className='addrmd-icon'>
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                    setAddRmdFlag(true)
                  }}>
                    <path d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z" fill="#FF8402" fill-opacity="0.15"/>
                    <path d="M8.85938 15.5H22.1451" stroke="#FF8402" stroke-width="2.21429" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.5 8.85742V22.1431" stroke="#FF8402" stroke-width="2.21429" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div className='addrmd-text'>
                    <p>Do you forget things? </p>
                    <p>Add a reminder here to remember those things.</p>
                </div>
              </div>
            </div>
            }

            { addRmdFlag && 
              <div className='remdFieldTemplate'>
                <div className='remTitleHld'>
                  <label className='rmdTitle'>Reminder Title</label>
                  <input className='inputRmdTilte' placeholder='-' />
                </div >

                <div className='remToMembersDiv'> 
                <label style={{fontSize: '15px', color: 'rgba(38, 50, 56, 0.6)'}} className='rmdTitle'>Reminder</label>
                  <textarea  className='inputRmdTilte inputRmdSubTilte' placeholder='-' />
                </div>

                <div className='remddate-time-opt'>
                  <div onClick={() => {setTimeOptFlag(true)}}>Current Day</div>
                  <div onClick={() => {setTimeOptFlag(true)}}>Daily</div>
                  <div onClick={() => {setTimeOptFlag(true)}}>Weekly</div>
                  <div onClick={() => {setTimeOptFlag(true)}}>Monthly</div>
                </div>

                {  timeOptFlag && 
                  <div className='crudSelectOpt'>
                    {

                    }
                  </div>
                }

                <div className='remadd-cnl-btns'>
                  <button style={{cursor: 'pointer'}} className='addrmdbtn' onClick={() => POSTREMD()}>Add</button>
                  <button style={{cursor: 'pointer'}} className='cnlrmdBtn' onClick={() => { setAddRmdFlag(false)}}>Cancel</button>
                </div>
              </div>
            }
            <div className='remindersCrdHlder' style={{height: addRmdFlag === false ? '69%' : '59%'}}>
              <div className='remindersCrdHlderScroller'>
                {/* Add here list of map code for reminder's card view list */}

                <div className='reminderCard'>
                  <div className='rmdCrdImgHld'> 
                    <img src='./images/Groupmeet.png' alt=""/>
                  </div>
                  <div className='rmdCrdContentHold'> 
                    <p style={{fontSize: '25px', color :'rgba(38, 50, 56, 1)'}}>Meeting</p>
                    <p style={{textAlign: 'left', fontSize: '17px' ,color: 'rgba(38, 50, 56, 0.8)'}}>You have meeting with your team in 10 minutes</p>
                  </div>  
                </div>

                <div className='reminderCard'>
                  <div className='rmdCrdImgHld'> 
                    <img src='./images/Groupmeet.png' alt=""/>
                  </div>
                  <div className='rmdCrdContentHold'> 
                    <p style={{fontSize: '25px', color :'rgba(38, 50, 56, 1)'}}>Meeting</p>
                    <p style={{textAlign: 'left', fontSize: '17px' ,color: 'rgba(38, 50, 56, 0.8)'}}>You have meeting with your team in 10 minutes</p>
                  </div>  
                </div>

                <div className='reminderCard'>
                  <div className='rmdCrdImgHld'> 
                    <img src='./images/Groupmeet.png' alt=""/>
                  </div>
                  <div className='rmdCrdContentHold'> 
                    <p style={{fontSize: '25px', color :'rgba(38, 50, 56, 1)'}}>Meeting</p>
                    <p style={{textAlign: 'left', fontSize: '17px' ,color: 'rgba(38, 50, 56, 0.8)'}}>You have meeting with your team in 10 minutes</p>
                  </div>  
                </div>

                <div className='reminderCard'>
                  <div className='rmdCrdImgHld'> 
                    <img src='./images/Groupmeet.png' alt=""/>
                  </div>
                  <div className='rmdCrdContentHold'> 
                    <p style={{fontSize: '25px', color :'rgba(38, 50, 56, 1)'}}>Meeting</p>
                    <p style={{textAlign: 'left', fontSize: '17px' ,color: 'rgba(38, 50, 56, 0.8)'}}>You have meeting with your team in 10 minutes</p>
                  </div>  
                </div>

                <div className='reminderCard'>
                  <div className='rmdCrdImgHld'> 
                    <img src='./images/Groupmeet.png' alt=""/>
                  </div>
                  <div className='rmdCrdContentHold'> 
                    <p style={{fontSize: '25px', color :'rgba(38, 50, 56, 1)'}}>Meeting</p>
                    <p style={{textAlign: 'left', fontSize: '17px' ,color: 'rgba(38, 50, 56, 0.8)'}}>You have meeting with your team in 10 minutes</p>
                  </div>  
                </div>

                <div className='reminderCard'>
                  <div className='rmdCrdImgHld'> 
                    <img src='./images/Groupmeet.png' alt=""/>
                  </div>
                  <div className='rmdCrdContentHold'> 
                    <p style={{fontSize: '25px', color :'rgba(38, 50, 56, 1)'}}>Meeting</p>
                    <p style={{textAlign: 'left', fontSize: '17px' ,color: 'rgba(38, 50, 56, 0.8)'}}>You have meeting with your team in 10 minutes</p>
                  </div>  
                </div>

                <div className='reminderCard'>
                  <div className='rmdCrdImgHld'> 
                    <img src='./images/Groupmeet.png' alt=""/>
                  </div>
                  <div className='rmdCrdContentHold'> 
                    <p style={{fontSize: '25px', color :'rgba(38, 50, 56, 1)'}}>Meeting</p>
                    <p style={{textAlign: 'left', fontSize: '17px' ,color: 'rgba(38, 50, 56, 0.8)'}}>You have meeting with your team in 10 minutes</p>
                  </div>  
                </div>

              </div>
            </div>
        </div>
    </div>
  )
}
export default Reminders
