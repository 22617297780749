import React, { useState, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom';
import HomeNavigate from './website/HomeNavigate'
import PageNotFound from './website/components/PageHandler/PageNotFound';
import PageNotFoundDay from './website/components/PageHandler/PageNotFoundDay';
import LoginPage from './erp/pages/login/loginPage';
import Dashboard from './erp/components/dashboard/Dashboard';
import NetworkError from './website/components/PageHandler/NetworkError';
import BadResponse from './erp/pages/responsepages/BadResponse';
function RoutingComponent() {
  const [pnp, setPnp] = useState();
  useEffect(() => {

    // window.addEventListener("orientationchange", function () {
    //   // Announce the new orientation number
    //   alert(window.orientation);
    // }, false);

    const time = new Date();
    if (time.getHours() >= 6 && time.getHours() <= 18) {
      setPnp(true);

    } else {
      setPnp(false)

    }
  }, [])
  return (


    <Routes>{
      pnp ? <Route path="*" Component={PageNotFoundDay} /> : <Route path="*" Component={PageNotFound} />
    }
      <Route path='/' Component={HomeNavigate} />
      <Route path='/login' Component={LoginPage} />
      <Route path={`/dashboard`} Component={Dashboard} />
      <Route path='/net-error' Component={NetworkError} />
      <Route path="/server-error" Component={BadResponse} />
    </Routes>
  )
}

export default RoutingComponent
