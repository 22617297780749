import React, { useState } from 'react';
import "./styleChangePass.css";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setChangePass } from '../../../../erpStore/actions';

function ChangePass() {
    const selfInfo = useSelector((state) => state.data.selfData)
    const dispatch = useDispatch();
    const [validatePassword, setValidatePassword] = useState(true)
    const [forgotClicked, setForgotClicked] = useState(false);
    const [success, setSuccess] = useState(false);
    const [val, setVal] = useState({
        loginId: selfInfo.userId,
        cntPassword: "",
        newPassword: '',
        cnfPassword: "",
    });

    const [errors, setErrors] = useState({
        currentPass: "",
        newPass: "",
        cnfPass: "",
    });

    const url = useSelector((state) => state.data.apiUrl);
    const [showPassword, setShowPassword] = useState({
        cntPass: false,
        newPass: false,
        cnfNewPass: false
    });
    const validatePass = () => {
        // Regular expression for password validation
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$&*]).{8,20}$/;
        const validate = regex.test(val.newPassword)
        setValidatePassword(regex.test(val.newPassword));
        if (!validate) {
            toast.error('Enter Valid New Password, follow the password rules', { autoClose: 2000 })
            setForgotClicked(false)
            return true
        }
        return false
    };

    const handleTogglePassword = (e) => {
        if (e === 1) {
            setShowPassword({
                ...showPassword,
                cntPass: !showPassword.cntPass
            });
        } else if (e === 2) {
            setShowPassword({
                ...showPassword,
                newPass: !showPassword.newPass
            });
        } else {
            setShowPassword({
                ...showPassword,
                cnfNewPass: !showPassword.cnfNewPass
            });
        }
        // setShowPassword(!showPassword);
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = { loginId: "", password: "" };

        if (val.cntPassword.trim() === "") {
            newErrors.currentPass = "Current Password is required";
            valid = false;
        }

        if (val.newPassword.trim() === "") {
            newErrors.newPass = "New Password is required";
            valid = false;
        }
        if (validatePass()) {
            return
        }
        if (val.cnfPassword.trim() === "") {
            newErrors.cnfPass = "Confirm Password is required";
            valid = false;
        }
        if (val.newPassword.trim() !== val.cnfPassword.trim()) {
            newErrors.cnfPass = "Confirm Password not match with New Password";
            setForgotClicked(false)
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = (event) => {
        setForgotClicked(true)
        event.preventDefault();
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);

        if (validateForm()) {
            const url2 = `${url}employee/update/user/cred`;
            axios.put(url2, {
                loginId: selfInfo.userId,
                oldPassword: val.cntPassword,
                newPassword: val.newPassword,
                confirmPassword: val.cnfPassword

            }, {
                headers: storedHeaders
            })
                .then((resp) => {
                    if (resp && resp.data && resp.data.status === 200) {
                        toast.success('Password Updated Successfully', { autoClose: 2000 })
                        setVal({
                            loginId: selfInfo.userId,
                            cntPassword: "",
                            newPassword: "",
                            cnfPassword: "",
                        })

                        setSuccess(true)
                        setTimeout(() => {
                            setForgotClicked(false);
                            setSuccess(false);
                        }, 2000);
                        dispatch(setChangePass(false))
                    }
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        toast.error('Enter Correct Current Password', { autoClose: 2000 })
                        setErrors({
                            currentPass: "Enter Correct Current Password",
                            newPass: "",
                            cnfPass: "",
                        })
                    } else {
                        toast.warning('Check your network connection', { autoClose: 2000 })
                    }
                    setForgotClicked(false);
                    setSuccess(false);

                });
        }
    };

    return (
        <div className='changePass'>
            <div className='login-snow-container'>
                <div className='login-container'>
                    <div className='login-info-container chnagepass-info-container'>
                        <svg className='cancel-change-pass' onClick={() => dispatch(setChangePass(false))} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1ZM0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM4.12292 10.8745C3.85665 10.6083 3.85665 10.1765 4.12292 9.91028L6.5332 7.5L4.12292 5.08972C3.85665 4.82345 3.85665 4.39175 4.12292 4.12548C4.38919 3.85921 4.82089 3.85921 5.08716 4.12548L7.49744 6.53576L9.90834 4.12486C10.1746 3.8586 10.6063 3.8586 10.8726 4.12486C11.1388 4.39113 11.1388 4.82283 10.8726 5.0891L8.46168 7.5L10.8726 9.9109C11.1388 10.1772 11.1388 10.6089 10.8726 10.8751C10.6063 11.1414 10.1746 11.1414 9.90834 10.8751L7.49744 8.46424L5.08716 10.8745C4.82089 11.1408 4.38919 11.1408 4.12292 10.8745Z" fill="#263238" />
                        </svg>

                        <form className='feild-name-container' onSubmit={handleSubmit}>
                            <div className='login-title signup-title'>
                                <h1>Change your login Password</h1>
                            </div>
                            <div className='input-container input-forget'>
                                <div className='input-type-container input-type-container-change'>
                                    <div className='label-holder'>
                                        <label>Current Password</label>
                                        <input style={val.cntPassword !== "" ? { height: "40px" } : null}
                                            type={showPassword.cntPass ? 'text' : 'password'}
                                            value={val.cntPassword}
                                            onChange={(event) => setVal((prev) => ({ ...prev, cntPassword: event.target.value }))} required
                                            autocomplete="current-password"
                                        />
                                    </div>{val.cntPassword !== "" ? (showPassword.cntPass ?
                                        <svg onClick={() => handleTogglePassword(1)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_6416_1216)">
                                                <path d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M1 1L23 23" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_6416_1216">
                                                    <rect width="24" height="24" fill="black" />
                                                </clipPath>
                                            </defs>
                                        </svg> : <svg onClick={() => handleTogglePassword(1)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    )}

                                </div>
                                <p className="error-message">{errors.currentPass !== "" ? errors.currentPass : ""}</p>
                                <div className='input-type-container input-type-container-change' style={{ borderBottom: validatePassword ? null : "2px solid red" }}>
                                    <div className='label-holder'>
                                        <label>New Password</label>
                                        <input style={val.newPassword !== "" ? { height: "40px" } : null}
                                            type={showPassword.newPass ? 'text' : 'password'}
                                            value={val.newPassword}
                                            onChange={(event) => {
                                                setValidatePassword(true)
                                                setVal((prev) => ({ ...prev, newPassword: event.target.value }))
                                            }} required
                                            autocomplete="current-password"
                                        />
                                    </div>{val.newPassword !== "" ? (showPassword.newPass ?
                                        <svg onClick={() => handleTogglePassword(2)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_6416_1216)">
                                                <path d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M1 1L23 23" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_6416_1216">
                                                    <rect width="24" height="24" fill="black" />
                                                </clipPath>
                                            </defs>
                                        </svg> : <svg onClick={() => handleTogglePassword(2)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    )}

                                </div>
                                <p className='pass-info' style={{ color: validatePassword ? null : "red" }}>Password length should be in between 8 to 20 characters string & must contain at least one digit, one upper case letter, one lower case letter & one special symbol. Among this symbol(!@#$&*)</p>
                                <div className='input-type-container input-type-container-change'>
                                    <div className='label-holder'>
                                        <label>Confirm New Password</label>
                                        <input style={val.cnfPassword !== "" ? { height: "40px" } : null}
                                            type={showPassword.cnfNewPass ? 'text' : 'password'}
                                            value={val.cnfPassword}
                                            onChange={(event) => {
                                                setErrors({
                                                    ...errors,
                                                    cnfPass: ""
                                                })
                                                setVal((prev) => ({ ...prev, cnfPassword: event.target.value }))
                                            }} required
                                            autocomplete="current-password"
                                        />
                                    </div>{val.cnfPassword !== "" ? (showPassword.cnfNewPass ?
                                        <svg onClick={() => handleTogglePassword(3)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_6416_1216)">
                                                <path d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M1 1L23 23" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_6416_1216">
                                                    <rect width="24" height="24" fill="black" />
                                                </clipPath>
                                            </defs>
                                        </svg> : <svg onClick={() => handleTogglePassword(3)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    )}

                                </div>
                                <p className="error-message">{errors.cnfPass ? errors.cnfPass : ""}</p>
                                <button type="submit" className='btnsubmit'>SUBMIT</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {forgotClicked ?
                <div className="forgot-loader">
                    <div className='forgot-loader-container'>
                        {success ?
                            <div className='loaderForgotSuccess'>
                                <svg width="67" height="63" viewBox="0 0 67 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="33.5" cy="30.8281" r="21.5" fill="#04BD00" />
                                    <path d="M43.0718 24.0769C42.9391 23.9455 42.7812 23.8411 42.6072 23.77C42.4332 23.6988 42.2466 23.6621 42.0581 23.6621C41.8696 23.6621 41.683 23.6988 41.509 23.77C41.335 23.8411 41.177 23.9455 41.0443 24.0769L30.4069 34.5378L25.9378 30.1347C25.7999 30.0039 25.6372 29.9011 25.459 29.8321C25.2807 29.7631 25.0903 29.7293 24.8987 29.7325C24.7072 29.7358 24.5181 29.7761 24.3424 29.8511C24.1666 29.9261 24.0077 30.0344 23.8745 30.1697C23.7414 30.3051 23.6367 30.4648 23.5665 30.6399C23.4962 30.815 23.4617 31.002 23.4651 31.1901C23.4684 31.3783 23.5094 31.5639 23.5858 31.7365C23.6622 31.9091 23.7724 32.0652 23.9102 32.196L29.3931 37.5807C29.5259 37.7121 29.6838 37.8164 29.8578 37.8876C30.0318 37.9588 30.2184 37.9954 30.4069 37.9954C30.5954 37.9954 30.782 37.9588 30.956 37.8876C31.13 37.8164 31.2879 37.7121 31.4207 37.5807L43.0718 26.1382C43.2168 26.0069 43.3324 25.8475 43.4116 25.6702C43.4907 25.4928 43.5315 25.3012 43.5315 25.1076C43.5315 24.9139 43.4907 24.7223 43.4116 24.545C43.3324 24.3676 43.2168 24.2082 43.0718 24.0769Z" fill="white" />
                                    <circle cx="9.84588" cy="5.27557" r="5.27557" fill="#04BD00" fill-opacity="0.2" />
                                    <circle cx="63.0426" cy="10.5504" r="3.95668" fill="#04BD00" fill-opacity="0.2" />
                                    <ellipse cx="3.25107" cy="46.8203" rx="2.63779" ry="2.41797" fill="#04BD00" fill-opacity="0.2" />
                                    <circle cx="23.9126" cy="61.108" r="1.31889" fill="#04BD00" fill-opacity="0.2" />
                                    <circle cx="57.7642" cy="55.8345" r="1.31889" fill="#04BD00" fill-opacity="0.2" />
                                    <circle cx="60.4048" cy="39.1275" r="1.31889" fill="#04BD00" fill-opacity="0.2" />
                                    <circle cx="38.4205" cy="2.63725" r="1.31889" fill="#04BD00" fill-opacity="0.2" />
                                </svg>
                                <div className='loader-success-message'>Password Sent successfully Check your Mail</div>
                            </div> : <div className="loaderForgot"></div>
                        }

                    </div>
                </div> : null
            }
        </div>
    );
}

export default ChangePass
