import React from 'react'
import "./styleAddPhase.css"
import { setAddPhase } from '../../../../erpStore/actions'
import { useDispatch } from 'react-redux';

function AddSprint() {
    const dispatch = useDispatch();
  return (
    <div className='add-phase-form-container'>
      <div className='add-phase-form'>
        <div className='add-phase-heading'>Add Sprint</div>
        <div className='phase-form'>
            <input className='phase-header' type='text' placeholder='Enter Milestone Name'/>
            <div className='phase-description'>
                <label>Description</label>
                <textarea></textarea>
            </div>
            <div className='phase-dates'>
                <div className='phase-date-part'>
                    <label>Start Date</label>
                    <input type='date' placeholder='DD-MM-YYYY'></input>
                </div>
                <div className='phase-date-part'>
                    <label>Start Date</label>
                    <input type='date' placeholder='DD-MM-YYYY'></input>
                </div>
            </div>
            <div className='phase-day-count'>
                <label>Number of days</label>
                <input type='number'></input>
            </div>
            <div className='phase-day-count'>
                <label>Status</label>
                <input type='number'></input>
            </div>
        </div>
        <div className='add-phase-buttons'>
            <div className='add-phase-button'>Add Sprint</div>
            <div className='cancel-phase-button' onClick={()=>{
              dispatch(setAddPhase("none"))
            }}>Cancel</div>
        </div>
      </div>
    </div>
  )
}

export default AddSprint
