import { ToastContainer } from 'react-toastify';
import './App.css';
import RoutingComponent from './RoutingComponent';
import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';


function App() {



  return (
    <div className="App" id="app" style={{userSelect: 'none'}}>
      <div className='cursor'>
      </div>
      <BrowserRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={true}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light" />
        <RoutingComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;
