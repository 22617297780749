// actions.js
import { type } from '@testing-library/user-event/dist/cjs/utility/type.js';
import {
  SET_ALL_DISPATCH_TASK, SET_API_URL, SET_DATA_OBJECT, SET_VIEW_ADDTASK, SET_VIEW_OBJECT, SET_FULLDATE, SET_RESET_OBJECT, SET_INITIAL_DATA_OBJECT, SET_NEWTASK, SET_SELF, SET_DATE, SET_RESET, SET_EMPINFO, SET_EMPVIEW, SET_HEADER, SET_EDITFORM, SET_CLOSURE, SET_ADDEMP, SET_ROUTE, SET_ADDPROJECT, SET_PROJECTLIST, SET_ADD_PHASE, SET_SEARCH_FOR_MONTH, SET_PROJECT, SET_GETLOGS, SET_NOTIFICATIONS, SET_REQUEST_OBJECT, SET_MILESTONEOBJ, SET_ADDMEETING, SET_PROJECTTASKFLOW, SET_NOTIFICATION, SET_REMINDERS, SET_INFOSHOW, SET_MESSAGE, SET_LOGOUT, SET_MTIID, SET_UPDATEEMP, SET_EDITEKEY, SET_DEFAULT_TAG, SET_RANDOMLINK, SET_RQTDENYPOPUP, SET_RQTDENYOBJ, SET_PREASkOPTN, SET_ALLEMPLOYEE, SET_SEARCHEDDATE, SET_SEARCHEDTASKMONTH, SET_LEVELSEARCH, SET_SETTING, SET_SETTINGVIEW, SET_CHANGEPASS, SET_LOADER, SET_WEEKDATA, SET_STATUSLIST, SET_ISEDITSTATUS, SET_USEDLIST, SET_UNUSEDLIST, SET_PRIORITYLIST,
  SET_PROJECTLISTUPDATE,
  SET_TAGSLIST,
  SET_ASSINEELIST,
  SET_VERIFIEdLIST,
  SET_PRIORITYCOLORLIST,
  SET_ISEDITPRIORITY,
  SET_DEPARTMENTCOLORLIST,
  SET_ISEDITDEPARTMENT,
  SET_VERIFICATIONCOLORLIST,
  SET_ISEDITVERIFICATION,
  SET_UNRDNOTICOUNT,
  SET_REQOBJ,
  SET_REQREJECTI,
  SET_TASKDTLOBJ,
  SET_TASKDTLFLAG,
  SET_MONTHDATA,
  SET_EMPLOADMORE,
  SET_CLOSUREPROCEDURE,
  SET_ISCLOSUREKEY,
  SET_ANALYTICSDATA,
  SET_ANALYTICSEMP,
  SET_ANALYTICSEMPLIST,
  SET_DESIGNATIONLIST,
  SET_ISEDITDESIGNATION,
  SET_ISDETAILANALYTICS,
  SET_ANALYTICSSTARTDATE,
  SET_ANALYTICSENDDATE,
  SET_LEVELINFOOBJECT,
  SET_UPDATEPRJFLAG,
  SET_REQUESTCOUNT,
  SET_UPDATE_PROJECT,
  SET_TASKEMP,
  SET_INITIALSTATE,
  SET_EMPJOININGDATE,
  SET_REQCOUNTDATA,
  SET_PRJUPDATEFLAG,
  SET_ANALYTICSTYPE,
  SET_PRJALLTSKFLAG,
  SET_ISCOMMENTVIEW,
  SET_LEAVECLANEDERVIEWFLAG,
  SET_ADDHOLIDAYFLAG,
  SET_ADDLEAVEFLAG,
  SET_ADDEDITEFLAG,
  SET_LEAVEDAYVIEWFLAG,
  SET_SCANNERVIEWFLAG,
  SET_ATTENDENCEMONTHVIEWFLAG,
  SET_MYLEAVESTATUSFLAG,
  SET_SCANNERATTENDENCELIST,
  SET_ATTENDMUSTEREDFLAG,
  SET_ONLEAVEDATE,
  SET_UPDATELEAVEOBJ,
  SET_EDITELEAVEFLAG,
  SET_HOLIDAYLISTFLAG,
  SET_EDITEHOLIDAY,
  SET_HOLIDAYCRUDOBJ,
  SET_MUSTEREDDATE,
  SET_MUSTEREDOBJ,
  SET_ISHOLIDAYSTATUS,
  SET_EMPISLEAVESTATUS,
  SET_ATTANALAYTICSDATE,
  SET_LEAVELIST,
  SET_LEAVEMANAGEHOLIDAYDAY,
  SET_ISPRESENTDAY,
  SET_LEVELADDTASKFLAG,
  SET_MONTHADDTASKFLAG,
  SET_WEEKADDTASKFLAG,
  SET_STOREDATEARRAY,
} from '../erpStore/actionTypes';
export const setApiUrl = (url) => ({
  type: SET_API_URL,
  payload: url,
})

export const setInitialDataObject = (data) => ({
  type: SET_INITIAL_DATA_OBJECT,
  payload: data,
});

export const setDataObject = (data, e = null) => ({
  type: SET_DATA_OBJECT,
  payload: data,
  status: e
});

export const setResetObject = (data, e) => ({
  type: SET_RESET_OBJECT,
  payload: data,
  status: e
})

export const setView = (data, e) => ({
  type: SET_VIEW_OBJECT,
  payload: data,
  status: e
});
export const setAddTask = (data, e) => ({
  type: SET_VIEW_ADDTASK,
  payload: data,
  status: e
});
export const setFullDate = (data) => ({
  type: SET_FULLDATE,
  payload: data,
});

export const setNewTask = (data) => ({
  type: SET_NEWTASK,
  payload: data,
})

export const setSelf = (data) => ({
  type: SET_SELF,
  payload: data,
})

export const setDate = (data) => ({
  type: SET_DATE,
  payload: data
})
export const setReset = (data) => ({
  type: SET_RESET,
  payload: data
})
export const setEmpInfo = (data) => ({
  type: SET_EMPINFO,
  payload: data
})
export const setEmpView = (data) => ({
  type: SET_EMPVIEW,
  payload: data
})
export const setHeader = (data) => ({
  type: SET_HEADER,
  payload: data
})
export const setEditForm = (data) => ({
  type: SET_EDITFORM,
  payload: data
})

export const setClosureMail = (data) => ({
  type: SET_CLOSURE,
  payload: data
})

export const setReminders = (data) => ({
  type: SET_REMINDERS,
  payload: data
})

export const setNotifications = (data) => ({
  type: SET_NOTIFICATIONS,
  payload: data
})

export const setAddEmp = (data) => ({
  type: SET_ADDEMP,
  payload: data
})

export const setRoute = (data) => ({
  type: SET_ROUTE,
  payload: data
})
export const setAddProject = (data) => ({
  type: SET_ADDPROJECT,
  payload: data
})

export const setProjectList = (data) => ({
  type: SET_PROJECTLIST,
  payload: data
})

export const setAddPhase = (data) => ({
  type: SET_ADD_PHASE,
  payload: data
})
export const setGetLogs = (data) => ({
  type: SET_GETLOGS,
  payload: data
})
export const setProject = (data) => ({
  type: SET_PROJECT,
  payload: data
})

export const setSearchForMoth = (data) => ({
  type: SET_SEARCH_FOR_MONTH,
  payload: data
})

export const setRequestObject = (data) => ({
  type: SET_REQUEST_OBJECT,
  payload: data
})

export const setMilestoneObj = (data) => ({
  type: SET_MILESTONEOBJ,
  payload: data
})

export const setAddMeetingObj = (data) => ({
  type: SET_ADDMEETING,
  payload: data
})

export const setProjectTaskFlow = (data) => ({
  type: SET_PROJECTTASKFLOW,
  payload: data
})

export const setShowNotificationBanner = (data) => ({
  type: SET_NOTIFICATION,
  payload: data
})

export const setMessage = (data) => ({
  type: SET_MESSAGE,
  payload: data
})

export const setLogout = (data) => ({
  type: SET_LOGOUT,
  payload: data
})

export const setmtiID = (data) => ({
  type: SET_MTIID,
  payload: data
})
export const setUpdateEmp = (data) => ({
  type: SET_UPDATEEMP,
  payload: data
})

export const setEditeKey = (data) => ({
  type: SET_EDITEKEY,
  payload: data
})

export const setNewTaskDefaultTag = (data) => ({
  type: SET_DEFAULT_TAG,
  payload: data
})

export const setRandomLink = (data) => ({
  type: SET_RANDOMLINK,
  payload: data
})

export const setrqtDenypopup = (data) => ({
  type: SET_RQTDENYPOPUP,
  payload: data
})

export const setDenyRqtObj = (data) => ({
  type: SET_RQTDENYOBJ,
  payload: data
})

export const setPreAskOption = (data) => ({
  type: SET_PREASkOPTN,
  payload: data
})
export const setAllEmployee = (data) => ({
  type: SET_ALLEMPLOYEE,
  payload: data
})
export const setSearchedDate = (data) => ({
  type: SET_SEARCHEDDATE,
  payload: data
})
export const setSearchedMonth = (data) => ({
  type: SET_SEARCHEDTASKMONTH,
  payload: data
})
export const setLevelSearch = (data) => ({
  type: SET_LEVELSEARCH,
  payload: data
})
export const setSetting = (data) => ({
  type: SET_SETTING,
  payload: data
})

export const setSettingView = (data) => ({
  type: SET_SETTINGVIEW,
  payload: data
})

export const setChangePass = (data) => ({
  type: SET_CHANGEPASS,
  payload: data
})
export const setLoader = (data) => ({
  type: SET_LOADER,
  payload: data
})
export const setWeekData = (data) => ({
  type: SET_WEEKDATA,
  payload: data
})

export const setDispatchAllTask = (data) => ({
  type: SET_ALL_DISPATCH_TASK,
  payload: data
})

export const setStatusList = (data) => ({
  type: SET_STATUSLIST,
  payload: data
})

export const setPriorityList = (data) => ({
  type: SET_PRIORITYLIST,
  payload: data
})

export const setProjectListDay = (data) => ({
  type: SET_PROJECTLISTUPDATE,
  payload: data
})

export const setTagList = (data) => ({
  type: SET_TAGSLIST,
  payload: data
})

export const setAssigneeList = (data) => ({
  type: SET_ASSINEELIST,
  payload: data
})

export const setVerfiedList = (data) => ({
  type: SET_VERIFIEdLIST,
  payload: data
})
export const setUsedList = (data) => ({
  type: SET_USEDLIST,
  payload: data
})
export const setUnUsedList = (data) => ({
  type: SET_UNUSEDLIST,
  payload: data
})
export const setIsEditStatus = (data) => ({
  type: SET_ISEDITSTATUS,
  payload: data
})
export const setPriorityColorList = (data) => ({
  type: SET_PRIORITYCOLORLIST,
  payload: data
})
export const setIsEditPriority = (data) => ({
  type: SET_ISEDITPRIORITY,
  payload: data
})
export const setDepartmentColorList = (data) => ({
  type: SET_DEPARTMENTCOLORLIST,
  payload: data
})
export const setIsEditDepartment = (data) => ({
  type: SET_ISEDITDEPARTMENT,
  payload: data
})
export const setVerificationColorList = (data) => ({
  type: SET_VERIFICATIONCOLORLIST,
  payload: data
})
export const setIsEditVerification = (data) => ({
  type: SET_ISEDITVERIFICATION,
  payload: data
})
export const setUnRdNotiCount = (data) => ({
  type: SET_UNRDNOTICOUNT,
  payload: data
})
export const setReqObj = (data) => ({
  type: SET_REQOBJ,
  payload: data
})
export const setRequestRejectI = (data) => ({
  type: SET_REQREJECTI,
  payload: data
})

export const setTaskDetailObj = (data) => ({
  type: SET_TASKDTLOBJ,
  payload: data
})
export const setTaskDetailFlag = (data) => ({
  type: SET_TASKDTLFLAG,
  payload: data
})
export const setMonthData = (data) => ({
  type: SET_MONTHDATA,
  payload: data
})

export const setEmpLoadMore = (data) => ({
  type: SET_EMPLOADMORE,
  payload: data
})

export const setClosureProcedure = (data) => ({
  type: SET_CLOSUREPROCEDURE,
  payload: data
})

export const setIsClosureKey = (data) => ({
  type: SET_ISCLOSUREKEY,
  payload: data
})

export const setAnalyticsData = (data) => ({
  type: SET_ANALYTICSDATA,
  payload: data
})
export const setAnalyticsEmp = (data) => ({
  type: SET_ANALYTICSEMP,
  payload: data
})
export const setAnalyticsEmpList = (data) => ({
  type: SET_ANALYTICSEMPLIST,
  payload: data
})
export const setDesignationList = (data) => ({
  type: SET_DESIGNATIONLIST,
  payload: data
})
export const setIsEditDesignation = (data) => ({
  type: SET_ISEDITDESIGNATION,
  payload: data
})
export const setIsDetailAnalytics = (data) => ({
  type: SET_ISDETAILANALYTICS,
  payload: data
})

export const setAnalyticsStartDate = (data) => ({
  type: SET_ANALYTICSSTARTDATE,
  payload: data
})

export const setAnalyticsEndDate = (data) => ({
  type: SET_ANALYTICSENDDATE,
  payload: data
})
export const setLevelInfoObject = (data) => ({
  type: SET_LEVELINFOOBJECT,
  payload: data
})

export const setUpdateProjectFlag = (data) => ({
  type: SET_UPDATEPRJFLAG,
  payload: data
})
export const setRequestCount = (data) => ({
  type: SET_REQUESTCOUNT,
  payload: data
})

export const setProjectToUpadte = (data) => ({
  type: SET_UPDATE_PROJECT,
  payload: data
})
export const setTaskEmp = (data) => ({
  type: SET_TASKEMP,
  payload: data
})
export const setInitialState = (data) => ({
  type: SET_INITIALSTATE,
  payload: data
})
export const setEmpJoiningDate = (data) => ({
  type: SET_EMPJOININGDATE,
  payload: data
})
export const setReqCountData = (data) => ({
  type: SET_REQCOUNTDATA,
  payload: data
})

export const setPrjUpdListFlag = (data) => ({
  type: SET_PRJUPDATEFLAG,
  payload: data
})

export const setInfoShow = (data) => ({
  type: SET_INFOSHOW,
  payload: data
})
export const setanalyticsType = (data) => ({
  type: SET_ANALYTICSTYPE,
  payload: data
})

export const setPrjAllTaskFlg = (data) => ({
  type: SET_PRJALLTSKFLAG,
  payload: data
})
export const setIsCommentView = (data) => ({
  type: SET_ISCOMMENTVIEW,
  payload: data
})

export const setLeaveCalenderViewFlag = (data) => ({
  type: SET_LEAVECLANEDERVIEWFLAG,
  payload: data
})

export const setAddHolidayFlag = (data) => ({
  type: SET_ADDHOLIDAYFLAG,
  payload: data
})

export const setAddLeaveFlag = (data) => ({
  type: SET_ADDLEAVEFLAG,
  payload: data
})

export const setEditeLeaveFlag = (data) => ({
  type: SET_EDITELEAVEFLAG,
  payload: data
})

export const setLeaveDayViewFlag = (data) => ({
  type: SET_LEAVEDAYVIEWFLAG,
  payload: data
})

export const setScannerViewFlag = (data) => ({
  type: SET_SCANNERVIEWFLAG,
  payload: data
})

export const setAttendenceMontViewFlag = (data) => ({
  type: SET_ATTENDENCEMONTHVIEWFLAG,
  payload: data
})

export const setMyLeaveStatusFlag = (data) => ({
  type: SET_MYLEAVESTATUSFLAG,
  payload: data
})

export const setScannerAttendenceList = (data) => ({
  type: SET_SCANNERATTENDENCELIST,
  payload: data
})

// export const setAttendenceDayView = (data) => ({
//   type: SET_ATTDAYVIEFLAG,
//   payload: data
// })

export const setAttendMusteredFlag = (data) => ({
  type: SET_ATTENDMUSTEREDFLAG,
  payload: data

})

export const setOnLeaveDate = (data) => ({
  type: SET_ONLEAVEDATE,
  payload: data
})

export const setUpdateLeaveObj = (data) => ({
  type: SET_UPDATELEAVEOBJ,
  payload: data
})

export const setHolidayListFlag = (data) => ({
  type: SET_HOLIDAYLISTFLAG,
  payload: data
})

export const setEditeHolidayFlag = (data) => ({
  type: SET_EDITEHOLIDAY,
  payload: data
})

export const setHolidayCrudObj = (data) => ({
  type: SET_HOLIDAYCRUDOBJ,
  payload: data
})

export const setMusterdDate = (data) => ({
  type: SET_MUSTEREDDATE,
  payload: data
})

export const setMusteredObj = (data) => ({
  type: SET_MUSTEREDOBJ,
  payload: data
})

export const setIsHolidayStatus = (data) => ({
  type: SET_ISHOLIDAYSTATUS,
  payload : data
})

export const setEmpLeaveStatus = (data) => ({
  type: SET_EMPISLEAVESTATUS,
  payload : data
})

export const setAttendenceAnalyticsDate = (data) => ({
  type: SET_ATTANALAYTICSDATE,
  payload: data
})

export const setLeaveList = (data) => ({
  type: SET_LEAVELIST,
  payload: data
})

export const setLeaveManageHolidayDay = (data) => ({
  type: SET_LEAVEMANAGEHOLIDAYDAY,
  payload: data
})

export const setIsPresentDay = (data) => ({
  type: SET_ISPRESENTDAY,
  payload: data
})

export const setLevelAddTaskFlag = (data) => ({
  type :   SET_LEVELADDTASKFLAG,
  payload: data

})

export const setMonthAddTaskFlag = (data) => ({
  type : SET_MONTHADDTASKFLAG,
  payload: data

})

export const setWeekAddTaskFlag = (data) => ({
  type : SET_WEEKADDTASKFLAG,
  payload: data

})

export const setStoreDateArray = ( data ) => ({
  type: SET_STOREDATEARRAY,
  payload: data
})