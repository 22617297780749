import React, { useEffect, useState } from 'react'
import './information.css';
import showCase from "../../../assets/images/informatio_girl_image.png"
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import StatusCrud from './status/StatusCrud';
import { setDepartmentColorList, setDesignationList, setInfoShow, setIsEditDepartment, setIsEditDesignation, setIsEditPriority, setIsEditStatus, setIsEditVerification, setPriorityColorList, setStatusList, setUnUsedList, setUsedList, setVerificationColorList } from '../../../erpStore/actions';
import PriorityCrud from './priority/PriorityCrud';
import DepartmentCrud from './department/DepartmentCrud';
import VerificationCrud from './verification/VerificationCrud';
import DesignationCrud from './designation/DesignationCrud';

function Information() {

    const apiUrl = useSelector((state) => state.data.apiUrl)
    const statusList = useSelector((state) => state.data.statusList)
    const isEditStatus = useSelector((state) => state.data.isEditStatus)
    const priorityColorList = useSelector((state) => state.data.priorityColorList)
    const departmentColorList = useSelector((state) => state.data.departmentColorList)
    const verificationColorList = useSelector((state) => state.data.verificationColorList)
    const isEditPriority = useSelector((state) => state.data.isEditPriority)
    const isEditDepartment = useSelector((state) => state.data.isEditDepartment)
    const isEditVerification = useSelector((state) => state.data.isEditVerification)
    const isEditDesignation = useSelector((state) => state.data.isEditDesignation)
    const designationList = useSelector((state) => state.data.designationList)
    const self = useSelector((state) => state.data.selfData)

    const dispatch = useDispatch();
    const colorMallStatus = [
        {
            statusColorCode: '#A5DFFA',
            statusColor: 'crystal blue'
        },
        {
            statusColorCode: '#C9C8C8',
            statusColor: 'gray'
        },
        {
            statusColorCode: '#BFE0F3',
            statusColor: 'lightSky'
        },
        {
            statusColorCode: '#B1CAEB',
            statusColor: 'navyBlue'
        },
        {
            statusColorCode: '#FCF088',
            statusColor: "mustard"
        },
        {
            statusColorCode: '#ECF193',
            statusColor: 'chartreuse'
        },
        {
            statusColorCode: '#CADF94',
            statusColor: 'smithGreen'
        },
        {
            statusColorCode: '#A9C096',
            statusColor: 'tree'
        },
        {
            statusColorCode: '#9DE2A2',
            statusColor: 'kellyGreen'
        },
        {
            statusColorCode: '#7BC490',
            statusColor: 'deeGreen'
        }

    ];

    const priorityColorMall = [
        {
            priorityColorCode: '#EB878B',
            priorityColor: 'maroon'
        },
        {
            priorityColorCode: '#FF9090',
            priorityColor: 'red'
        },
        {
            priorityColorCode: '#FBB7AA',
            priorityColor: 'redOrange'
        },
        {
            priorityColorCode: '#FAC9A2',
            priorityColor: 'orange'
        },
        {
            priorityColorCode: '#FBD9AA',
            priorityColor: "pumpkin"
        },
        {
            priorityColorCode: '#FFEAB4',
            priorityColor: 'babyYellow'
        },
        {
            priorityColorCode: '#EDDFCB',
            priorityColor: 'sand'
        },
        {
            priorityColorCode: '#ECDECB',
            priorityColor: 'beige'
        },
        {
            priorityColorCode: '#DAC5B0',
            priorityColor: 'honey'
        },
        {
            priorityColorCode: '#D0B399',
            priorityColor: 'chestnut'
        }

    ]


    const departmentColorMall = [
        {
            departmentColorCode: '#FF9DCF',
            departmentColor: 'magenta'
        },
        {
            departmentColorCode: '#FFBDD1',
            departmentColor: 'bright pink'
        },
        {
            departmentColorCode: '#A9DBF0',
            departmentColor: 'electric blue'
        },
        {
            departmentColorCode: '#A1D0CE',
            departmentColor: 'teal'
        },
        {
            departmentColorCode: '#DBD5B9',
            departmentColor: "pale olive"
        },
        {
            departmentColorCode: '#F4A3B3',
            departmentColor: 'cherry'
        },
        {
            departmentColorCode: '#F3CEA9',
            departmentColor: 'rust'
        },
        {
            departmentColorCode: '#F7CFCB',
            departmentColor: 'coral'
        },
        {
            departmentColorCode: '#BEA4C6',
            departmentColor: 'grape'
        },
        {
            departmentColorCode: '#A6B1D0',
            departmentColor: 'royal blue'
        },
        {
            departmentColorCode: '#BBE4E7',
            departmentColor: 'turquoise'
        },
        {
            departmentColorCode: '#FDBEBA',
            departmentColor: 'melon'
        }

    ]
    const verificationColorMall = [
        {
            verificationColorCode: '#8AB6F9',
            verificationColor: 'cobalt'
        },
        {
            verificationColorCode: '#A1C5FE',
            verificationColor: 'azure'
        },
        {
            verificationColorCode: '#BCD6FF',
            verificationColor: 'Sky'
        },
        {
            verificationColorCode: '#DBE9FF',
            verificationColor: 'soft purple'
        },
        {
            verificationColorCode: '#C2A4F8',
            verificationColor: "Royal purple"
        },
        {
            verificationColorCode: '#CDB7F3',
            verificationColor: 'ultraviolet'
        },
        {
            verificationColorCode: '#E3D4FE',
            verificationColor: 'wisteria'
        },
        {
            verificationColorCode: '#F0E3FF',
            verificationColor: 'baby purple'
        },

    ]

    const usedList = [];
    const unusedList = [];



    const fetchData = () => {
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        axios.get(`${apiUrl}task/status/get/all`, {
            headers: storedHeaders
        })
            .then((response) => {
                if (response && response.data) {
                    dispatch(setStatusList(response.data))
                }
            })
            .catch((error) => {})

        axios.get(`${apiUrl}task/priority/get/all`, {
            headers: storedHeaders
        })
            .then((response) => {
                if (response && response.data) {
                    dispatch(setPriorityColorList(response.data))
                }
            })
            .catch((error) => {})
        axios.get(`${apiUrl}employee/department/get/all`, {
            headers: storedHeaders
        })
            .then((response) => {
                if (response && response.data) {
                    dispatch(setDepartmentColorList(response.data))
                }
            })
            .catch((error) => {})
        axios.get(`${apiUrl}task/verification/get/all`, {
            headers: storedHeaders
        })
            .then((response) => {
                if (response && response.data) {
                    dispatch(setVerificationColorList(response.data))
                }
            })
            .catch((error) => {})
        axios.get(`${apiUrl}employee/designation/all`, {
            headers: storedHeaders
        })
            .then((response) => {
                if (response && response.data) {
                    dispatch(setDesignationList(response.data))
                }
            })
            .catch((error) =>{})
    }


    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className='infro-main-container'>
            <div className='back-btn-info' onClick={() => dispatch(setInfoShow(false))}><svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.414 5.707L0.707 6.414L0 5.707L0.707 5L1.414 5.707ZM18.414 13.707C18.414 13.9722 18.3086 14.2266 18.1211 14.4141C17.9336 14.6016 17.6792 14.707 17.414 14.707C17.1488 14.707 16.8944 14.6016 16.7069 14.4141C16.5194 14.2266 16.414 13.9722 16.414 13.707H18.414ZM5.707 11.414L0.707 6.414L2.121 5L7.121 10L5.707 11.414ZM0.707 5L5.707 0L7.121 1.414L2.121 6.414L0.707 5ZM1.414 4.707H11.414V6.707H1.414V4.707ZM18.414 11.707V13.707H16.414V11.707H18.414ZM11.414 4.707C13.2705 4.707 15.051 5.4445 16.3637 6.75725C17.6765 8.07001 18.414 9.85048 18.414 11.707H16.414C16.414 10.3809 15.8872 9.10915 14.9495 8.17147C14.0119 7.23378 12.7401 6.707 11.414 6.707V4.707Z" fill="#263238" />
            </svg> Back
            </div>
            <div className='info-flex-sub-container'>
                <div className='info_girl_image_container'><img src={showCase} alt='/'></img></div>
                <div className='info_content_container'>
                    <div className='status_info_conatiner'>
                        <div className='crud-operation-header'>
                            <p style={{ color: 'rgba(255, 132, 2, 1)', borderBottom: '1px solid rgba(255, 132, 2, 1)', fontFamily: 'Roboto', fontWeight: '400', fontSize: '20px' }}>Status-</p>
                            {self && self.role && self.role.length > 0 && (self.role[0].role === "ROLE_SUPERADMIN" ||
                                self.role[0].role === "ROLE_ADMIN") ?
                                <p className='edit-crud' onClick={() => {
                                    colorMallStatus.forEach(status => {
                                        const foundInStatusList = statusList.some(statusColor => statusColor.statusColorCode === status.statusColorCode);
                                        if (foundInStatusList) {
                                            usedList.push(status);
                                        } else {
                                            unusedList.push(status);
                                        }
                                    });
                                    dispatch(setUsedList(usedList))
                                    dispatch(setUnUsedList(unusedList))
                                    dispatch(setIsEditStatus(true))
                                }}>Edit</p> : null}

                        </div>
                        <div className='statusTagsDefineColors'>
                            <div className='statusColorContainer'>
                                {statusList && statusList.length > 0 && statusList.map((status, index) => (
                                    <div style={{ borderRight: statusList.length !== index + 1 ? '2px solid rgba(38, 50, 56, 0.3)' : null }} className='actualColorOfStatusDiv'>
                                        <div style={{ width: '30px', height: '20px', backgroundColor: status.statusColorCode, borderRadius: '5px' }}></div>
                                        <p>{status.status}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='status_info_conatiner'>
                        <div className='crud-operation-header'>
                            <p style={{ color: 'rgba(255, 132, 2, 1)', borderBottom: '1px solid rgba(255, 132, 2, 1)', fontFamily: 'Roboto', fontWeight: '400', fontSize: '20px' }}>Priority-</p>
                            {self && self.role && self.role.length > 0 && (self.role[0].role === "ROLE_SUPERADMIN" ||
                                self.role[0].role === "ROLE_ADMIN") ?

                                <p className='edit-crud' onClick={() => {
                                    priorityColorMall.forEach(priority => {
                                        const foundInStatusList = priorityColorList.some(priorityColor => priorityColor.priorityColorCode === priority.priorityColorCode);
                                        if (foundInStatusList) {
                                            usedList.push(priority);
                                        } else {
                                            unusedList.push(priority);
                                        }
                                    });
                                    dispatch(setUsedList(usedList))
                                    dispatch(setUnUsedList(unusedList))
                                    dispatch(setIsEditPriority(true))
                                }}>Edit</p> : null}
                        </div>
                        <div className='statusTagsDefineColors'>
                            <div className='statusColorContainer'>
                                {priorityColorList && priorityColorList.length > 0 && priorityColorList.map((priority, index) => (
                                    <div style={{ borderRight: priorityColorList.length !== index + 1 ? '2px solid rgba(38, 50, 56, 0.3)' : null }} className='actualColorOfStatusDiv'>
                                        <div style={{ width: '30px', height: '20px', backgroundColor: priority.priorityColorCode, borderRadius: '5px' }}></div>
                                        <p>{priority.priority}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='status_info_conatiner'>
                        <div className='crud-operation-header'>
                            <p style={{ color: 'rgba(255, 132, 2, 1)', borderBottom: '1px solid rgba(255, 132, 2, 1)', fontFamily: 'Roboto', fontWeight: '400', fontSize: '20px' }}>Verification-</p>
                            {self && self.role && self.role.length > 0 && (self.role[0].role === "ROLE_SUPERADMIN" ||
                                self.role[0].role === "ROLE_ADMIN") ?
                                <p className='edit-crud' onClick={() => {
                                    verificationColorMall.forEach(verification => {
                                        const foundInStatusList = verificationColorList.some(verificationColor => verificationColor.verificationColorCode === verification.verificationColorCode);
                                        if (foundInStatusList) {
                                            usedList.push(verification);
                                        } else {
                                            unusedList.push(verification);
                                        }
                                    });
                                    dispatch(setUsedList(usedList))
                                    dispatch(setUnUsedList(unusedList))
                                    dispatch(setIsEditVerification(true))
                                }}>Edit</p> : null}
                        </div>
                        <div className='statusTagsDefineColors'>
                            <div className='statusColorContainer'>
                                {verificationColorList && verificationColorList.length > 0 && verificationColorList.map((verification, index) => (
                                    <div style={{ borderRight: verificationColorList.length !== index + 1 ? '2px solid rgba(38, 50, 56, 0.3)' : null }} className='actualColorOfStatusDiv'>
                                        <div style={{ width: '30px', height: '20px', backgroundColor: verification.verificationColorCode, borderRadius: '5px' }}></div>
                                        <p>{verification.verificationStatus}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='status_info_conatiner'>
                        <div className='crud-operation-header'>
                            <p style={{ color: 'rgba(255, 132, 2, 1)', borderBottom: '1px solid rgba(255, 132, 2, 1)', fontFamily: 'Roboto', fontWeight: '400', fontSize: '20px' }}>Department-</p>
                            {self && self.role && self.role.length > 0 && (self.role[0].role === "ROLE_SUPERADMIN" ||
                                self.role[0].role === "ROLE_ADMIN") ?
                                <p className='edit-crud' onClick={() => {
                                    departmentColorMall.forEach(department => {
                                        const foundInStatusList = departmentColorList.some(departmentColor => departmentColor.departmentColorCode === department.departmentColorCode);
                                        if (foundInStatusList) {
                                            usedList.push(department);
                                        } else {
                                            unusedList.push(department);
                                        }
                                    });
                                    dispatch(setUsedList(usedList))
                                    dispatch(setUnUsedList(unusedList))
                                    dispatch(setIsEditDepartment(true))
                                }}>Edit</p> : null}
                        </div>
                        <div className='statusTagsDefineColors'>
                            <div className='statusColorContainer'>
                                {departmentColorList && departmentColorList.length > 0 && departmentColorList.map((department, index) => (
                                    <div style={{ borderRight: departmentColorList.length !== index + 1 ? '2px solid rgba(38, 50, 56, 0.3)' : null }} className='actualColorOfStatusDiv'>
                                        <div style={{ width: '30px', height: '20px', backgroundColor: department.departmentColorCode, borderRadius: '5px' }}></div>
                                        <p>{department.departmentName}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                    <div className='status_info_conatiner'>
                        <div className='crud-operation-header'>
                            <p style={{ color: 'rgba(255, 132, 2, 1)', borderBottom: '1px solid rgba(255, 132, 2, 1)', fontFamily: 'Roboto', fontWeight: '400', fontSize: '20px' }}>Designation-</p>
                            {self && self.role && self.role.length > 0 && (self.role[0].role === "ROLE_SUPERADMIN" ||
                                self.role[0].role === "ROLE_ADMIN") ?
                                <p className='edit-crud' onClick={() => {
                                    colorMallStatus.forEach(status => {
                                        const foundInStatusList = statusList.some(statusColor => statusColor.statusColorCode === status.statusColorCode);
                                        if (foundInStatusList) {
                                            usedList.push(status);
                                        } else {
                                            unusedList.push(status);
                                        }
                                    });
                                    dispatch(setUsedList(usedList))
                                    dispatch(setUnUsedList(unusedList))
                                    dispatch(setIsEditDesignation(true))
                                }}>Edit</p> : null}

                        </div>
                        <div className='statusTagsDefineColors'>
                            <div className='statusColorContainer'>
                                {designationList && designationList.length > 0 && designationList.map((designation, index) => (
                                    <div className='actualColorOfStatusDiv designationDiv'>
                                        {/* <div style={{ width: '30px', height: '20px', backgroundColor: status.statusColorCode, borderRadius: '5px' }}></div> */}
                                        <div className='designation-holder'><p>{designation.designation}</p></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditStatus ?
                <StatusCrud statusList={statusList} usedList={usedList} unusedList={unusedList} /> : null
            }
            {isEditPriority ?
                <PriorityCrud statusList={priorityColorList} usedList={usedList} unusedList={unusedList} /> : null
            }
            {isEditDepartment ?
                <DepartmentCrud statusList={departmentColorList} usedList={usedList} unusedList={unusedList} /> : null
            }
            {isEditVerification ?
                <VerificationCrud statusList={verificationColorList} usedList={usedList} unusedList={unusedList} /> : null
            }
            {isEditDesignation ?
                <DesignationCrud /> : null
            }
        </div>
    )
}

export default Information