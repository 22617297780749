import React from 'react'
import ProjectList from './project/ProjectList';
import "./styleProjectSection.css";

function ProjectSection() {
  return (
    <div className='project-section'>
      <div className='project-section-container'>
        <ProjectList />
      </div>
    </div>
  )
}

export default ProjectSection
