import React, { useState } from 'react';
import "./styleSettings.css";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setAttendMusteredFlag, setAttendenceAnalyticsDate, setAttendenceMontViewFlag, setChangePass, setInfoShow, setLeaveCalenderViewFlag, setMusteredObj, setMyLeaveStatusFlag, setOnLeaveDate, setScannerViewFlag, setView } from '../../../erpStore/actions';
import leaveMangemnetPng from '../../../assets/images/VectorleaveManagementPng.png';
import AttendencePng from '../../../assets/images/VectorAttendence.png';
import scannerPng from '../../../assets/images/VectorScannerPng.png';
import infromationPng from '../../../assets/images/infromationPng.png'
import changePassPng from '../../../assets/images/changePassPng.png'
import Information from '../Information/Information';

function Setting() {

    const dispatch = useDispatch();
    const selfInfo = useSelector((state) => state.data.selfData)
    const show = useSelector((state) => state.data.infoShow);
    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);

    const [val, setVal] = useState({
        loginId: selfInfo.userId,
        cntPassword: "",
        newPassword: '',
        cnfPassword: "",
    });

    const [errors, setErrors] = useState({
        currentPass: "",
        newPass: "",
        cnfPass: "",
    });

    const url = useSelector((state) => state.data.apiUrl);

    return (
        <div className='settings-container'>
            {show === 'information' ? <Information /> :
                <div className='settings-inner-container'>
                    <div className='change-pass' onClick={() => dispatch(setInfoShow('information'))}>
                        <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '10px' }}>
                            <img src={infromationPng} alt="" />
                        </div>
                        <div className='change-pass-heading'>Information</div>
                    </div>
                    <div className='change-pass' onClick={() => dispatch(setChangePass(true))}>

                        <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '10px' }}>
                            <img src={changePassPng} alt="" />
                        </div>
                        <div className='change-pass-heading'>Change Password?</div>
                    </div>
                    {(selfInfo?.role[0]?.role === 'ROLE_SUPERADMIN' || selfInfo?.role[0]?.role === 'ROLE_ADMIN') &&
                        <div className='change-pass' onClick={() => {
                            dispatch(setLeaveCalenderViewFlag(true))
                            dispatch(setView('month'))
                        }}>

                            <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '10px' }}>
                                <img src={leaveMangemnetPng} alt="" />
                            </div>
                            <div className='change-pass-heading'>Leaves Management</div>
                        </div>
                    }

                    <div className='change-pass' onClick={ (selfInfo.role[0].role === 'ROLE_ADMIN' || selfInfo.role[0].role === 'ROLE_SUPERADMIN' ) ? () => {
                        // dispatch(setAttendenceMontViewFlag(true))
                        dispatch(setAttendenceMontViewFlag(false))
                        dispatch(setAttendMusteredFlag(true))
                        dispatch(setMyLeaveStatusFlag(false))
                    } : () => {
                        dispatch(setMyLeaveStatusFlag(true))
                        dispatch(setAttendenceAnalyticsDate(new Date()))
                        // axios.post(`${url}attendance/get-for-employee`, {"empId": selfInfo.empId, "monthId": (new Date().getMonth() + 1), "year": new Date().getFullYear()}, {headers : storedHeaders})
                        // .then(response => {
                        //     if(response.request.status === 200){
                        //         dispatch(setMusteredObj(response.data))
                        //         dispatch(setMyLeaveStatusFlag(true))
                        //         dispatch(setAttendenceAnalyticsDate(new Date()))
                        //     }
                        // })
                        // .catch(error => {
                        //     toast.error('error occured', {autoClose: 2000})
                        // })
                    }}>

                        <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '10px' }}>
                            <img src={AttendencePng} alt="" />
                        </div>
                        <div className='change-pass-heading'>Attendance</div>
                    </div>
 
                    <div className='change-pass' onClick={() => {
                        dispatch(setScannerViewFlag(true))
                        const today = new Date()
                        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    
                        const dayOfWeek = daysOfWeek[today.getDay()];
                        const day = String(today.getDate()).padStart(2, '0'); // Ensures two-digit day
                        const month = months[today.getMonth()];
                        const year = today.getFullYear();
                        const tempDate = `${dayOfWeek}, ${day} ${month} ${year}`;
                        dispatch(setOnLeaveDate(tempDate))
                    }}>

                        <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '10px' }}>
                            <img src={scannerPng} alt="" />
                        </div>
                        <div className='change-pass-heading'>Scanner</div>
                    </div>
                </div>
            }

        </div>
    );
}

export default Setting
