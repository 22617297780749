import React from 'react'
import './MeetingCards.css'
function MeetingCards() {
  return (
    <div className='MeetingsCards_mainContainer'>
      <div className='MeetingsCard_header'>
        <p>Meetings</p>
        <div className='meetingsCardsCrud'>
            <div className='meetingsCrud'>
            {/* Svg add here */}
            <p>Today(0)</p>
            </div>
            <div className='meetingsCrud'>
            {/* Svg add here */}
            <p>Upcoming(0)</p>
            </div>
            <div className='meetingsCrud'>
            {/* Svg add here */}
            <p>Completed(0)</p>
            </div>
            <div className='meetingsCrud'>
            {/* Svg add here */}
            <p>Cancelled(3)</p>
            </div>  
        </div>
        
      </div>
    </div>
  )
}

export default MeetingCards
