import React, { useEffect, useRef, useState } from 'react'
import './MyLeaveStatus.css'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { Calendar as RouteCalender } from 'primereact/calendar';
import ReactApexChart from 'react-apexcharts';
import {
    setAttendenceMontViewFlag,
    setHolidayListFlag,
} from '../../../../erpStore/actions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


function MyLeaveStatus() {

    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);
    const attendenceDate = useSelector((state) => state.data.attendenceAnalyticsDate)
    const selfData = useSelector((state) => state.data.selfData)
    const selfInfo = useSelector((state) => state.data.selfData)
    const dispatch = useDispatch()

    const [isMonthAnalytics, setIsMonthAnalytics] = useState(false)
    const [isYearAnalytics, setIsYearAnalytics] = useState(false)
    const [isQuaterAnylytics ,setIsQuarterAnalytics]= useState (false)
    const yearDateRouteWrapper = useRef(null)
    const mothDateRouteWrapper = useRef(null)
    const [isStatusAnaLeave, setIsStatusAnaLeave] = useState(false)

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      

    const [selectedBtn, setSelectedBtn] = useState('month')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const apiUrl = useSelector((state) => state.data.apiUrl);
    const navigate = useNavigate();
    const [analyticsMonth, setAnalyticsMonth] = useState(attendenceDate);

    const [quaterYear, setQuaterYear] = useState(new Date().getFullYear());
    const [isQuaterYear, setIsQuaterYear] = useState(false)

    const empJoiningDate = useSelector((state) => state.data.empJoiningDate)
    const myLeaveStatusFlag = useSelector((state) => state.data.myLeaveStatusFlag)

    const musterdAttObj = useSelector((state) => state.data.musterdAttObj)
    const [analyaticsType, setAnalyticsType] = useState('month')
    const [leaveAnalyticsData, setLeaveAnalyticsData] = useState(null)
    const quaterRouteRef = useRef(null)

    useEffect(() => {
        function handleOutsideClick(event) {
            if (mothDateRouteWrapper && mothDateRouteWrapper.current && !mothDateRouteWrapper.current.contains(event.target)) {
                setIsMonthAnalytics(false)
            } else if (yearDateRouteWrapper && yearDateRouteWrapper.current && !yearDateRouteWrapper.current.contains(event.target)) {
                setIsYearAnalytics(false)
            } else if (quaterRouteRef && quaterRouteRef.current && !quaterRouteRef.current.contains(event.target)) {
                setIsQuarterAnalytics(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])


    const [monthBtn, setMonthBtn] = useState(monthNames[(analyticsMonth.getMonth())])
    const isDetailAnalytics = useSelector((state) => state.data.isDetailAnalytics)
    const analyticsEmp = useSelector((state) => state.data.analyticsEmp)

    const getLeaveAnalyticsData = (value) => {
        setIsStatusAnaLeave(true)
            axios.post(`${apiUrl}attendance/get-for-employee`, {
                "empId" : (selfInfo?.role[0].roleLevel === 1 || selfInfo?.role[0].roleLevel === 2)  ? musterdAttObj?.empId : selfInfo?.empId,
                "monthId" : value?.getMonth() + 1,
                'year' : value.getUTCFullYear()
            }, {headers: storedHeaders})
            .then(response => {
                if(response.request.status === 200){
                    setLeaveAnalyticsData(response.data)
                    setIsStatusAnaLeave(false)
                    
                }       
            })
            .catch(error => {
                setLeaveAnalyticsData(null)
                setIsStatusAnaLeave(false)
                if(error.request.status === 422){
                    toast.error(`${error.response.data.message}`, {autoClose: 2000})
                    setLeaveAnalyticsData(null)
                }
            })
    }

    useEffect(() => {
        getLeaveAnalyticsData(analyticsMonth)
    }, [])




    const [selectedQuarter, setSelectedQuarter] = useState('Quarter')
    const handleQuarterData = async (year, quarterId) => {
        setIsStatusAnaLeave(true)
        axios.post(`${apiUrl}attendance/get-for-employee`, {
            "empId" : (selfInfo?.role[0].roleLevel === 1 || selfInfo?.role[0].roleLevel === 2)  ? musterdAttObj?.empId : selfInfo?.empId,
            "year" : year,
            "quarterId" : quarterId
        }, {headers: storedHeaders})
        .then(response => {
            if(response.request.status === 200){
                setLeaveAnalyticsData(response.data)  
                setIsStatusAnaLeave(false)        
            }   
        })
        .catch(error => {
            setIsStatusAnaLeave(false)   
            setLeaveAnalyticsData(null)     
            if(error.request.status === 403){
                toast.error(`${error.response.data.message}`, {autoClose: 2000})
                setLeaveAnalyticsData(null)
            }
        }) 
    }

    const [yearBtn, setYearBtn] = useState('Year')
    const handleYearAnalytics = async (e) => {
        setIsStatusAnaLeave(true)        
        setIsYearAnalytics(false)
        const date = new Date(e.target.value)
        const year = date.getFullYear();
        setYearBtn(year)   
        setIsYearAnalytics(!isYearAnalytics)

        axios.post(`${apiUrl}attendance/get-for-employee`, {
            "empId" : (selfInfo?.role[0].roleLevel === 1 || selfInfo?.role[0].roleLevel === 2)  ? musterdAttObj?.empId : selfInfo?.empId,
            "year" : year
        }, {headers: storedHeaders})
        .then(response => {
            if(response.request.status === 200){
                setLeaveAnalyticsData(response.data)
                setIsYearAnalytics(false)
                setIsStatusAnaLeave(false)        
            }   
        })
        .catch(error => {
            setIsStatusAnaLeave(false)        
            setIsYearAnalytics(false)
            setLeaveAnalyticsData(null)
            toast.error(`${error.response.data.message}`, {autoClose: 2000})

        })
    }

    const [percentage, setPercentage] = useState(0)
    const [radialData, setRadialData] = useState({
        series: [percentage], // Series data
        options: {
            chart: {
                type: 'radialBar',
                background: '#ffffff', // Set the background color here
                height: 50, // Set the height dynamically
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '40%',
                        color: "#ffffff"
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: -10,
                            show: false,
                            color: '#888',
                            fontSize: '12px',
                        },
                        value: {
                            color: '#111',
                            fontSize: '12px',
                            offsetY: 5,
                            show: true,
                            formatter: function (val) {
                                return val + '%';
                            },
                        },
                    },
                },

            }, fill: {
                opacity: 1.5,
                colors: ['rgba(255, 132, 2, 1)'],
            },

            labels: ['Progress'], // Labels for each data point
        },
    });


    useEffect(() => {
        let empPresentDays = parseInt(leaveAnalyticsData?.empPresentDays);
        let workingDays = parseInt(leaveAnalyticsData?.workingDays);
        let isValidData = !isNaN(empPresentDays) && !isNaN(workingDays) && workingDays !== 0;
        let percentage = isValidData ? (empPresentDays / workingDays) * 100 : 0;
        setPercentage(parseInt(percentage))
        setRadialData({
            series: [parseInt(percentage)], // Series data
            options: {
                chart: {
                    type: 'radialBar',
                    background: '#ffffff', // Set the background color here
                    height: 50, // Set the height dynamically
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: '40%',
                            color: "#ffffff"
                        },
                        dataLabels: {
                            showOn: 'always',
                            name: {
                                offsetY: -10,
                                show: false,
                                color: '#888',
                                fontSize: '12px',
                            },
                            value: {
                                color: '#111',
                                fontSize: '12px',
                                offsetY: 5,
                                show: true,
                                formatter: function (val) {
                                    return val + '%';
                                },
                            },
                        },
  
                    },
    
                }, fill: {
                    opacity: 1.5,
                    colors: ['rgba(255, 132, 2, 1)'],
                },
    
                labels: ['Progress'], // Labels for each data point
            },
        })
    }, [leaveAnalyticsData])

    return (
        <div className="LeaveCalMainView">
            <div className='leaveManagement-header' style={{ paddingLeft: '0px' }}>
                <div className='leaveManagement-header'>
                    <div className='leaveManHeading'>
                        {myLeaveStatusFlag === true && (selfInfo.role[0].roleLevel === 1 || selfInfo.role[0].roleLevel === 2 ) && 
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} onClick={() => {
                                // dispatch(setMyLeaveStatusFlag(false))
                                // dispatch(setAttendMusteredFlag(false))
                                dispatch(setAttendenceMontViewFlag(true))
                            }}>
                                <path d="M19 12.8105H5" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 19.8105L5 12.8105L12 5.81055" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        }

                        {
                        
                            selfInfo.role[0].role !== 'ROLE_ADMIN' && selfInfo.role[0].role !== 'ROLE_SUPERADMIN'  ?
                                <div className='nameForAttendence'>
                                    My Leaves Status
                                </div>
                        
                            : 
                                <div className="nameForAttendence">
                                    <div style={{ fontSize: '25px', color: '#263238' }}>{musterdAttObj?.empName}</div>
                                    <div style={{ fontSize: '20px', color: '#263238', marginTop: '10px' }}>{`(${musterdAttObj?.designation})`}</div>
                                </div>
                        }
                    </div>
                    <div className='headerSerchBtnDiv'>
                        <div className='OverallOptionBtn'>
                            <div className='holidayBtnDiv' style={{cursor: 'pointer'}} onClick={() => {
                                dispatch(setHolidayListFlag(true))
                            }}>
                                Holiday
                            </div>
                            <div className='analytics-parameters'>
                                <div className='analytics-month-range-container'>
                                    <div className='analytics-month-range' style={{ background: selectedBtn === 'month' ? "rgba(255, 246, 223, 1)" : null, borderLeft: selectedBtn === 'month' ? "1px solid rgba(255, 132, 2, 1)" : null }} onClick={() => {
                                        setIsMonthAnalytics(true)
                                        setSelectedBtn('month')
                                        }}>{monthBtn}</div>
                                    {isMonthAnalytics ?
                                        <div className='calender-holder-dashboard' ref={mothDateRouteWrapper} style={{ left: '0px' }}>
                                            <RouteCalender value={analyticsMonth} inline view='month' unstyled={false} onChange={(e) => {
                                                setAnalyticsMonth(e.target.value)
                                                setIsMonthAnalytics(false)
                                                getLeaveAnalyticsData(e.target.value)
                                                setSelectedQuarter('Quater')
                                                setYearBtn('Year')
                                                const monthNames = [
                                                    'January', 'February', 'March', 'April', 'May', 'June', 
                                                    'July', 'August', 'September', 'October', 'November', 'December'
                                                  ];
                                                  
                                                  const selectedMonth = monthNames[e.target.value.getMonth()];
                                                  // Assuming setMonthBtn is a function that sets the month name somewhere
                                                  setMonthBtn(selectedMonth);
                                            }}
                                                
                                                />
                                        </div> : null
                                    }
                                </div>

                                <div className='analytics-month-range-container'>
                                    <div className='analytics-month-range' style={{ background: selectedBtn === 'quarter' ? "rgba(255, 246, 223, 1)" : null, borderLeft: selectedBtn === 'quarter' ? "1px solid rgba(255, 132, 2, 1)" : null }} onClick={() => {setIsQuarterAnalytics(true) 
                                        setSelectedBtn('quarter')}}>{selectedQuarter}</div>
                                    {isQuaterAnylytics ? <div className='quater-calender' ref={quaterRouteRef}>{!isQuaterYear ? <div className='quater-calender-navigation'>
                                        <div className='quater-year-navigation' onClick={() => {
                                            setIsQuaterYear(true)
                                            }}>{quaterYear}</div>
                                        <div className='quater-navigaters'>
                                            <svg onClick={() => {
                                                const date = moment(`${quaterYear}-01-01`)
                                                const newDate = moment(date._d).subtract(1, 'year').toDate();
                                                setQuaterYear(newDate.getFullYear())
                                            }} width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.8679 6.42566C13.1809 6.73037 13.1722 7.23589 12.8489 7.52966C12.5539 7.79773 12.1017 7.79146 11.8142 7.51532L6.72262 2.62448L1.63106 7.51532C1.34358 7.79146 0.891329 7.79773 0.596309 7.52966C0.273013 7.23589 0.264311 6.73037 0.577304 6.42566L6.72262 0.442833L12.8679 6.42566Z" fill="#263238" fill-opacity="0.8" />
                                            </svg>
                                            <svg onClick={() => {
                                                const date = moment(`${quaterYear}-01-01`)
                                                const newDate = moment(date._d).add(1, 'year').toDate();
                                                setQuaterYear(newDate.getFullYear())
                                            }} width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.2 2.02356C13.513 1.71885 13.5043 1.21332 13.181 0.919561C12.8859 0.65149 12.4337 0.657757 12.1462 0.933902L7.05465 5.82474L1.96309 0.933901C1.67561 0.657757 1.22336 0.65149 0.92834 0.919561C0.605044 1.21332 0.596342 1.71885 0.909335 2.02356L7.05465 8.00639L13.2 2.02356Z" fill="#263238" fill-opacity="0.8" />
                                            </svg>
                                        </div>
                                    </div> : null

                                    }
                                        {isQuaterYear ?
                                            <RouteCalender value={moment(`${quaterYear}-01-01`)._d} inline view='year' unstyled={false} onChange={(e) => {
                                                const year = moment(e.target.value).year();
                                                setQuaterYear(year)
                                                setIsQuarterAnalytics(false)
                                            }} /> : <div className='quater-name-container'>
                                                <div className='quater-names' onClick={() => {
                                
                                                    handleQuarterData(quaterYear, "1")
                                                    setIsQuarterAnalytics(false)
                                                    setSelectedQuarter('Quarter-1')
                                                    setMonthBtn('Month');
                                                    setYearBtn('Year')

                                                }}>Quarter 1 (Jan, Feb, Mar)</div>
                                                <div className='quater-names' onClick={() => {
                                                    setMonthBtn('Month');
                                                    setYearBtn('Year')
                                                    handleQuarterData(quaterYear, "2")
                                                    setIsQuarterAnalytics(false)
                                                    setSelectedQuarter('Quarter-2')
                                                }}>Quarter 2 (Apr, May, Jun)</div>
                                                <div className='quater-names' onClick={() => {
                                                    setMonthBtn('Month');
                                                    setYearBtn('Year')
                                                    handleQuarterData(quaterYear, "3")
                                                    setIsQuarterAnalytics(false)
                                                    setSelectedQuarter('Quarter-3')
                                                    }}>Quarter 3 (Jul, Aug, Sep)</div>
                                                <div className='quater-names' onClick={() => {
                                                    setMonthBtn('Month');
                                                    setYearBtn('Year')
                                                    handleQuarterData(quaterYear, "4")
                                                    setIsQuarterAnalytics(false)
                                                    setSelectedQuarter('Quarter-4')
                                                    }}>Quarter 4 (Oct, Nov, Dec)</div>
                                            </div>
                                        }

                                    </div> : null
                                    }
                                </div>
                                <div className='analytics-month-range-container' onClick={() => {
                                    setIsYearAnalytics(true)
                                    setSelectedBtn('year')
                                    }}>
                                    <div className='analytics-month-range' style={{ background: selectedBtn === 'year' ? "rgba(255, 246, 223, 1)" : null, borderLeft: selectedBtn === 'year' ? "1px solid rgba(255, 132, 2, 1)" : null }}>{yearBtn}</div>
                                    {isYearAnalytics && 
                                        <div className='calender-holder-dashboard calender-holder-myleaveStatus' ref={yearDateRouteWrapper} style={{ left: '-209px' }}>
                                            <RouteCalender value={analyticsMonth} inline view='year' unstyled={false} onChange={(e) => {  
                                                handleYearAnalytics(e)
                                                setIsYearAnalytics(false)
                                                setSelectedQuarter('Quarter')
                                                setMonthBtn('Month')
                                            }} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='LeaveDayViewMainContainer'>
                <div className='analytics-count-container'>
                    <div className='analytics-card my-analytics-card emp' >
                        <div className='analytics-title-head-container'>
                            <div className='my-analytics-heading'>{"My Working Day's"}</div>
                            
                            < div className='my-analytics-count'>{leaveAnalyticsData?.empPresentDays ? leaveAnalyticsData?.empPresentDays : 0}/<span>{leaveAnalyticsData?.workingDays ? leaveAnalyticsData?.workingDays : 0 }</span></div>
                        </div>
                        <div className='analytics-count-donught-container'>
                            <div className='my-analytics-donught'><ReactApexChart options={radialData?.options} series={radialData?.series} type="radialBar" /></div>
                        </div>
                    </div>

                    <div className='analytics-card myLeaveStatusCard'>
                        <div className='analytics-title'>Total Leaves</div>
                        <div className='analytics-count'>{leaveAnalyticsData?.totalLeaveCount}</div>
                    </div>
                    <div className='analytics-card myLeaveStatusCard' >
                        <div className='analytics-title'>Total full day's leaves</div>
                        <div className='analytics-count'>{leaveAnalyticsData?.fullDayLeaves}</div>
                    </div>
                    <div className='analytics-card myLeaveStatusCard' >
                        <div className='analytics-title'>Total Half day's leaves</div>
                        <div className='analytics-count'>{leaveAnalyticsData?.halfDayLeaves}</div>
                    </div>
                    <div className='analytics-card myLeaveStatusCard'>
                        <div className='analytics-title'>Previous unused leaves</div>
                        <div className='analytics-count'>{leaveAnalyticsData?.unusedLeaves}</div>
                    </div>
                    <div className='analytics-card myLeaveStatusCard'>
                        <div className='analytics-title'>Current available leaves</div>
                        <div className='analytics-count'>{leaveAnalyticsData?.availableLeaves}</div>
                    </div>

                    <div className='analytics-card myLeaveStatusCard'>
                        <div className='analytics-title'>Total available paid leaves</div>
                        <div className='analytics-count'>{leaveAnalyticsData?.totalAvailablePaidLeaves}</div>
                    </div>
                    <div className='analytics-card myLeaveStatusCard'>
                        <div className='analytics-title'>Unpaid leaves</div>
                        <div className='analytics-count'>{leaveAnalyticsData?.unPaidLeaves}</div>
                    </div>
                </div>
                
                {
                    isStatusAnaLeave && <div class="loaderIdProjectsLoad">
                      <div class="loader"></div>
                        </div>
                }

            </div>
        </div>
    )
}

export default MyLeaveStatus