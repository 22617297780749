import React, { useEffect, useRef, useState } from 'react'
import './Scanner.css'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { setScannerAttendenceList, setOnLeaveDate } from '../../../erpStore/actions'
import { Space, TimePicker } from "antd";
import { toast } from 'react-toastify'
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import profile from '../../../assets/images/profile.png'
import { Loader } from 'rsuite'

function Scanner() {

  const selfInfo = useSelector((state) => state.data.selfData)
  const dispatch = useDispatch()
  const url = useSelector((state) => state.data.apiUrl)
  const stringHeaders = window.localStorage.getItem('myHeaders');
  const onLeaveDate = useSelector((state) => state.data.onLeaveDate)
  const userId = window.localStorage.getItem('id')
  const storedHeaders = JSON.parse(stringHeaders);
  const [routeDate, setRouteDate] = useState("")
  const scannerAttendenceList = useSelector((state) => state.data.scannerAttendenceList)
  const [isInTimeRadio, setInTimeRadio] = useState(true)
  const [isInOutRadio, setInOutRadio] = useState(false)
  const [userIdPut, setUserIdPut] = useState(null)
  const dateRouteRef = useRef(null);
  const [tempAttObj, setTempAttObj] = useState(null)
  const [inTime, setInTime] = useState()
  const [isDateRoute, setIsDateRoute] = useState(false)
  const fdSplit = onLeaveDate?.toString().split(", ")

  const [inTimeIndex, setInTimeIndex] = useState(null)
  const [outTimeIndex, setOutTimeIndex] = useState(null)
  const [isInTimeFlag, setIsInTimeFlag] = useState(false)

  const [isOutTimeFlag, setIsOutTimeFlag] = useState(false)

  const [remountFlag, setRemountFlag] = useState(false)

  const [loader, setLoader] = useState(false)

  const [isScanLoad, setIsScanLoad] = useState(false)
  const [isScanIndex, setIsScanIndex] = useState(false)
  const [isRotating, setRotating] = useState(false)


  const parseDateString = (dateString) => {
    const [weekday, day, month, year] = dateString.split(' ');
    const monthMap = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',  
      Oct: '10',
      Nov: '11',
      Dec: '12'
    };

    const monthNumber = monthMap[month];
    return `${year}-${monthNumber}-${day.padStart(2, '0')}`;
  };

  const callScannerAPI = () => {
    setLoader(true) 
    dispatch(setScannerAttendenceList([]))
    axios.post(`${url}attendance/for-day`, { "date": parseDateString(onLeaveDate) }, { headers: storedHeaders })
      .then(response => {
        dispatch(setScannerAttendenceList(response.data.attendance))
        setIsDateRoute(false)
        setLoader(false)
        setRotating(false)
      })
      .catch(error => {
        
        setLoader(false)
      })
  }

  useEffect(() => {
    callScannerAPI()
  }, [onLeaveDate])


  function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();

    // Ensure hours, minutes, and seconds are two digits
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    const formattedTime = hours + ':' + minutes + ':' + seconds;
    return formattedTime;
  }

  const findEmployeeByUserId = (userId) => {
    return scannerAttendenceList.find(employee => employee?.userId?.toString() === userId?.toString());
  };

  const handleKeyDawnAttendence = (e) => {
    const employee = findEmployeeByUserId(e.target.value)
    setUserIdPut(e.target.value)

    if(employee?.leave !== null && employee?.leave?.leaveFormat === 'FULLDAY'){ 
      toast.error(`${employee?.empFullName} is on full day leave can not update attendence`)
      setUserIdPut('')
      return
  }

  if (isInTimeRadio === true && e.key === 'Enter' && (selfInfo.role[0].roleLevel === 1 || selfInfo.role[0].roleLevel === 2 ) && employee?.inTime === null ) {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`
    if(parseDateString(onLeaveDate) < formattedDate){
      toast.error("can not update the attendence", {autoClose: 2000})
      return 
    }

    axios.put(`${url}attendance/update-attendance`,
      {
        "attendanceDate": parseDateString(onLeaveDate), 
        "userId": e.target.value,
        "inTime": getCurrentTime()
      },
      { headers: storedHeaders }
    )
      .then(response => {
        if (response.request.status === 200) {
          setUserIdPut('')
          const inTime = getCurrentTime()
          const index = scannerAttendenceList.findIndex(user => user.userId.toString() === response.data.userId.toString());
          const user = scannerAttendenceList.find(user => user.userId.toString() === response.data.userId.toString());
          scannerAttendenceList[index].inTime = getCurrentTime()
          dispatch(setScannerAttendenceList(scannerAttendenceList))
        }
      })
      .catch(error => {
        const inTime = getCurrentTime()
        setUserIdPut('')
      })
  }

  if (isInOutRadio === true && e.key === 'Enter' &&  (selfInfo.role[0].roleLevel === 1 || selfInfo.role[0].roleLevel === 2 ) && employee?.outTime === null ) {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`
    if(parseDateString(onLeaveDate) < formattedDate){
      toast.error("can not update the attendence", {autoClose: 2000})
      return 
    }

    axios.put(`${url}attendance/update-attendance `,
      {
        "attendanceDate": parseDateString(onLeaveDate),
        "userId": e.target.value,
        "outTime": getCurrentTime()
      },
      { headers: storedHeaders }
    )
      .then(response => {
        if (response.request.status === 200) {
          const outTime = getCurrentTime()
          setUserIdPut('')
          const index = scannerAttendenceList.findIndex(user => user.userId.toString() === response.data.userId.toString());
          const user = scannerAttendenceList.find(user => user.userId.toString() === response.data.userId.toString());
          scannerAttendenceList[index].outTime = getCurrentTime()
          dispatch(setScannerAttendenceList(scannerAttendenceList))
        }
      })
      .catch(error => {
        const outTime = getCurrentTime()
        setUserIdPut('')
        
      })
  }
}

const findEmployeeByCardId = (cardId) => {
  return scannerAttendenceList.find(employee => employee?.cardId?.toString() === cardId?.toString());
};

  const handlePutAttendene = (e) => {
      setUserIdPut(e.target.value)
      const employee = findEmployeeByCardId(e.target.value)
      const index = scannerAttendenceList?.findIndex(obj => obj?.userId === employee?.userId)
      if(employee?.leave !== null && employee?.leave?.leaveFormat === 'FULLDAY'){ 
        toast.error(`${employee?.empFullName} is on full day leave can not update attendence`)
        setUserIdPut('')
        return
    }

      if (isInTimeRadio === true  && employee?.inTime === null ) {
        setIsScanLoad(true)
        setIsScanIndex(index)
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        
        const formattedDate = `${year}-${month}-${day}`
        if(parseDateString(onLeaveDate) < formattedDate){
          toast.error("can not update the attendence", {autoClose: 2000})
          return 
        }

        axios.put(`${url}attendance/update-attendance`,
          {
            "attendanceDate": parseDateString(onLeaveDate),
            "cardId": e.target.value,
            "inTime": getCurrentTime()
          },
          { headers: storedHeaders }
        )
          .then(response => {
            if (response.request.status === 200) {
              setIsScanLoad(false)
              setUserIdPut('')
              const inTime = getCurrentTime()
              const index = scannerAttendenceList.findIndex(user => user.userId.toString() === response.data.userId.toString());
              const user = scannerAttendenceList.find(user => user.userId.toString() === response.data.userId.toString());
              scannerAttendenceList[index].inTime = response.data.inTime
              dispatch(setScannerAttendenceList(scannerAttendenceList))
            }
          })
          .catch(error => {
            const inTime = getCurrentTime()
            setIsScanLoad(false)
            setUserIdPut('')
            
          })
      }


      if (isInOutRadio === true &&  employee?.outTime === null) {
          const date = new Date();
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
          const day = String(date.getDate()).padStart(2, '0');
          
          const formattedDate = `${year}-${month}-${day}`
          if(parseDateString(onLeaveDate) < formattedDate){
            toast.error("can not update the attendence", {autoClose: 2000})
            return 
          }
        axios.put(`${url}attendance/update-attendance `,
          {
            "attendanceDate": parseDateString(onLeaveDate),
            "cardId": e.target.value,
            "outTime": getCurrentTime()
          },
          { headers: storedHeaders }
        )
          .then(response => {
            if (response.request.status === 200) {
              const outTime = getCurrentTime()
              setUserIdPut('')
              const index = scannerAttendenceList.findIndex(user => user.userId.toString() === response.data.userId.toString());
              const user = scannerAttendenceList.find(user => user.userId.toString() === response.data.userId.toString());
              scannerAttendenceList[index].outTime = response.data.outTime
              dispatch(setScannerAttendenceList(scannerAttendenceList))
            }
          })
          .catch(error => {
            const outTime = getCurrentTime()
            setUserIdPut('')
            
          })
      }
  }

  const handleInTime = (time, timeString) => {

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`
    if(parseDateString(onLeaveDate) < formattedDate){
      toast.error("can not update the attendence", {autoClose: 2000})
      return 
    }

    let hour = time.$H < 10 ? '0' + time.$H : time.$H
    let minute = time.$m < 10 ? '0' + time.$m : time.$m
    let seconds = time.$s < 10 ? '0' + time.$s : time.$s
    let temptimeString = `${hour}:${minute}:${seconds}`;
    axios.put(`${url}attendance/update-attendance`, { "attendanceDate": parseDateString(onLeaveDate), "inTime": temptimeString, "userId": tempAttObj.userId }, { headers: storedHeaders })
      .then(response => {
        if (response.request.status === 200) {
          const index = scannerAttendenceList.findIndex(user => user.userId.toString() === tempAttObj.userId.toString());
          scannerAttendenceList[index].inTime = response.data.inTime
          dispatch(setScannerAttendenceList(scannerAttendenceList))
          toast.success('intime update successfully', { autoClose: 2000 })
          setIsInTimeFlag(false)
          setInTimeIndex(null)
        }
      })
      .catch(error => {
        setIsInTimeFlag(false)
      })
  };

  const handleOutTime = (time, timeString) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`
    if(parseDateString(onLeaveDate) < formattedDate){
      toast.error("can not update the attendence", {autoClose: 2000})
      return 
    }

    let hour = time.$H < 10 ? '0' + time.$H : time.$H
    let minute = time.$m < 10 ? '0' + time.$m : time.$m
    let seconds = time.$s < 10 ? '0' + time.$s : time.$s
    let temptimeString = `${hour}:${minute}:${seconds}`;
    axios.put(`${url}attendance/update-attendance`, { "attendanceDate": parseDateString(onLeaveDate), "outTime": temptimeString, "userId": tempAttObj.userId }, { headers: storedHeaders })
      .then(response => {
        if (response.request.status === 200) {
          const index = scannerAttendenceList.findIndex(user => user.userId.toString() === tempAttObj.userId.toString());
          scannerAttendenceList[index].outTime = response.data.outTime
          dispatch(setScannerAttendenceList(scannerAttendenceList))
          toast.success('outTime updated successfully', {autoCloseL: 2000})
          setIsInTimeFlag(false)
          setOutTimeIndex(null)
          
        }
      })
      .catch(error => {
        setIsInTimeFlag(false)
        
      })
  }
  
  const handlePrevDate = () => {
    const currentDate = moment(fdSplit[1]);
    // dispatch(setLoader(true))
    const previousDate = currentDate.subtract(1, 'day');
    dispatch(setOnLeaveDate(previousDate.format('dddd') + ", " + previousDate.date().toString().padStart(2, "0") + " " + previousDate.format('MMM') + " " + previousDate.year()))
    window.sessionStorage.setItem('date', previousDate.format('dddd') + ", " + previousDate.date().toString().padStart(2, "0") + " " + previousDate.format('MMM') + " " + previousDate.year())
    // setRouteDate(previousDate._d)
  }

  const handleNextDate = () => {
    const currentDate = moment(fdSplit[1]);
    const nextDate = currentDate.add(1, 'day')
    dispatch(setOnLeaveDate(nextDate.format('dddd') + ", " + nextDate.date().toString().padStart(2, "0") + " " + nextDate.format('MMM') + " " + nextDate.year()))
    window.sessionStorage.setItem('date', nextDate.format('dddd') + ", " + nextDate.date().toString().padStart(2, "0") + " " + nextDate.format('MMM') + " " + nextDate.year())
    // setRouteDate(nextDate._d)
  }


  const handleRouteDate = (e) => {
    const date = new Date(e.target.value);

    // Get the day of the week, day of the month, month, and year from the date object
    const weekDay = date.toLocaleString('default', { weekday: 'long' });
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    // Format the date as "Weekday, DD MMM YYYY"
    const tempString =  `${weekDay}, ${day} ${month} ${year}`;

    dispatch(setOnLeaveDate(tempString))
    // setIsDateRoute(false)
  }


  function convertTimeFormat(timeString) {
    const [hours, minutes] = timeString.split(':');

    let hourNum = parseInt(hours, 10);
    const ampm = hourNum >= 12 ? 'pm' : 'am';

    hourNum = hourNum % 12;
    hourNum = hourNum === 0 ? 12 : hourNum;

    const hourStr = hourNum < 10 ? '0' + hourNum : hourNum;
    let minuteStr = ''
    if (minutes === '00') {
      minuteStr = '00'
    } else {
      minuteStr = minutes < 10 && minutes !== 0 ? '' + minutes : minutes;
    }
    return hourStr + ':' + minuteStr + ' ' + ampm;
  }


  const handleInTimeManual = (attObj) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`
    if(parseDateString(onLeaveDate) < formattedDate){
      toast.error("can not update the attendence", {autoClose: 2000})
      return 
    }

    if(attObj?.leave !== null && attObj?.leave?.leaveFormat === 'FULLDAY'){
        toast.error(`${attObj?.empFullName} is on full day leave can not update attendence`)
        return
    }
    
    if(attObj.inTime === null){
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      const temptimeString =  `${hours}:${minutes}:${seconds}`;
      const index = scannerAttendenceList.findIndex(user => user.userId === attObj.userId);
      scannerAttendenceList[index].inTime = temptimeString
      axios.put(`${url}attendance/update-attendance`, { "attendanceDate": parseDateString(onLeaveDate), "inTime": temptimeString, "userId": attObj.userId }, { headers: storedHeaders })
      .then(response => {
        if (response.request.status === 200) {
          scannerAttendenceList[index].inTime = response.data.inTime
          dispatch(setScannerAttendenceList(scannerAttendenceList))
          toast.success('in time update successfully', { autoClose: 2000 })
          
        }
      })
      .catch(error => {
        setIsInTimeFlag(false)
        const index = scannerAttendenceList.findIndex(user => user.userId === attObj.userId);
        scannerAttendenceList[index] = attObj
        dispatch(setScannerAttendenceList(scannerAttendenceList))
        
      })
    }else{
      const index = scannerAttendenceList.findIndex(user => user.userId === attObj.userId);
      scannerAttendenceList[index] = attObj
      dispatch(setScannerAttendenceList(scannerAttendenceList))
      toast.error('in time already updated', {autoClose: 2000})
    }      
  }

  

  const handleOutTimeManual = (attObj) => {
       
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`

    if(parseDateString(onLeaveDate) < formattedDate){
      toast.error("can not update the attendence", {autoClose: 2000})
      return 
    }
    
    if(attObj?.leave !== null && attObj?.leave?.leaveFormat === 'FULLDAY'){
      toast.error(`${attObj?.empFullName} is on full day leave can not update attendence`)
      return
  }

    const index = scannerAttendenceList.findIndex(user => user.userId.toString() === attObj.userId.toString());
    if(attObj.outTime === null){
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      const temptimeString =  `${hours}:${minutes}:${seconds}`;
      const index = scannerAttendenceList.findIndex(user => user.userId === attObj.userId);
      scannerAttendenceList[index].outTime = temptimeString
      dispatch(setScannerAttendenceList(scannerAttendenceList))
      axios.put(`${url}attendance/update-attendance`, { "attendanceDate": parseDateString(onLeaveDate), "outTime": temptimeString, "userId": attObj.userId }, { headers: storedHeaders })
      .then(response => {
        if (response.request.status === 200) {
          scannerAttendenceList[index].outTime = response.data.outTime
          dispatch(setScannerAttendenceList(scannerAttendenceList))
          toast.success('out time update successfully', { autoClose: 2000 })
        }
      })
      .catch(error => {
        setIsInTimeFlag(false)
        const index = scannerAttendenceList.findIndex(user => user.userId === attObj.userId);
        scannerAttendenceList[index] = attObj
        dispatch(setScannerAttendenceList(scannerAttendenceList))
      })
    }else{
      const index = scannerAttendenceList.findIndex(user => user.userId === attObj.userId);
      scannerAttendenceList[index] = attObj
      dispatch(setScannerAttendenceList(scannerAttendenceList))
      toast.error('out time already updated', {autoClose: 2000})
    }    
  }

  useEffect(() => {
    dispatch(setScannerAttendenceList(scannerAttendenceList))
  }, [remountFlag])

  
  useEffect(() => {
    function handleOutsideClick(event) {
      if (dateRouteRef && dateRouteRef.current && !dateRouteRef.current.contains(event.target)) {
        setIsDateRoute(false)
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])


  const handleFutureDate = () => {
    const date = new Date(onLeaveDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const date1 = new Date();
    const year1 = date1.getFullYear();
    const month1 = String(date1.getMonth() + 1).padStart(2, '0');
    const day1 = String(date1.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}` < `${year1}-${month1}-${day1}` ? true : false;
  }

  return (
    <div className="LeaveCalMainView">
      <div className='leaveManagement-header'>
        <div className='leaveManHeading'>
          Scanner
        </div>
      </div>
      <div className='LeaveDayViewMainContainer'>
          <div className='leaveDayViewCalenderHeader'>
          { (selfInfo.role[0].role === 'ROLE_ADMIN' || selfInfo.role[0].role === 'ROLE_SUPERADMIN') &&
            <div className='dayViewDateSeletor LeavedayViewDateSelector'>
              <svg onClick={handlePrevDate} width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }}>
                <path d="M5.19231 0L6 0.916667L1.73077 5.5L6 10.0833L5.19231 11L4.76837e-07 5.5L5.19231 0Z" fill="#FF8402" />
              </svg>
              <div className='date-holder-dashboard' onClick={() => { setIsDateRoute(true) }}>
                <p onClick={() => ""}>{onLeaveDate}</p>
                {isDateRoute &&
                  <div className='calender-holder-dashboard' ref={dateRouteRef}>
                    <Calendar value={moment(routeDate)._d} inline onChange={(e) => {  
                      handleRouteDate(e)
                      setRouteDate(e.target.value)
                    }} unstyled={false} maxDate={new Date()} />
                  </div>
                }

              </div>
              { handleFutureDate() && 
                <svg onClick={handleNextDate} width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }}>
                  <path d="M0.807692 0L0 0.916667L4.26923 5.5L0 10.0833L0.807692 11L6 5.5L0.807692 0Z" fill="#FF8402" />
                </svg>
              }
            </div>
            }
            <div className='scannerBtnsField'>
              <button className='scannetIntimeBtn' style={{
                background: isInTimeRadio === true ? '#FF8402' : 'transparent',
                color: isInTimeRadio === true ? '#fff' : '#263238',
                border: isInTimeRadio === false ? '0.5px solid grey' : 'none'
              }} onClick={() => {
                setInTimeRadio(true)
                setInOutRadio(false)
              }}>In Time</button>
              <button className='scannetOuttimeBtn' style={{
                background: isInOutRadio === true ? '#FF8402' : 'transparent',
                color: isInOutRadio === true ? '#fff' : '#263238',
                border: isInOutRadio === false ? '0.5px solid grey' : 'none'
              }} onClick={() => {
                setInTimeRadio(false)
                setInOutRadio(true)
              }}>Out Time</button>
              <input type='text' autocomplete="off"  style={{color: (selfInfo?.role[0].role === 'ROLE_ADMIN' || selfInfo?.role[0].role === 'ROLE_SUPERADMIN') ? '#263238' : '#FFF'}} id="idInput" value={userIdPut} onPaste={() => { return false}} onCopy={ () => {return false}} className='inputFieldForId' autoFocus={true} placeholder='Employee Id'  onChange={(e) => {
                handlePutAttendene(e)
              }} 

              onKeyDown={ (e) => {
                handleKeyDawnAttendence(e)
              }}
              />
            </div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="refresh-icon"
              className={isRotating ? "rotateRefresh" : ""}
              onClick={
                () => {
                  setRotating(true)
                  callScannerAPI()
                }
              }>
              <path d="M1 4V10H7" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M23 20V14H17" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.49 8.99959C19.9828 7.56637 19.1209 6.28499 17.9845 5.27501C16.8482 4.26502 15.4745 3.55935 13.9917 3.22385C12.5089 2.88834 10.9652 2.93393 9.50481 3.35636C8.04437 3.77879 6.71475 4.5643 5.64 5.63959L1 9.99959M23 13.9996L18.36 18.3596C17.2853 19.4349 15.9556 20.2204 14.4952 20.6428C13.0348 21.0652 11.4911 21.1108 10.0083 20.7753C8.52547 20.4398 7.1518 19.7342 6.01547 18.7242C4.87913 17.7142 4.01717 16.4328 3.51 14.9996" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        
        <div className='leaveDayViewTableView'>
          <div className='leaveTableViewHead'>
            <div className='sr-scanner'>sr</div>
            <div className='empId-scanner'>empId</div>
            <div className='nameMyRow-scanner'>Name</div>
            <div className='InTime-scanner'>In Time</div>
            <div className='outTime-scanner'>Out Time</div>
            <div className='status-scanner'>Status</div>
          </div>
          <div className='leaveDayViewTbody'>
            <div className='leaveDayViewTbodyScroller'>
              {scannerAttendenceList && scannerAttendenceList?.length > 0 && scannerAttendenceList?.map((attObj, index) => (
                <div className='myRow'>
                  <div className='leaveTableViewHeadTbody' title={attObj?.leave?.leaveReason} style={{background: attObj?.leave !== null  ? attObj?.leave?.leaveFormat === 'FULLDAY' ? 'rgb(255, 223, 223)' : 'rgba(98, 63, 173, 0.2)' : 'none'}}>
                    <div className='sr-scanner'>{index + 1}</div>
                    <div className='empId-scanner'>
                      {attObj.userId}
                    </div>

                    <div className='nameMyRow-scanner'>
                      <div className='holdAttNamehold'>
                        <div className='taskOwnerImage'><img id="taskOwnerImage" src={attObj.empImg !== null ? attObj.empImg : profile} alt='' /></div>
                        <div>{attObj.empFullName}</div>
                      </div>
                    </div>
                    <div className='InTime-scanner' onClick={  () => {
                      if(selfInfo.role[0].roleId === 1){
                        setInTimeIndex(index)
                        setIsInTimeFlag(true)
                      }else if(selfInfo.role[0].roleId === 2){
                        handleInTimeManual(attObj)
                        setRemountFlag(!remountFlag)
                      }
                    }}>
                    
                    {  selfInfo.role[0].roleId === 1 &&    
                        <div className={isInTimeFlag && inTimeIndex === index ? 'displaiForHIDe' : 'dispalyForOpn'}>
                          {attObj?.inTime !== null ? convertTimeFormat(attObj?.inTime) : '-'}
                        </div>
                      }
                      
                    {
                      selfInfo.role[0].roleId !== 1 && (
                        <div className='dispalyForOpn'>
                          {isScanLoad && isScanIndex === inTimeIndex 
                            ? <Loader />
                            : (attObj?.inTime !== null ? convertTimeFormat(attObj?.inTime) : '-')
                          }
                        </div>
                      )
                    }

                    {selfInfo.role[0].roleId === 1 && isInTimeFlag && inTimeIndex === index &&
                      <div className='isDateHolder'>
                        <Space wrap >
                          <TimePicker autoClose className='tempTimePicker' placeholder={attObj?.outTime !== null ? `${convertTimeFormat(attObj?.inTime)}` : 'In Time'} use24Hours minuteStep={1} secondStep={1} hourStep={1} format={'h:mm a'} onClick={() => setTempAttObj(attObj)} onChange={handleInTime} />
                        </Space>
                      </div>
                    }

                    </div>

                    <div className='outTime-scanner' onClick={() => {
                      if(selfInfo.role[0].roleId === 1){
                        setOutTimeIndex(index)
                        setIsOutTimeFlag(true)
                      }else if(selfInfo.role[0].roleId === 2){
                        handleOutTimeManual(attObj)
                        setRemountFlag(!remountFlag)
                      }
                    }}>

                    { selfInfo.role[0].roleId === 1 &&  
                      <div className={isInTimeFlag && outTimeIndex === index ? 'displaiForHIDe' : 'dispalyForOpn'}>
                        {attObj?.outTime !== null ? convertTimeFormat(attObj?.outTime) : '-'}
                      </div>
                      }


                    { selfInfo.role[0].roleId !== 1 &&  
                        <div className={isInTimeFlag && outTimeIndex === index ? 'displaiForHIDe' : 'dispalyForOpn'}>
                          {attObj?.outTime !== null ? convertTimeFormat(attObj?.outTime) : '-'}
                        </div>
                      }
                      {selfInfo.role[0].roleId === 1  && isOutTimeFlag && outTimeIndex === index &&
                        <div className='isDateHolder'>
                          <Space wrap>
                            <TimePicker className='tempTimePicker' placeholder={attObj?.outTime !== null ? `${convertTimeFormat(attObj?.outTime)}` : 'Out Time'} use24Hours minuteStep={1} secondStep={1} hourStep={1} format={'h:mm a'} onClick={() => setTempAttObj(attObj)} onChange={handleOutTime} />
                          </Space>
                        </div>
                      }
                    </div>
                    <div className='status-scanner' style={{ color: attObj.inTime === null && attObj.outTime === null ? 'red' : 'green' }}>{(attObj.inTime !== null || attObj.outTime !== null ? 'Present' : 'Absent')}</div>
                  </div>
                </div>
              ))}              
            </div>
            </div>
          </div>

          {
            loader && scannerAttendenceList?.length === 0 &&  <div class="loaderIdProjectsLoad">
                <div class="scannerlist-loader"></div>
            </div>
          }
      </div>
    </div>
  )
}

export default Scanner