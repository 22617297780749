
import React from "react";
import "./styleMailkey.css"
import step1 from '../../../assets/images/Step1.png'
import step2 from '../../../assets/images/Step2.png'
import step3 from '../../../assets/images/Step3.png'
import step4 from '../../../assets/images/Step4.png'
import step5 from '../../../assets/images/Step5.png'
import step6 from '../../../assets/images/Step6.png'
import step7 from '../../../assets/images/Step7.png'
import step8 from '../../../assets/images/Step8.png'
import step9 from '../../../assets/images/Step9.png'



import { setClosureProcedure, setEditForm, setEmpInfo, setIsClosureKey } from "../../../erpStore/actions";
import { useDispatch, useSelector } from "react-redux";

function MailKeyProcedure() {
    const dispatch = useDispatch();
    const selfInfo = useSelector((state) => state.data.selfData)
    const editFrom = () => {
        dispatch(setClosureProcedure(false))
        dispatch(setEditForm(true))
        dispatch(setEmpInfo(selfInfo))
        dispatch(setIsClosureKey(true))
    }
    return (
        <div className="mailKeyProcedure-container" onClick={() => dispatch(setClosureProcedure(false))}>
            <div className="procedure-scroll-container" onClick={(e) => e.stopPropagation()}>
                <div className="mailKeyprocedure">
                    <div className="mailkeyprocedure-heading">Follow the procedure to Generate mail key</div>
                    <div className="mailkey-steps">
                        <div className="step">Step 1: </div>
                        <div className="screenshot-holder">
                            <img style={{width:'50%', height: '700px'}} src={step1} alt="/" />
                        </div>
                        <div className="step">Step 2: </div>
                        <div className="screenshot-holder">
                            <img style={{width:'50%', height: '700px'}} src={step2} alt="/" />
                        </div>
                        <div className="step">Step 3: </div>
                        <div className="screenshot-holder">
                            <img src={step3} alt="/" />
                        </div>

                        <div className="step">Step 4: </div>
                        <div className="screenshot-holder">
                            <img src={step4} alt="/" />
                        </div>

                        <div className="step">Step 5: </div>
                        <a className="steplink" href="" target="_blank" rel="noreferrer"> Open Link</a>
                        <div className="screenshot-holder">
                            <img src={step5} alt="/" />
                        </div>

                        <div className="step">Step 6: </div>
                        <div className="screenshot-holder">
                            <img src={step6} alt="/" />
                        </div>

                        <div className="step">Step 7: </div>
                        <div className="screenshot-holder">
                            <img src={step7} alt="/" />
                        </div>

                        <div className="step">Step 8: </div>
                        <div className="screenshot-holder">
                            <img src={step8} alt="/" />
                        </div>

                        <div className="step">Step 9: </div>
                        <div className="screenshot-holder">
                            <img src={step9} alt="/" />
                        </div>
                    </div>
                </div>
                <div className="proceed-btn-container">
                    <div className="proceed" onClick={editFrom}>Proceed</div>
                </div>
            </div>
        </div>
    )
}

export default MailKeyProcedure
