import React, { useEffect } from 'react';
import "./stylePageNotFound.css";
function PageNotFoundDay() {
  return (
    <div className='page-not-found daypnp'>
      <div className='page-not-found-container'>
        <div className='pnp-containt-container dayContainer'>
          <h1 className='dayHeading'>Page Not Found</h1>
          <button onClick={() => window.history.back()}>Go Back</button>
        </div>
      </div>
    </div>
  )
}

export default PageNotFoundDay
