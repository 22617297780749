import React, { useEffect, useState } from 'react'
import './styleLevel.css'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import { setEditeKey, setEmpInfo, setEmpView, setHeader, setRoute, setView, setNewTaskDefaultTag, setLevelSearch, setTaskEmp, setDispatchAllTask, setLevelAddTaskFlag, setIsHolidayStatus } from '../../../erpStore/actions';
import { setLoader as dayLoader } from '../../../erpStore/actions';
import profile from "../../../assets/images/profile.png";
import CalenderView from '../CalenderView/CalenderView';
import { toast } from 'react-toastify';

function Level(props) {
  const apiUrl = useSelector((state) => state.data.apiUrl)
  const selfData = useSelector((state) => state.data.selfData)
  const dispatch = useDispatch()
  const empView = useSelector((state) => state.data.empView);
  const stringHeaders = window.localStorage.getItem('myHeaders');
  const storedHeaders = JSON.parse(stringHeaders);
  const fullDateF = useSelector((state) => state.data.fullDate)
  const searchedList = useSelector((state) => state.data.levelSearch)
  const fdSplit = fullDateF.toString().split(", ")
  const levelInfoObject = useSelector((state) => state.data.levelInfoObject)
  const [empList, setEmpList] = useState();
  const view = useSelector((state) => state.data.view)
  const statusList = useSelector((state) => state.data.statusList)
  const employeeList = useSelector((state) => state.data.levelSearch)
  const isHolidayStatus = useSelector((state) => state.data.isHolidayStatus)
  const dummyObject = [{ id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }];
  const isLevelAddTaskFlag = useSelector((state) => state.data.levelAddTaskFlag)
  const getMonthNumber = (monthName) => {
    const monthMoment = moment().month(monthName).startOf('month');
    return monthMoment.format('MM');
  };
  const [loader, setLoader] = useState();
  useEffect(() => {
    if (view === 'level' || view === 'month' || isLevelAddTaskFlag === true) {
      setLoader(true)
      window.sessionStorage.setItem("view", "level")
      window.sessionStorage.setItem("header", window.sessionStorage.getItem("levelVal"))
      try {
        const dateSplit = fdSplit[1].split(" ");
        const date = `${dateSplit[2]}-${getMonthNumber(dateSplit[1])}-${dateSplit[0].length > 1 ? dateSplit[0] : "0" + dateSplit[0]}`
        axios.get(`${apiUrl}employee/get/assigned/task/info/${window.sessionStorage.getItem("levelVal") ? window.sessionStorage.getItem("levelVal") : levelInfoObject}?date=${date}`, {
          headers: storedHeaders
        })
          .then((response) => {
            dispatch(setLevelSearch(response.data.data))
            setEmpList(response.data.data)
            dispatch(setLevelAddTaskFlag(false))
            setLoader(false)
            dispatch(setIsHolidayStatus(response.data.holiday))
          })
          .catch((err) => {
            setLoader(false)
          })
          dispatch(setLevelAddTaskFlag(false))
      } catch {
        setLoader(false)
      }
    }
  }, [levelInfoObject, fdSplit[1], isLevelAddTaskFlag, view])
  

  useEffect(() => {

    const handleWheelScroll = (event) => {
      // Adjust scrollX based on mouse wheel delta
      const container = document.getElementById('analytics-info');
      if (container) {
        // Smoothly scroll horizontally
        container.scrollTo({
          left: container.scrollLeft + event.deltaY,
          behavior: 'smooth'
        });

        // Prevent the default scroll behavior
        event.preventDefault();
      }
    };

    const container = document.getElementById('analytics-info');
    if (container) {
      container.addEventListener('wheel', handleWheelScroll);
    }

    return () => {
      // Clean up event listener when component unmounts
      if (container) {
        container.removeEventListener('wheel', handleWheelScroll);
      }
    };
  }, [employeeList]);



  const timeCalculator = (lastUpdated) => {
    const parsedDate = new Date(lastUpdated);
    const formattedDate = `${parsedDate.getUTCFullYear()}-${(parsedDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getUTCDate().toString().padStart(2, '0')}T${parsedDate.getUTCHours().toString().padStart(2, '0')}:${parsedDate.getUTCMinutes().toString().padStart(2, '0')}:${parsedDate.getUTCSeconds().toString().padStart(2, '0')}Z`;
    const currentDateTime = new Date();
    const lastUpdatedDateTime = new Date(formattedDate);
    const timeDifference = (currentDateTime - lastUpdatedDateTime) / 1000;

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800;
    const secondsInYear = 31557600;

    if (timeDifference >= secondsInYear) {
      const yearsAgo = Math.floor(timeDifference / secondsInYear);
      return `${yearsAgo} ${yearsAgo === 1 ? 'yr' : 'yrs'} ago`;
    } else if (timeDifference >= secondsInMonth) {
      const monthsAgo = Math.floor(timeDifference / secondsInMonth);
      return `${monthsAgo} ${monthsAgo === 1 ? 'mon' : 'mons'} ago`;
    } else if (timeDifference >= secondsInWeek) {
      const weeksAgo = Math.floor(timeDifference / secondsInWeek);
      return `${weeksAgo} ${weeksAgo === 1 ? 'wk' : 'wks'} ago`;
    } else if (timeDifference >= secondsInDay) {
      const daysAgo = Math.floor(timeDifference / secondsInDay);
      return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
    } else if (timeDifference >= secondsInHour) {
      const hoursAgo = Math.floor(timeDifference / secondsInHour);
      return `${hoursAgo} ${hoursAgo === 1 ? 'hr' : 'hrs'} ago`;
    } else {
      const minutesAgo = Math.floor(timeDifference / secondsInMinute);
      return `${minutesAgo} ${minutesAgo === 1 ? 'min' : 'mins'} ago`;
    }
  };
  
  const routeDetails = (obj) => {
    dispatch(setEmpInfo(obj))
    dispatch(setView("day"))
    window.sessionStorage.setItem("view", 'day')
    dispatch(setEmpView(true))
    dispatch(setHeader(obj.empFullName))
    window.sessionStorage.setItem('header', obj.empFullName)
    dispatch(setDispatchAllTask([]))
    dispatch(dayLoader(true))
  }
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleScroll = (e) => {
    const scrollValue = e.target.scrollLeft;
    setScrollLeft(scrollValue);
  };

  const timeVisible = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    const time = `${hours}:${minutes}:${seconds} ${period}`;
    
    return time
};

// Example usage:
timeVisible('2024-06-27 11:57:25'); // Outputs: 11:57:25 AM
timeVisible('2024-06-27 23:30:00'); // Outputs: 11:30:00 PM

  return (
    <div className='employeelist-holder'>
      <div className='information_Container'>
        {empView ? <CalenderView /> :
          (employeeList && employeeList.length > 0 ?
            <div className="information_table-container">
              <table>
                <thead className="header">
                  <tr className='headingRow'>
                    <th className='rowSr header-cell'>Sr.</th>
                    <th className="name header-cell">Name</th>
                    <th className="assigned header-cell">Assigned</th>
                    {/* <th>Not Started</th>
                  <th>In Progress</th>
                  <th>Submitted</th>
                  <th>Completed</th>
                  <th>Due</th> */}
                    <th className="analytics-cell header-cell">Analytics</th>
                    <th className="header-cell" style={{ width: "20px" }} > Last Changed / Status</th>
                  </tr>
                </thead>{
                  loader ? <tbody className="body">
                    {dummyObject.map((obj, index) =>
                      <tr className="row">
                        <td className='srColumn cell'><div className='level-loader'></div></td>
                        <td className='Level_employee_profile cell'>
                          <div className='Level_employee_profile'>
                            <div className='img-holder'></div>
                            <div className='level_employee_designation'><p><div className='level-loader'></div></p><div className='level-loader designation-loader'></div></div>
                          </div>

                        </td>
                        <td className='assignedColumn cell'><div className='level-loader count-loader'></div></td>
                        <td className='analyticsColumn cell'>
                          <div className='analytics' style={{ background: "rgb(225 225 225 / 45%)" }}>
                            <div className='level-loader'></div>
                          </div>
                        </td>
                        <td className='updateColumn'><div className='level-loader count-loader'></div></td>
                      </tr>

                    )}
                  </tbody> : <tbody className="body" onScroll={handleScroll}>
                    {employeeList && employeeList.length > 0 ? employeeList.map((obj, index) =>
                      <tr className="row" onClick={() => {
                        routeDetails(obj)
                        dispatch(setRoute(null))
                        dispatch(setEditeKey(false))
                        dispatch(setNewTaskDefaultTag(false))
                        dispatch(setTaskEmp(obj))
                        // handleViewEmp(obj)
                      }} key={index} style={{ background: selfData.empId === obj.empId ? "#FFF6DF" : null }}>
                        <td className='srColumn cell'>{index + 1}</td>
                        <td className='Level_employee_profile cell'>
                          <div className='Level_employee_profile'>
                            <div className='img-holder'><img src={obj.empImg ? obj.empImg : profile} className='userProfileImage' alt='' /></div>
                            <div className='level_employee_designation'><p>{obj.empFullName}</p>{obj.department.departmentName} {obj.employeeDesignation}</div>
                          </div>
                        </td>
                        <td className='assignedColumn cell'>{obj.totalTask}</td>
                        <td className='analyticsColumn cell'>
                          <div className='analytics' style={{ background: "rgb(225 225 225 / 45%)" }}>
                            {obj.notDue.map((analytics) => (
                              <div
                                className='count completed'
                                title={`(${analytics.count}) ${analytics.status}`}
                                style={{
                                  width: analytics.count > 0 ? `${((analytics.count / obj.totalTask) * 100)}%` : "0%",
                                  background: analytics.colorCode
                                }}
                              >
                                <div className='count-analytics'>{analytics.count > 0 ? analytics.count : ""}</div>
                              </div>
                            ))}

                            {/* {obj.completedTasks > 0 ? <div className='count completed' title={`(${obj.completedTasks}) Completed`} style={{ width: obj.completedTasks > 0 ? `${((obj.completedTasks / obj.totalTasks) * 100)}%` : "0%", background: "rgba(63, 167, 72, 1)" }}><div className='count-analytics'>{obj.completedTasks > 0 ? obj.completedTasks : ""}</div></div> : null}
                            {obj.submittedTasks > 0 ? <div className='count submitted' title={`(${obj.submittedTasks}) Submitted`} style={{ width: obj.submittedTasks > 0 ? `${((obj.submittedTasks / obj.totalTasks) * 100)}%` : "0%", background: "rgba(145, 177, 59, 1)" }}><div className='count-analytics'>{obj.submittedTasks > 0 ? obj.submittedTasks : ""}</div></div> : null}
                            {obj.inProgressTasks > 0 ? <div className='count in-progress' title={`(${obj.inProgressTasks}) In Progress`} style={{ width: obj.inProgressTasks > 0 ? `${((obj.inProgressTasks / obj.totalTasks) * 100)}%` : "0%", background: "rgba(238, 214, 1, 1)" }}><div className='count-analytics'>{obj.inProgressTasks > 0 ? obj.inProgressTasks : ""}</div></div> : null}
                            {obj.inDiscussionTasks > 0 ? <div className='count in-discussion' title={`(${obj.inDiscussionTasks}) In Discussion`} style={{ width: obj.inDiscussionTasks > 0 ? `${((obj.inDiscussionTasks / obj.totalTasks) * 100)}%` : "0%", background: "rgba(160, 210, 233, 1)" }}><div className='count-analytics'>{obj.inDiscussionTasks > 0 ? obj.inDiscussionTasks : ""}</div></div> : null}
                            {obj.notStartedTasks > 0 ? <div className='count no-started' title={`(${obj.notStartedTasks}) Not Started`} style={{ width: obj.notStartedTasks > 0 ? `${((obj.notStartedTasks / obj.totalTasks) * 100)}%` : "0%", background: "rgba(142, 143, 147, 1)" }}><div className='count-analytics'>{obj.notStartedTasks > 0 ? obj.notStartedTasks : ""}</div></div> : null} */}
                            {
                              obj.totalDue > 0 ? (
                                <div
                                  className='count Due'
                                  title={`(${obj.totalDue}) Total Due ${obj.due.map((dueTask) => String("(" + dueTask.count + ") " + dueTask.status))}`}
                                  style={{
                                    width: obj.totalDue > 0 ? `${((obj.totalDue / obj.totalTask) * 100)}%` : "0%",
                                    background: "rgb(255, 102, 102)"
                                  }}
                                >
                                  <div className='count-analytics'>{obj.totalDue > 0 ? obj.totalDue : ""}</div>
                                </div>
                              ) : null
                            }


                          </div>
                          {/* (${obj.totalDue}) Total Due  (${obj.dueTasks.dueCompleted})Completed   (${obj.dueTasks.dueSubmitted})Submitted  (${obj.dueTasks.dueInProgress})In Progress  (${obj.dueTasks.dueNotStarted})Not Started   (${obj.dueTasks.dueInDiscussion})In Discussion` */}
                        </td>
                        <td className='updateColumn'>
                          { obj?.leave?.leaveFormat !== 'FULLDAY' ? 
                            <div className='lastChangedLevel-active' >
                              <div className='lstchangetext' style={{width: '100px'}} title={obj?.LastUpdated !== null ? timeVisible(obj.LastUpdated) : 'not updated yet'}>{obj.LastUpdated ? `${timeCalculator(obj.LastUpdated)}` : "-"}</div>
                              { obj.isPresent !== null && isHolidayStatus === null  ? ( 
                                 obj.isPresent  !== false) ?  
                                <div className='active-deactive' style={{background : obj?.leave === null ? 'rgba(1, 159, 55, 0.15)' : 'rgba(98, 63, 173, 0.15)'}} title={obj?.leave !== null ? obj?.leave.leaveReason : 'Present'}>
                                  <div className='active-deactive-disc' style={{background : obj?.leave === null ? 'rgba(1, 159, 55, 1)' : 'rgba(98, 63, 173, 1)'}}></div>
                                  <div className='active-deactivestatus' style={{color : obj?.leave === null ? 'rgba(1, 159, 55, 1)' : 'rgba(98, 63, 173, 1)', width: 'max-content'}}>{obj?.leave === null ? 'Active' : 'half day leave'}</div>
                                </div>
                                :
                                <div className='lstchangetextAbsent'>
                                  <div className='active-deactive-disc' style={{background : 'rgba(231, 0, 0, 1)' }}></div>
                                  <div className='active-deactivestatus' style={{color : 'rgba(231, 0, 0, 1)'}}>Absent</div>
                                </div>
                               : (
                                <div className='lstchangetext'>
                                  <div className='active-deactivestatus' >-</div>
                                </div>
                              )}
                            </div>

                            :

                            <div className='AbsentFormatLevel'>
                              <div className='absentDivHolder' style={{background : 'rgba(252, 220, 220, 1)'}}title={obj?.leave.leaveReason}>
                                <div className='active-deactive-disc' style={{background: 'rgba(231, 0, 0, 1)'}}></div>
                                <div className='active-deactivestatus'>{'full day leave'}</div>
                              </div>
                            </div>

                          }
                        </td>
                      </tr>

                    ) : null}

                  </tbody>
                }
              </table>
            </div> : loader !== true &&  <div className='no-employee'>
              <div className='img-container' />
              <p>Not {props.info_Object} found!</p>
            </div>
          )
        }
      </div>

      {/*Color explanation to understand the status*/}
      {
        view === 'level' ?
          <div className='analytics-info' id='analytics-info'>
            {
              statusList && statusList.map((status) => <div className='analytics-detail'>
                <div style={{ height: "13px", width: "31px", background: status.statusColorCode, borderRadius: "8px" }} /> &nbsp;<div className='statusName'>{status.status}</div>
              </div>)
            }

            {/* <div className='analytics-detail'>
              <div style={{ height: "13px", width: "31px", background: "rgba(145, 177, 59, 1)", borderRadius: "8px" }} /> &nbsp;Submitted
            </div>
            <div className='analytics-detail'>
              <div style={{ height: "13px", width: "31px", background: "rgba(238, 214, 1, 1)", borderRadius: "8px" }} /> &nbsp;In Progress
            </div>
            <div className='analytics-detail'>
              <div style={{ height: "13px", width: "31px", background: "rgba(160, 210, 233, 1)", borderRadius: "8px" }} /> &nbsp;In Discussion
            </div>
            <div className='analytics-detail'>
              <div style={{ height: "13px", width: "31px", background: "rgba(142, 143, 147, 1)", borderRadius: "8px" }} /> &nbsp;Not Started
            </div> */}
            <div className='analytics-detail'>
              <div style={{ height: "13px", width: "31px", background: "rgba(255, 102, 102, 1)", borderRadius: "8px", marginRight: '10px' }} /> &nbsp;due
            </div>
          </div> : null
      }

    </div >
  );

}

export default Level
