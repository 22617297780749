import React, { useEffect, useState } from 'react';
import "./styleAnalyticsHome.css"
import { useDispatch, useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import profile from "../../../assets/images/profile.png";
import { setAnalyticsData, setAnalyticsEmp, setAnalyticsEmpList, setAnalyticsEndDate, setAnalyticsStartDate, setEmpJoiningDate, setHeader, setIsDetailAnalytics, setLoader } from '../../../erpStore/actions';
import Analytics from './Analytics';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { debounce } from 'throttle-debounce';


function AnalyticsHome() {
    const analyticsData = useSelector((state) => state.data.analyticsData);
    const analyticsEmpList = useSelector((state) => state.data.analyticsEmpList);
    const dispatch = useDispatch();
    const selfInfo = useSelector((state) => state.data.selfData)
    const isDetailAnalytics = useSelector((state) => state.data.isDetailAnalytics)
    const apiUrl = useSelector((state) => state.data.apiUrl)
    const navigate = useNavigate();
    const startDate = useSelector((state) => state.data.analyticsStartDate)
    const endDate = useSelector((state) => state.data.analyticsEndDate)
    const analyticsType = useSelector((state) => state.data.analyticsType)
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        dispatch(setEmpJoiningDate(selfInfo.empJoiningDate))
    }, [])
    const [radial, setRadial] = useState({
        series: [analyticsData ? parseInt(((analyticsData.summary.completedOnTime + analyticsData.summary.completedOverDue) / analyticsData.summary.totalTask) * 100) : 0], // Series data
        options: {
            chart: {
                type: 'radialBar',
                background: '#ffffff', // Set the background color here
                height: 50, // Set the height dynamically
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '40%',
                        color: "#ffffff"
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: -10,
                            show: false,
                            color: '#888',
                            fontSize: '12px',
                        },
                        value: {
                            color: '#111',
                            fontSize: '12px',
                            offsetY: 5,
                            show: true,
                            formatter: function (val) {
                                return val + '%';
                            },
                        },
                    },
                    // track: {
                    //     background: "#ffffff",
                    //     strokeWidth: '97%',
                    //     margin: 5, // margin is in pixels
                    // },

                },

            }, fill: {
                opacity: 1.5,
                colors: ['rgba(255, 132, 2, 1)'],
            },

            labels: ['Progress'], // Labels for each data point
        },
    });
    const analyticsEmp = useSelector((state) => state.data.analyticsEmp)
    useEffect(() => {
        dispatch(setAnalyticsEmpList([]))
        const fetchData = async () => {
            if (isDetailAnalytics && selfInfo?.length !== 0) {
                const stringHeaders = window.localStorage.getItem('myHeaders');
                const storedHeaders = JSON.parse(stringHeaders);
                const date = new Date();
                const year = date.getFullYear();
                const firstDateFormatted = `${year}-01-01`;
                const lastDateFormatted = `${year}-12-31`;
                dispatch(setAnalyticsStartDate(`${year}-01-01`))
                dispatch(setAnalyticsEndDate(`${year}-12-31`))
                try {
                    setLoader(true)
                    const resp = await axios.post(
                        `${apiUrl}emp/analytics/get-single`,
                        {
                            userId: analyticsEmp ? analyticsEmp : selfInfo.userId,
                            startDate: firstDateFormatted,
                            endDate: lastDateFormatted,
                            type: analyticsType
                        },
                        {
                            headers: storedHeaders
                        }
                    );

                    if (resp && resp.data) {
                        dispatch(setAnalyticsData(resp.data));
                    }
                } catch (err) {
                    if (err.response && err.response.status === 500) {
                        navigate("/server-error");
                    }
                }
                try {
                    dispatch(setAnalyticsEmpList([]))
                    const resp = await axios.post(`${apiUrl}emp/analytics/get-assignee-list`, {
                        roleLevel: selfInfo && selfInfo.role?.length > 0 ? selfInfo?.role[0]?.roleLevel : 0,
                        startDate: firstDateFormatted,
                        endDate: lastDateFormatted,
                        key: ""
                    }, {
                        headers: storedHeaders
                    })
                    if (resp && resp.data) {
                        setLoader(false)
                        dispatch(setAnalyticsEmpList(resp.data))
                    }
                } catch (err) {
                    if (err.response.status === 500) {
                        navigate("/server-error")
                    }
                }
            }
        };

        fetchData();
    }, [isDetailAnalytics, selfInfo]);
    useEffect(() => {
        setRadial(prevRadial => ({
            ...prevRadial,
            series: [analyticsData && analyticsData.summary.totalTask > 0 ? parseInt(((analyticsData.summary.completedOnTime + analyticsData.summary.completedOverDue) / analyticsData.summary.totalTask) * 100) : 0]
        }));
    }, [analyticsData]);

    const handleSearchEmployee = async (e) => {
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        try {
            dispatch(setAnalyticsEmpList([]))
            const resp = await axios.post(`${apiUrl}emp/analytics/get-assignee-list`, {
                roleLevel: selfInfo && selfInfo.role?.length > 0 ? selfInfo?.role[0]?.roleLevel : 0,
                startDate: startDate,
                endDate: endDate,
                key: e
            }, {
                headers: storedHeaders
            })
            if (resp && resp.data) {
                dispatch(setAnalyticsEmpList(resp.data))
                setLoader(false)
            }
        } catch (err) {
            if (err.response.status === 500) {
                navigate("/server-error")
                setLoader(false)

            }
        }
    }
    const debouncedSearchEmp = debounce(1000, handleSearchEmployee);

    const progressColor = (emp) => {
        const progressRatio = parseInt((emp.completedTask / emp.totalTask) * 100)
        if (progressRatio <= 25) {
            return 'rgba(225, 52, 11, 1)'
        } else if (progressRatio <= 50) {
            return 'rgba(255, 208, 38, 1)'
        } else if (progressRatio <= 75) {
            return 'rgba(49, 119, 237, 1)'
        } else {
            return "rgba(42, 164, 119, 1)"
        }
    }
    return (
        <div className='analyticshome'>{isDetailAnalytics ?
            <div className='analyticshome-container'>
                <div className='self-analytics'>
                    <div className='analytics-card my-analytics-card' onClick={ analyticsData !== null ? () => { 
                        dispatch(setAnalyticsEmp(selfInfo.userId))
                        dispatch(setIsDetailAnalytics(false))
                        dispatch(setEmpJoiningDate(selfInfo.empJoiningDate))
                        dispatch(setHeader(selfInfo.empFullName))
                    }: null}>
                        <div className='analytics-title-head-container'>
                            <div className='my-analytics-heading'>My Performance</div>
                            <div className='my-analytics-count'>{analyticsData ? analyticsData.summary.completedOnTime + analyticsData.summary.completedOverDue : 0}<span>/{analyticsData ? analyticsData.summary.totalTask : 0}</span></div>
                        </div>
                        <div className='analytics-count-donught-container'>
                            <div className='my-analytics-donught'><ReactApexChart options={radial.options} series={radial.series} type="radialBar" /></div>
                        </div>
                    </div>

                    <div className='analytics-card'>
                        <div className='analytics-title'>Completed Tasks<br />
                            (On Time)</div>
                        <div className='analytics-count'>{analyticsData ? analyticsData.summary.completedOnTime : 0}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>Completed Tasks<br />
                            (Overdue)</div>
                        <div className='analytics-count'>{analyticsData ? analyticsData.summary.completedOverDue : 0}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>Pending Tasks</div>
                        <div className='analytics-count'>{analyticsData ? analyticsData.summary.pendingTask : 0}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>Total Tasks</div>
                        <div className='analytics-count'>{analyticsData ? (analyticsData.summary.totalTask + analyticsData.summary.totalDeleted + analyticsData.summary.upcomingTask) : 0}</div>
                    </div>
                </div>

                <div className='analytics-emp-list'>
                    {/* <div className='analytics-emp-list-header'>
                        Employees Performance
                    </div> */}
                    <div className='analytics-emp-list-container'>
                        <div className='analytics-emp-list-guidance'>
                            <div className='analytics-emp-list-status'>
                                <div className='analytics-emp-list-status-container'>
                                    <div className='analytics-status-color' style={{ background: "rgba(42, 164, 119, 1)" }}></div>
                                    <div className='analytical-status'>Perfect score</div>
                                </div>
                                <div className='analytics-emp-list-status-container'>
                                    <div className='analytics-status-color' style={{ background: "rgba(49, 119, 237, 1)" }}></div>
                                    <div className='analytical-status'>Good score</div>
                                </div>
                                <div className='analytics-emp-list-status-container'>
                                    <div className='analytics-status-color' style={{ background: "rgba(255, 208, 38, 1)" }}></div>
                                    <div className='analytical-status'>Average score</div>
                                </div>
                                <div className='analytics-emp-list-status-container'>
                                    <div className='analytics-status-color' style={{ background: "rgba(225, 52, 11, 1)" }}></div>
                                    <div className='analytical-status'>Bad score</div>
                                </div>
                            </div>
                            <div className='analytics-emp-list-search'>
                                <div className='search'>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.77005 0.341571C3.31446 0.116066 3.89796 0 4.48724 0C5.07651 0 5.66001 0.116066 6.20443 0.341571C6.74885 0.567075 7.24351 0.897603 7.66019 1.31428C8.07687 1.73096 8.4074 2.22563 8.63291 2.77005C8.85841 3.31446 8.97447 3.89797 8.97447 4.48724C8.97447 5.07651 8.85841 5.66001 8.6329 6.20443C8.45661 6.63004 8.21613 7.02525 7.92048 7.37656L9.88735 9.34343C10.0375 9.49363 10.0375 9.73715 9.88735 9.88735C9.73715 10.0375 9.49363 10.0375 9.34343 9.88735L7.37656 7.92049C6.5709 8.59857 5.54845 8.97447 4.48724 8.97447C3.29715 8.97447 2.1558 8.50171 1.31428 7.66019C0.472761 6.81867 0 5.67733 0 4.48724C0 3.29715 0.472761 2.1558 1.31428 1.31428C1.73096 0.897603 2.22563 0.567075 2.77005 0.341571ZM4.48724 0.769223C3.99898 0.769223 3.5155 0.865392 3.06441 1.05224C2.61332 1.23909 2.20345 1.51295 1.8582 1.8582C1.16094 2.55547 0.769223 3.50116 0.769223 4.48724C0.769223 5.47332 1.16094 6.41901 1.8582 7.11627C2.55547 7.81353 3.50116 8.20525 4.48724 8.20525C5.47332 8.20525 6.41901 7.81353 7.11627 7.11627C7.46152 6.77102 7.73539 6.36115 7.92224 5.91006C8.10908 5.45897 8.20525 4.97549 8.20525 4.48724C8.20525 3.99898 8.10908 3.5155 7.92224 3.06441C7.73539 2.61332 7.46152 2.20345 7.11627 1.8582C6.77102 1.51295 6.36115 1.23909 5.91006 1.05224C5.45897 0.865392 4.97549 0.769223 4.48724 0.769223Z" fill="#263238" />
                                    </svg>
                                    <input type='search' onChange={(e) => debouncedSearchEmp(e.target.value)} placeholder='Search employee name' alt='/' />
                                </div>
                            </div>
                        </div>
                        <div className='analytics-emp-list-table-container'>
                            <div className='analytics-emp-list-table'>
                                <div className="information_table-container">
                                    <table>
                                        <thead className="header">
                                            <tr className='headingRow'>
                                                <th className='rowSr header-cell'>Sr.</th>
                                                <th className="name header-cell">Name</th>
                                                <th className="assigned header-cell">Department</th>
                                                <th className="analytics-cell header-cell">Completion Rate</th>
                                                <th className="header-cell" >%</th>
                                            </tr>
                                        </thead> <tbody className="body">
                                            {
                                                analyticsEmpList && analyticsEmpList.map((emp, index) =>
                                                    <tr className="row" onClick={selfInfo && (selfInfo.role[0].role === "ROLE_SUPERADMIN" ||
                                                        selfInfo.role[0].role === "ROLE_ADMIN" || emp.roleLevel !== selfInfo.role[0].roleLevel) ? () => {
                                                            dispatch(setAnalyticsEmp(emp.userId))
                                                            dispatch(setIsDetailAnalytics(false))
                                                            dispatch(setHeader(emp.empFullName))
                                                            dispatch(setEmpJoiningDate(emp.empJoiningDate))
                                                        } : null}>
                                                        <td className='srColumn cell'>{index + 1}</td>
                                                        <td className='Level_employee_profile cell'>
                                                            <div className='Level_employee_profile'>
                                                                <div className='img-holder'>
                                                                    <img src={emp.empImg ? emp.empImg : profile} alt='/' />
                                                                </div>
                                                                <div className='level_employee_designation'><p>{emp.empFullName}</p>{emp.designation}</div>
                                                            </div>

                                                        </td>
                                                        <td className='assignedColumn cell'>{emp.department}</td>
                                                        <td className='analyticsColumn cell'>
                                                            {emp.completedTask}<span>/{emp.totalTask}</span>
                                                        </td>
                                                        <td className='updateColumn'><div className='progressRatio'><div className='progressColor' style={{ background: emp.totalTask > 0 ? progressColor(emp) : 'rgba(225, 52, 11, 1)' }}></div> {emp.totalTask > 0 ? parseInt((emp.completedTask / emp.totalTask) * 100) + "%" : "0%"}</div></td>
                                                    </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {
                                 loader && <div class="loaderIdProjectsLoad">
                                    <div class="loader-analytics"></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div> : <Analytics />
        }

        </div>
    )
}

export default AnalyticsHome
