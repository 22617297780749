import React, { useEffect, useRef, useState } from 'react';
import "./styleProjectInfo.css";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAddPhase, setMilestoneObj, setRoute } from '../../../../erpStore/actions';
import no_milestone from "../../../../assets/images/no_project.png"



function ProjectInfo() {
    const apiUrl = useSelector((state) => state.data.apiUrl)
    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);
    const dispatch = useDispatch();
    const [milestones, setMilestones] = useState([]);
    const project = useSelector((state) => state.data.project);
    const [isPhaseList, setIsPhaseList] = useState(false);
    const [phaseList, setPhaseList] = useState([]);
    const mileRef = useRef(null);
    const [milesShow, setMilesShow] = useState(false);
    const viewAddPhase = useSelector((state) => state.data.addPhase)
    const [routeS, setRouteS] = useState();


    useEffect(() => {
        axios.post(`${apiUrl}project/milestone/get/all`, {
            projectId: project.projectId
        }, {
            headers: storedHeaders
        })
            .then((response) => {
                setMilestones(response.data)
            })
            .catch((error) => {
            })
    }, [viewAddPhase])

    useEffect(() => {
        function handleOutsideClick(event) {
            if (mileRef && mileRef.current && !mileRef.current.contains(event.target)) {
                setMilesShow(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])

    const handleMilesShow = () => {
        setMilesShow(!milesShow)
    }
    const handlePhaseList = async (e) => {
        setIsPhaseList(!isPhaseList);
        try {
            const resp = await axios.post(`${apiUrl}project/phase/get/all`, {
                milestoneId: e.milestoneId
            }, {
                headers: storedHeaders
            })
            if (resp && resp.data) {
                setPhaseList(resp.data)
            } else {
            }
        }
        catch (error) {
        }
    }
    const calculateDays = (start, end) => {
        var startDate = new Date(start);
        var endDate = new Date(end);

        var difference = endDate - startDate;
        var daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));
        if (daysDifference < 0) {
            return "0";
        }
        return daysDifference
    }
    return (
        <div className='ProjectInfo'>
            {milestones.length === 0 ?
                <div className='no-milestone'>
                    <img src={no_milestone} alt='/' />
                    <div className='add-phase'>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.13333 8.5C1.13333 4.4315 4.4315 1.13333 8.5 1.13333C12.5685 1.13333 15.8667 4.4315 15.8667 8.5C15.8667 12.5685 12.5685 15.8667 8.5 15.8667C4.4315 15.8667 1.13333 12.5685 1.13333 8.5ZM8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0ZM8.5 4.63636C8.92676 4.63636 9.27273 4.88348 9.27273 5.18831V7.72727H11.8117C12.1165 7.72727 12.3636 8.07323 12.3636 8.5C12.3636 8.92676 12.1165 9.27273 11.8117 9.27273H9.27273V11.8117C9.27273 12.1165 8.92676 12.3636 8.5 12.3636C8.07323 12.3636 7.72727 12.1165 7.72727 11.8117V9.27273H5.18831C4.88348 9.27273 4.63636 8.92676 4.63636 8.5C4.63636 8.07323 4.88348 7.72727 5.18831 7.72727H7.72727V5.18831C7.72727 4.88348 8.07323 4.63636 8.5 4.63636Z" fill="#263238" fill-opacity="0.4" />
                        </svg>
                        <div className='add-phase-text' onClick={() => {
                            dispatch(setAddPhase("milestone"))
                        }}>Add New Milestone</div>
                    </div>
                </div> : <div className='milestone-wrapper'>
                    {/* <div className='ProjectInfo-container'>
                        <div className='milestone-container'>
                            <div className='pArrow'>
                                <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.27612 7.68831L12.709 1.76623C13.097 1.35065 13.097 0.727272 12.709 0.311687C12.3209 -0.103897 11.7388 -0.103897 11.3507 0.311687L6.5 5.4026L1.64925 0.311687C1.26119 -0.103897 0.679104 -0.103897 0.291045 0.311687C0.0970147 0.51948 0 0.727273 0 1.03896C0 1.35065 0.0970147 1.55844 0.291045 1.76623L5.72388 7.68831C6.20895 8.1039 6.79105 8.1039 7.27612 7.68831C7.1791 7.68831 7.1791 7.68831 7.27612 7.68831Z" fill="#263238" fill-opacity="0.4" />
                                </svg>
                            </div>
                            <div className='pText-Info'>
                                <div className='milestone-heading'>Milestone no. 1</div>
                                <div className='milestone-duration'>12 January 2024 - 12 February 2024</div>
                            </div>
                        </div>
                        <div className='pPhase-container'>
                            <div className='left-border-container' />
                            <div className='phase-container'>
                                <div className='pArrow' style={{ borderRight: "4px solid rgba(1, 159, 55, 1)" }}>
                                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.27612 7.68831L12.709 1.76623C13.097 1.35065 13.097 0.727272 12.709 0.311687C12.3209 -0.103897 11.7388 -0.103897 11.3507 0.311687L6.5 5.4026L1.64925 0.311687C1.26119 -0.103897 0.679104 -0.103897 0.291045 0.311687C0.0970147 0.51948 0 0.727273 0 1.03896C0 1.35065 0.0970147 1.55844 0.291045 1.76623L5.72388 7.68831C6.20895 8.1039 6.79105 8.1039 7.27612 7.68831C7.1791 7.68831 7.1791 7.68831 7.27612 7.68831Z" fill="#263238" fill-opacity="0.4" />
                                    </svg>
                                </div>
                                <div className='pText-Info'>
                                    <div className='phase-heading'>Phase no. 1</div>
                                    <div className='phase-duration'>12 January 2024 - 12 February 2024</div>
                                </div>
                            </div>
                        </div>
                        <div className='pPhase-container'>
                            <div className='left-border-container' />
                            <div className='phase-container'>
                                <div className='pArrow' style={{ borderRight: "4px solid rgba(1, 159, 55, 1)" }}>
                                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.27612 7.68831L12.709 1.76623C13.097 1.35065 13.097 0.727272 12.709 0.311687C12.3209 -0.103897 11.7388 -0.103897 11.3507 0.311687L6.5 5.4026L1.64925 0.311687C1.26119 -0.103897 0.679104 -0.103897 0.291045 0.311687C0.0970147 0.51948 0 0.727273 0 1.03896C0 1.35065 0.0970147 1.55844 0.291045 1.76623L5.72388 7.68831C6.20895 8.1039 6.79105 8.1039 7.27612 7.68831C7.1791 7.68831 7.1791 7.68831 7.27612 7.68831Z" fill="#263238" fill-opacity="0.4" />
                                    </svg>
                                </div>
                                <div className='pText-Info'>
                                    <div className='phase-heading'>Phase no. 2</div>
                                    <div className='phase-duration'>12 January 2024 - 12 February 2024</div>
                                </div>
                            </div>
                        </div>
                        <div className='add-phase'>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.13333 8.5C1.13333 4.4315 4.4315 1.13333 8.5 1.13333C12.5685 1.13333 15.8667 4.4315 15.8667 8.5C15.8667 12.5685 12.5685 15.8667 8.5 15.8667C4.4315 15.8667 1.13333 12.5685 1.13333 8.5ZM8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0ZM8.5 4.63636C8.92676 4.63636 9.27273 4.88348 9.27273 5.18831V7.72727H11.8117C12.1165 7.72727 12.3636 8.07323 12.3636 8.5C12.3636 8.92676 12.1165 9.27273 11.8117 9.27273H9.27273V11.8117C9.27273 12.1165 8.92676 12.3636 8.5 12.3636C8.07323 12.3636 7.72727 12.1165 7.72727 11.8117V9.27273H5.18831C4.88348 9.27273 4.63636 8.92676 4.63636 8.5C4.63636 8.07323 4.88348 7.72727 5.18831 7.72727H7.72727V5.18831C7.72727 4.88348 8.07323 4.63636 8.5 4.63636Z" fill="#263238" fill-opacity="0.4" />
                            </svg>
                            <div className='add-phase-text' onClick={() => {
                                dispatch(setAddPhase("phase"))
                            }}>Add New Phase</div>
                        </div>
                    </div> */}
                    {
                        milestones && milestones.length > 0 && milestones.map((milestone) => (
                            <div className='ProjectInfo-container'>
                                <div className='milestone-container'>
                                    <div className='milestone-container-left'>
                                        <div className='pArrow'>
                                            <svg onClick={() => { handlePhaseList(milestone) }} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.27612 7.68831L12.709 1.76623C13.097 1.35065 13.097 0.727272 12.709 0.311687C12.3209 -0.103897 11.7388 -0.103897 11.3507 0.311687L6.5 5.4026L1.64925 0.311687C1.26119 -0.103897 0.679104 -0.103897 0.291045 0.311687C0.0970147 0.51948 0 0.727273 0 1.03896C0 1.35065 0.0970147 1.55844 0.291045 1.76623L5.72388 7.68831C6.20895 8.1039 6.79105 8.1039 7.27612 7.68831C7.1791 7.68831 7.1791 7.68831 7.27612 7.68831Z" fill="#263238" fill-opacity="0.4" />
                                            </svg>
                                        </div>
                                        <div className='pText-Info'>
                                            <div className='milestone-heading'>{milestone.milestoneName}</div>
                                            <div className='milestone-duration'>{milestone.milestoneStartDate} &lt;--&gt; {milestone.milestoneEndDate}</div>
                                        </div>
                                    </div>
                                    <div className='milestone-container-right'>
                                        <svg onClick={handleMilesShow} width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2" cy="2" r="2" fill="#263238" fill-opacity="0.8" />
                                            <circle cx="10" cy="2" r="2" fill="#263238" fill-opacity="0.8" />
                                            <circle cx="18" cy="2" r="2" fill="#263238" fill-opacity="0.8" />
                                        </svg>{milesShow ?
                                            <div className='milestone-operation' ref={mileRef}>
                                                <div className='milestone-edit'>
                                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.78813 0.954054C10.2602 0.492902 10.8951 0.236434 11.5551 0.240277C12.215 0.24412 12.8469 0.507963 13.3135 0.974582C13.7802 1.4412 14.0441 2.07297 14.0479 2.73285C14.0518 3.39274 13.7953 4.02753 13.3341 4.49956L12.7702 5.06336L9.22425 1.51786L9.78813 0.954054ZM8.62834 2.11454L1.88624 8.85579C1.61036 9.13197 1.41886 9.48101 1.33416 9.86205L0.594127 13.191C0.578648 13.2602 0.580917 13.3323 0.600723 13.4004C0.620528 13.4686 0.657229 13.5306 0.707419 13.5808C0.75761 13.631 0.819665 13.6677 0.887826 13.6875C0.955987 13.7073 1.02805 13.7096 1.09732 13.6941L4.40642 12.9584C4.80041 12.8707 5.16121 12.6724 5.44652 12.387L12.1743 5.66004L8.62834 2.11454Z" fill="#FF8402" />
                                                    </svg>
                                                    Edit
                                                </div>
                                                <hr />
                                                <div className='milestone-delete'>
                                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.5935 12.3586C1.5935 13.1814 2.30167 13.8546 3.16721 13.8546H9.46207C10.3276 13.8546 11.0358 13.1814 11.0358 12.3586V4.87862C11.0358 4.05582 10.3276 3.38262 9.46207 3.38262H3.16721C2.30167 3.38262 1.5935 4.05582 1.5935 4.87862V12.3586ZM11.0358 1.13862H9.06864L8.50997 0.607545C8.36834 0.472905 8.16375 0.390625 7.95917 0.390625H4.67011C4.46553 0.390625 4.26094 0.472905 4.11931 0.607545L3.56064 1.13862H1.5935C1.16073 1.13862 0.806641 1.47522 0.806641 1.88662C0.806641 2.29803 1.16073 2.63462 1.5935 2.63462H11.0358C11.4686 2.63462 11.8226 2.29803 11.8226 1.88662C11.8226 1.47522 11.4686 1.13862 11.0358 1.13862Z" fill="#FF8402" />
                                                    </svg>
                                                    Delete
                                                </div>
                                            </div> : null
                                        }
                                    </div>
                                </div>
                                {isPhaseList && phaseList.length > 0 && phaseList.map((phase) => <div className='pPhase-container'>
                                    <div className='left-border-container' />
                                    <div className='phase-container'>
                                        <div className='phase-container-left'>
                                            <div className='pArrow' style={{ borderRight: "4px solid rgba(1, 159, 55, 1)" }}>
                                                <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.27612 7.68831L12.709 1.76623C13.097 1.35065 13.097 0.727272 12.709 0.311687C12.3209 -0.103897 11.7388 -0.103897 11.3507 0.311687L6.5 5.4026L1.64925 0.311687C1.26119 -0.103897 0.679104 -0.103897 0.291045 0.311687C0.0970147 0.51948 0 0.727273 0 1.03896C0 1.35065 0.0970147 1.55844 0.291045 1.76623L5.72388 7.68831C6.20895 8.1039 6.79105 8.1039 7.27612 7.68831C7.1791 7.68831 7.1791 7.68831 7.27612 7.68831Z" fill="#263238" fill-opacity="0.4" />
                                                </svg>
                                            </div>
                                            <div className='pText-Info'>
                                                <div className='phase-heading'>Phase no. 1</div>
                                                <div className='phase-duration'>12 January 2024 - 12 February 2024</div>
                                            </div>
                                        </div>
                                        <div className='phase-container-right'>
                                            <div className='phase_period'>
                                                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8056 9.6C10.9472 9.59993 11.0865 9.56503 11.2102 9.4986C11.3339 9.43218 11.4378 9.33645 11.5123 9.22048C11.5867 9.10452 11.6291 8.97217 11.6355 8.83599C11.6419 8.69982 11.612 8.56434 11.5487 8.4424L10.0725 5.6L11.5487 2.7576C11.612 2.63566 11.6419 2.50018 11.6355 2.364C11.6291 2.22783 11.5867 2.09548 11.5123 1.97952C11.4378 1.86355 11.3339 1.76782 11.2102 1.7014C11.0865 1.63498 10.9472 1.60007 10.8056 1.6H1.6624V0.8C1.6624 0.587827 1.57483 0.384344 1.41895 0.234315C1.26307 0.0842854 1.05165 0 0.831201 0C0.610752 0 0.399334 0.0842854 0.243453 0.234315C0.0875728 0.384344 0 0.587827 0 0.8V15.2C0 15.4122 0.0875728 15.6157 0.243453 15.7657C0.399334 15.9157 0.610752 16 0.831201 16C1.05165 16 1.26307 15.9157 1.41895 15.7657C1.57483 15.6157 1.6624 15.4122 1.6624 15.2V9.6H10.8056Z" fill="#FF8402" />
                                                </svg>
                                                {calculateDays(phase.phaseStartDate, phase.phaseEndDate)} days left
                                            </div>
                                            <svg onClick={handleMilesShow} width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="2" cy="2" r="2" fill="#263238" fill-opacity="0.8" />
                                                <circle cx="10" cy="2" r="2" fill="#263238" fill-opacity="0.8" />
                                                <circle cx="18" cy="2" r="2" fill="#263238" fill-opacity="0.8" />
                                            </svg>
                                            {milesShow ?
                                                <div className='milestone-operation' ref={mileRef}>
                                                    <div className='milestone-edit'>
                                                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.78813 0.954054C10.2602 0.492902 10.8951 0.236434 11.5551 0.240277C12.215 0.24412 12.8469 0.507963 13.3135 0.974582C13.7802 1.4412 14.0441 2.07297 14.0479 2.73285C14.0518 3.39274 13.7953 4.02753 13.3341 4.49956L12.7702 5.06336L9.22425 1.51786L9.78813 0.954054ZM8.62834 2.11454L1.88624 8.85579C1.61036 9.13197 1.41886 9.48101 1.33416 9.86205L0.594127 13.191C0.578648 13.2602 0.580917 13.3323 0.600723 13.4004C0.620528 13.4686 0.657229 13.5306 0.707419 13.5808C0.75761 13.631 0.819665 13.6677 0.887826 13.6875C0.955987 13.7073 1.02805 13.7096 1.09732 13.6941L4.40642 12.9584C4.80041 12.8707 5.16121 12.6724 5.44652 12.387L12.1743 5.66004L8.62834 2.11454Z" fill="#FF8402" />
                                                        </svg>
                                                        Edit
                                                    </div>
                                                    <hr />
                                                    <div className='milestone-delete'>
                                                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.5935 12.3586C1.5935 13.1814 2.30167 13.8546 3.16721 13.8546H9.46207C10.3276 13.8546 11.0358 13.1814 11.0358 12.3586V4.87862C11.0358 4.05582 10.3276 3.38262 9.46207 3.38262H3.16721C2.30167 3.38262 1.5935 4.05582 1.5935 4.87862V12.3586ZM11.0358 1.13862H9.06864L8.50997 0.607545C8.36834 0.472905 8.16375 0.390625 7.95917 0.390625H4.67011C4.46553 0.390625 4.26094 0.472905 4.11931 0.607545L3.56064 1.13862H1.5935C1.16073 1.13862 0.806641 1.47522 0.806641 1.88662C0.806641 2.29803 1.16073 2.63462 1.5935 2.63462H11.0358C11.4686 2.63462 11.8226 2.29803 11.8226 1.88662C11.8226 1.47522 11.4686 1.13862 11.0358 1.13862Z" fill="#FF8402" />
                                                        </svg>
                                                        Delete
                                                    </div>
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                </div>)}
                                {isPhaseList ?
                                    <div className='add-phase'>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.13333 8.5C1.13333 4.4315 4.4315 1.13333 8.5 1.13333C12.5685 1.13333 15.8667 4.4315 15.8667 8.5C15.8667 12.5685 12.5685 15.8667 8.5 15.8667C4.4315 15.8667 1.13333 12.5685 1.13333 8.5ZM8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0ZM8.5 4.63636C8.92676 4.63636 9.27273 4.88348 9.27273 5.18831V7.72727H11.8117C12.1165 7.72727 12.3636 8.07323 12.3636 8.5C12.3636 8.92676 12.1165 9.27273 11.8117 9.27273H9.27273V11.8117C9.27273 12.1165 8.92676 12.3636 8.5 12.3636C8.07323 12.3636 7.72727 12.1165 7.72727 11.8117V9.27273H5.18831C4.88348 9.27273 4.63636 8.92676 4.63636 8.5C4.63636 8.07323 4.88348 7.72727 5.18831 7.72727H7.72727V5.18831C7.72727 4.88348 8.07323 4.63636 8.5 4.63636Z" fill="#263238" fill-opacity="0.4" />
                                        </svg>
                                        <div className='add-phase-text' onClick={() => {
                                            dispatch(setMilestoneObj(milestone))
                                            dispatch(setAddPhase("phase"))
                                        }}>Add New Phase</div>
                                    </div> : null
                                }

                            </div>)
                        )
                    }
                    <div className='add-phase'>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.13333 8.5C1.13333 4.4315 4.4315 1.13333 8.5 1.13333C12.5685 1.13333 15.8667 4.4315 15.8667 8.5C15.8667 12.5685 12.5685 15.8667 8.5 15.8667C4.4315 15.8667 1.13333 12.5685 1.13333 8.5ZM8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0ZM8.5 4.63636C8.92676 4.63636 9.27273 4.88348 9.27273 5.18831V7.72727H11.8117C12.1165 7.72727 12.3636 8.07323 12.3636 8.5C12.3636 8.92676 12.1165 9.27273 11.8117 9.27273H9.27273V11.8117C9.27273 12.1165 8.92676 12.3636 8.5 12.3636C8.07323 12.3636 7.72727 12.1165 7.72727 11.8117V9.27273H5.18831C4.88348 9.27273 4.63636 8.92676 4.63636 8.5C4.63636 8.07323 4.88348 7.72727 5.18831 7.72727H7.72727V5.18831C7.72727 4.88348 8.07323 4.63636 8.5 4.63636Z" fill="#263238" fill-opacity="0.4" />
                        </svg>
                        <div className='add-phase-text' onClick={() => {
                            dispatch(setAddPhase("milestone"))
                        }}>Add New Milestone</div>
                    </div>
                </div>
            }

        </div>
    )
}

export default ProjectInfo
