
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Calendar, globalizeLocalizer } from 'react-big-calendar';
import globalize from 'globalize';
import './AttendenceMonthView.css';
import moment from 'moment';
import axios from 'axios';
import BasicTable from '../../Dayview/DayView';
import { useDispatch, useSelector } from 'react-redux';
import { setView, setAddTask, setFullDate, setDate, setSearchedDate, setSearchedMonth, setWeekData, setMonthData, setTaskEmp, setAddHolidayFlag, setAddLeaveFlag, setLeaveDayViewFlag, setOnLeaveDate, setHolidayListFlag, setMyLeaveStatusFlag, setAttendenceMontViewFlag, setAttendMusteredFlag, setAttendenceAnalyticsDate } from '../../../../erpStore/actions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Calendar as RouteCalender } from 'primereact/calendar'


const localizer = globalizeLocalizer(globalize);

function AttendenceMonthView() {
    const culture = globalize.culture('en-US');
    const view = useSelector((state) => state.data.view)
    culture.calendar.firstDay = 1;
    const dispatch = useDispatch();
    const empView = useSelector((state) => state.data.empView)
    const taskUpdate = useSelector((state) => state.data.newTask)
    const viewM = useSelector((state) => state.data.view)
    const self = useSelector((state) => state.data.selfData)
    const empInfo = useSelector((state) => state.data.empInfo)
    const taskEmp = useSelector((state) => state.data.taskEmp)
    // const selfInfo = empView ? empInfo : self
    const selfInfo = empView ? taskEmp : self
    const url = useSelector((state) => state.data.apiUrl)
    const [dayView, setDayView] = useState(true);
    const monthData = useSelector((state) => state.data.monthData);
    // const [monthData, setMonthData] = useState({});
    const searchedTaskMonth = useSelector((state) => state.data.searchedTaskMonth)
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const [isDateRoute, setIsDateRoute] = useState(false)
    const mothDateRouteWrapper = useRef(null)
    let nDate = []
    const [holidayId, setHolidayId] = useState()
    const [leaveDecFlag, setLeaveDecFlag] = useState(false)
    const musterdAttObj = useSelector((state)=> state.data.musterdAttObj)
    const attendenceAnalyticsDate = useSelector((state) => state.data.attendenceAnalyticsDate)

    useEffect(() => {
        dispatch(setTaskEmp(taskEmp))
    }, [])

    useEffect(() => {
        if (window.localStorage.getItem("myHeaders") === "") {
            navigate('/login')
            return
        }

        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        if (parseInt(nDate[3]) && parseInt(getMonthNumber(nDate[1]))) {
            axios.post(`${url}attendance/emp/for-month `, { 'userId': musterdAttObj.userId,  "monthId": parseInt(getMonthNumber(nDate[1])), "year": parseInt(nDate[3]) }, {
                headers: storedHeaders,
            })
                .then((response) => {
                    setLoader(false)
                    dispatch(setMonthData(response.data))
                })
                .catch((error) => {
                    if (error.response && error.response.status === 500) {
                        navigate("/server-error")
                    }
                })
            return
        }
    }, [])

    useEffect(() => {
        function handleOutsideClick(event) {
            if (mothDateRouteWrapper && mothDateRouteWrapper.current && !mothDateRouteWrapper.current.contains(event.target)) {
                setIsDateRoute(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])
    const getMonthNumber = (monthName) => {
        if (monthName) {
            const monthMoment = moment().month(monthName).startOf('month');
            return monthMoment.format('MM');
        }
    };


    function convertTimeFormat(timeString) {
        const [hours, minutes] = timeString.split(':');
    
        let hourNum = parseInt(hours, 10);
        const ampm = hourNum >= 12 ? 'pm' : 'am';
    
        hourNum = hourNum % 12;
        hourNum = hourNum === 0 ? 12 : hourNum;
    
        const hourStr = hourNum < 10 ? '0' + hourNum : hourNum;
        let minuteStr = ''
        if (minutes === '00') {
          minuteStr = '00'
        } else {
          minuteStr = minutes < 10 && minutes !== 0 ? '' + minutes : minutes;
        }
        return hourStr + ':' + minuteStr + ' ' + ampm;
      }
    

    const navigateNextMonth = (onNavigate) => {
        setLoader(true)
        onNavigate("NEXT")

        dispatch(setAttendenceAnalyticsDate(routeDate))
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        if (nDate) {
            axios.post(`${url}attendance/emp/for-month `, {'userId': musterdAttObj.userId, 'year': parseInt(getMonthNumber(nDate[1])) + 1 === 13 ? parseInt(nDate[3]) + 1 : parseInt(nDate[3]), "monthId": parseInt(getMonthNumber(nDate[1])) + 1 === 13 ? 1 : parseInt(getMonthNumber(nDate[1])) + 1 }, { headers: storedHeaders })
                .then((response) => {
                    setLoader(false)
                    dispatch(setSearchedMonth([]))
                    dispatch(setMonthData(response.data))
                })
                .catch((error) => {
                    if (error.response && error.response.status === 500) {
                        navigate("/server-error")
                    }
                })
        }
    }

    const navigatePrevMonth = (onNavigate) => {
        setLoader(true)
        onNavigate("PREV")
        dispatch(setAttendenceAnalyticsDate(routeDate))
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        if (nDate) {
            axios.post(`${url}attendance/emp/for-month `, {'userId': musterdAttObj.userId, "monthId": parseInt(getMonthNumber(nDate[1])) - 1 === 0 ? 12 : parseInt(getMonthNumber(nDate[1])) - 1, "year": parseInt(getMonthNumber(nDate[1])) - 1 === 0 ? parseInt(nDate[3]) - 1 : parseInt(nDate[3]) }, {
                headers: storedHeaders,
            })
                .then((response) => {
                    setLoader(false)
                    dispatch(setMonthData(response.data))
                })
                .catch((error) => {
                    if (error.response && error.response.status === 500) {
                        navigate("/server-error")
                    }
                })
        }
    }

    //comparison of date to date is less than joining date for add task condition 
    const isDateLessThanCurrent = (date) => {
        const givenDate = moment(date.value); // Convert string to Date object
        const currentDate = moment(selfInfo.empJoiningDate); // Get current date
        // Compare the dates
        return givenDate < currentDate;
    };

    const renderCustomMonthCell = (date) => {
        if (!date) {
            return <div className='rbc-day-bg'>No date</div>;
        }
        const dateString = date.value + "";
        const dateSplit = dateString.split(" ");
        const calendarDay = dateSplit[2].toString();
        const newDate = new Date();
        const listDate = newDate.toString().split(" ");
        const getToday = listDate[2].toString();
        const currentMonth = dateSplit[1].toString() + dateSplit[3].toString();
        const todayMonth = listDate[1].toString() + listDate[3].toString();
        const isToday = calendarDay === getToday && currentMonth === todayMonth;
        const gtDate = (parseInt(getMonthNumber(dateSplit[1])) === parseInt(getMonthNumber(listDate[1])) && parseInt(dateSplit[2]) < parseInt(listDate[2]));
        let monthNum = getMonthNumber(dateSplit[1]);
        const fullDate = dateSplit[3] + "-" + monthNum + "-" + dateSplit[2].padStart(2, '0');
        if (fullDate === (dateSplit[3] + "-" + monthNum + "-" + 15)) {
            dispatch(setSearchedDate(fullDate))
        }
        const taskList = searchedTaskMonth.length > 0 ? searchedTaskMonth[fullDate] : monthData[fullDate];
        if (!labelData[1].includes(dateSplit[1])) {
            if (dateSplit[0] === "Sun") {
                document.querySelector(".rbc-month-header").children[6].setAttribute("style", "min-width:4.8%")
                return <div className='rbc-day-bg off-day sunday-block'><div className='dateContainer'>{dateSplit[2]}</div></div>;
            }
            return <div className='rbc-day-bg off-day'></div>;
        }

        if (dateSplit[0] === "Sun") {
            return (
                <div className='rbc-day-bg sunday-block leaveMang-sunday-block'>
                    <div className='dateContainer' onClick={() => {
                        if (isDateLessThanCurrent(date)) {
                            toast.warning("No task available before employee's joining date");
                            return
                        }
                        const previousDate = moment(date.value)
                        dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                        setDayView(!dayView);
                        dispatch(setView('day'))
                        window.sessionStorage.setItem("view", 'day')
                    }}>{dateSplit[2]}</div>
                    <div className='innerDiv-addTask-sunday' onClick={(e) => {
                        if (isDateLessThanCurrent(date)) {
                            toast.warning("No task available before employee's joining date");
                            return
                        }
                        const previousDate = moment(date.value)
                        dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                        // dispatch(setAddTask(true))

                        dispatch(setDate(fullDate))
                    }} style={{ cursor: 'pointer' }}>
                        <div className='add-task-container-sunday' style={{ display: isDateLessThanCurrent(date) ? "none" : "flex" }}>
                            <p className='leaveMngSunday'>Sunday</p>
                        </div>
                    </div>
                </div>

            );
        }
        if (loader) {
            return (
                <div className='rbc-day-bg'>
                    <div className='innerDiv-day'
                    // style={{
                    //     background: isToday ? '#FFF6DF' : 'none', opacity: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "1" : "1"
                    // }}
                    ><div className='innerDiv-addTask' style={{ borderBottom: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "null" : "null", cursor: 'pointer' }}>
                            <div className='add-task-container' style={{ display: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "flex" : "flex" }}>
                                <div class="loader-month loader-addTask"></div>
                            </div>
                        </div>
                        <div className='innerDivTask task-loader' onClick={() => {
                            const previousDate = moment(date.value)
                            dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                            window.sessionStorage.setItem("view", 'day')
                        }}>
                            <div class="loader-month"></div>
                            <div class="loader-month"></div>
                            <div class="loader-month"></div>
                        </div>
                    </div>
                </div>
            );
        }
        if (true) {
            return (
                <div className='rbc-day-bg'>
                    <div className='innerDiv-day innerDiv-leaveCal' onClick={() => {
                        const date = new Date(fullDate);
                        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        const dayName = days[date.getUTCDay()];
                        const day = String(date.getUTCDate()).padStart(2, '0');
                        const monthName = months[date.getUTCMonth()];
                        const year = date.getUTCFullYear();
                        const tempOnLeave = `${dayName}, ${day} ${monthName} ${year}`;
                        dispatch(setOnLeaveDate(tempOnLeave))
                    }}
                        style={{
                            background: isToday ? '#FFF6DF' : ( taskList && taskList?.holiday !== null ? '#E3E3E3' :  taskList?.leave?.leaveFormat === 'FULLDAY' ? 'rgb(255, 223, 223)' : 'none'), opacity: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "1" : "1",
                            border: "1.px solid grey"
                        }}
                    >
                        <div className='innerDivTask-countAttendence' style={{ display: 'flex', justifyContent: taskList?.holiday !== null ? 'center' : 'center', alignItems: taskList?.holiday !== null ? 'center' : 'center' }} onClick={() => {
                            const previousDate = moment(date.value)
                            dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                            setDayView(!dayView);
                            dispatch(setView('day'))
                            window.sessionStorage.setItem("view", 'day')
                        }}>
                                { taskList && taskList?.holiday !== null && 
                                    <div className='taskListCount-container' style={{ width: '100%' }}>
                                        <div className='taskDivCount holidayStatusDay' style={{ background: "#4B555A" , height: 'auto'}} onMouseEnter={() => {
                                            }}>
                                            <div className='taskDiv-half-container'>
                                                <div className='task-holder' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div title={`${taskList?.holiday?.holidayDescription}`}>
                                                        {}
                                                        {taskList?.holiday?.holidayTitle.length > 15 ? taskList?.holiday?.holidayTitle?.substring(0, 25) + '...' : taskList?.holiday?.holidayTitle}
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                }    

                                {taskList?.holiday === null &&  taskList?.leave?.leaveFormat === 'FULLDAY'  && 
                                    <div className='taskListCount-container' style={{ width: '100%' }}>                
                                            <div className='taskDivCount' style={{background: "#fff"}}>
                                                <div className='taskDiv-half-container'>
                                                    <div title="Assign" className="status"  style={{background: "rgba(234, 22, 22, 1)"}}></div>
                                                    <div className='task-holder'>   
                                                        <p>full day leave</p>
                                                    </div>
                                                </div>
                                            </div>                             
                                    </div> 
                                }

                                { taskList?.holiday === null && (taskList?.leave?.leaveFormat === null || taskList?.leave?.leaveFormat !== 'FULLDAY') && 
                                    <div className='taskListCount-container' style={{ width: '100%' }}> 
                                        <div className='taskDivCount' style={{ background: "rgba(0, 95, 182, 0.15)"}}>
                                            <div className='taskDiv-half-container'>
                                                <div title="Assign" className="status" style={{background: "rgba(0, 95, 182, 1)" }}></div>
                                                <div className='task-holder'>
                                                    <p>{'in Time'}</p>
                                                </div>
                                            </div>
                                            <div className='priority-container'  style={{width: '35%'}}>
                                                <p className='priority'>{taskList?.inTime !== null ? convertTimeFormat(taskList?.inTime) : ''}</p>
                                            </div>
                                        </div>

                                        <div className='taskDivCount' style={{ background: "rgba(0, 95, 182, 0.15)"}}>
                                            <div className='taskDiv-half-container'>
                                                <div title="Assign" className="status" style={{ background: "rgba(0, 95, 182, 1)" }}></div>
                                                <div className='task-holder'>
                                                    <p>{'out Time'}</p>
                                                </div>
                                            </div>
                                            <div className='priority-container' style={{width: '35%'}}>
                                                <p className='priority'>{ taskList?.outTime ? convertTimeFormat(taskList?.outTime) : ''}</p>
                                            </div>
                                        </div>

                                        { (taskList?.leave !== null && taskList?.leave?.leaveFormat !== 'FULLDAY') && 
                                            <div className='taskDivCount' style={{ background: " rgba(98, 63, 173, 0.15)" }}>
                                                <div className='taskDiv-half-container'>
                                                    <div title="Assign" className="status" style={{ background: " rgba(98, 63, 173, 1)" }}></div>
                                                    <div className='task-holder'>
                                                        <p>{taskList?.leave?.leaveFormat === 'HALFDAY1' ? 'First half leave' : 'Second half leave'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                }
                        </div>
                    </div>
                </div>
            );
        }

    };

    const handleDayView = () => {

        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Get the day of the week, date, month, and year
        const dayOfWeek = weekdays[new Date().getDay()];
        const day = new Date().getDate();
        const month = months[new Date().getMonth()];
        const year = new Date().getFullYear();

        // Format the date string
        const formattedDate = `${dayOfWeek}, ${day} ${month} ${year}`;
        setDayView(!dayView);
        dispatch(setOnLeaveDate(formattedDate))
        dispatch(setView('day'))
        window.sessionStorage.setItem("view", 'day')
    };

    const onViewChange = (onView, view, date) => {
        dispatch(setView(view))
        window.sessionStorage.setItem("view", view)
        onView(view)
    }

    const [routeDate, setRouteDate] = useState(attendenceAnalyticsDate)
    const handleDateRoute = (e, onNavigate) => {
        setRouteDate(e.target.value)
        dispatch(setAttendenceAnalyticsDate(e.target.value))
        setLoader(true)
        onNavigate('DATE', e.target.value)
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        nDate = moment(e.target.value)
        const month = moment(e.target.value).month() + 1;
        const year = moment(e.target.value).year();
        setIsDateRoute(false)
        if (nDate) {

            axios.post(`${url}leave/data-month`, { "year": year, "monthId": month }, {
                headers: storedHeaders,
            })
                .then((response) => {
                    setLoader(false)
                    dispatch(setMonthData(response.data))

                })
                .catch((error) => {
                    if (error.response && error.response.status === 500) {
                        navigate("/server-error")
                    }
                })
        }
    }

    let labelData = [];
    const CustomMonthCell = React.memo(renderCustomMonthCell);

    const CustomToolbar = (toolbar) => {
        const { label, onView, onNavigate, view, date } = toolbar;
        const month = label.split(' ');
        labelData = month;
        const dateString = toolbar.date + "";
        const dateSplit = dateString.split(" ");
        nDate = dateSplit
        const calendarDay = dateSplit[2].toString();
        const newDate = new Date();
        const listDate = newDate.toString().split(" ");
        const getToday = listDate[2].toString();
        const currentMonth = dateSplit[1].toString() + dateSplit[3].toString();
        const todayMonth = listDate[1].toString() + listDate[3].toString();
        const isToday = calendarDay === getToday && currentMonth === todayMonth;
        const weekNumberInMonth = moment(toolbar.date).isoWeek() - moment(toolbar.date).startOf('month').isoWeek() + 1;

            return (
                <div className='monthView-toolbar'>
                    <div className='label'>
                        <svg onClick={() => navigatePrevMonth(onNavigate)} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.19231 0L6 0.916667L1.73077 5.5L6 10.0833L5.19231 11L4.76837e-07 5.5L5.19231 0Z" fill="#FF8402" />
                        </svg>
                        &nbsp;
                        <div className='label-container' onClick={() => setIsDateRoute(true)}>{month[1]}, {month[0]}</div>
                        &nbsp;
                        <svg onClick={() => navigateNextMonth(onNavigate)} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.807692 0L0 0.916667L4.26923 5.5L0 10.0833L0.807692 11L6 5.5L0.807692 0Z" fill="#FF8402" />
                        </svg>
                        <div className='date-holder-dashboard month-dateRoute'>
                            {
                                isDateRoute ? <div className='calender-holder-dashboard' ref={mothDateRouteWrapper}>
                                    <RouteCalender value={routeDate} inline view='month' unstyled={false} onChange={(e) => {
                                        handleDateRoute(e, onNavigate)}} />
                                </div> : null
                            }

                        </div>
                    </div>
                </div>
            );
        
    };


    return (
        <div className="LeaveCalMainView">
            <div className='leaveManagement-header'>
                <div className='leaveManHeading'>
                    <svg width="26" height="26" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                        dispatch(setMyLeaveStatusFlag(false))
                        dispatch(setAttendenceMontViewFlag(false))
                        dispatch(setAttendMusteredFlag(true))
                    }}>
                        <path d="M19 12.8105H5" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 19.8105L5 12.8105L12 5.81055" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div>
                        {musterdAttObj.empName}
                    </div>
                    <div className='desgnaitionLeaveMonth'>{`(${musterdAttObj.designation})`}</div>
                </div>
                <div className='headerSerchBtnDiv'>
                    <div className='OverallOptionBtn'>
                        <button onClick={() => {
                            dispatch(setAttendenceMontViewFlag(false))
                            dispatch(setMyLeaveStatusFlag(true))
                        }}>Overall Leaves</button>
                    </div>
                </div>
            </div>
            <div className='Leavesviews'>
                    <Calendar
                        localizer={localizer}
                        events={[]}
                        startAccessor="start"
                        endAccessor="end"
                        cultures={{ 'en-US': culture }}
                        components={{
                            toolbar: CustomToolbar,
                            month: {
                                dateCellWrapper: CustomMonthCell,
                            },
                            week: {
                                header: null,
                            },
                        }}
                    />
            </div>
        </div>

    );

}

export default AttendenceMonthView

