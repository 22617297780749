import React from "react";
import "./styleBadResponse.css";

const BadResponse = () => {
    return (
        <div className="badresponse-page">
            <div className="badresponse-container">
                <div className="badresponse-message-container">

                </div>
                <div className="bad-response-button" onClick={() => window.history.back()}>Go Back</div>
            </div>
        </div>
    )
}

export default BadResponse;