import React, { useState } from 'react'
import './DenyPopUp.css'
import { useDispatch, useSelector } from 'react-redux'
import { setrqtDenypopup, setDenyRqtObj, setRequestObject, setReqObj, setRequestCount } from '../../../erpStore/actions';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';


function DenyPopUp() {
    const [denyReason, setDenyReason] = useState('')
    const url = useSelector((state) => state.data.apiUrl)
    const self = useSelector((state) => state.data.selfData)
    const rqtObj = useSelector((state) => state.data.denyRequestObj)
    const dispatch = useDispatch()
    const reqIndex = useSelector((state) => state.data.reqRejectI)
    const requestObj = useSelector((state) => state.data.reqObj)
    const [rejectLoader, setRejectLoader] = useState(false)

    const navigate = useNavigate();
    const handldenyReason = (e) => {
        setDenyReason(e.target.value)
    }

    const handleSendRqt = () => {
        setRejectLoader(true)
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        axios.put(`${url}task/req/status/change`, { "requestId": rqtObj.requestId, "empId": self.empId, "statusId": 2, "requestRemark": denyReason }, {
            headers: storedHeaders
        })
            .then(response => {
                setDenyReason('')
                if (response && (response.status === 200 || response.status === 208)) {
                    const updatedReqObj = requestObj.filter(obj => obj.requestId !== reqIndex);
                    dispatch(setReqObj(updatedReqObj))
                    dispatch(setrqtDenypopup(false))
                    try {
                        axios.get(`${url}task/pending-req`, {
                            headers: storedHeaders
                        })
                            .then((response) => {
                                if (response && response.data) {
                                    dispatch(setRequestCount(response.data))
                                    dispatch(setrqtDenypopup(false))
                                    setRejectLoader(false)

                                }
                            }).catch((err) => {
                                dispatch(setrqtDenypopup(false))
                                if (err.response && err.response.status === 500) {
                                    navigate("/server-error")
                                }
                            })

                    } catch (err) {
                        setRejectLoader(false)
                        dispatch(setrqtDenypopup(false))

                        if (err.response && err.response.status === 500) {
                            navigate("/server-error")
                        }

                    }
                }
            })
            .catch(error => {
                if(error.response.status === 404){
                    const updatedReqObj = requestObj.filter(obj => obj.requestId !== reqIndex);
                    dispatch(setReqObj(updatedReqObj)) 
                }
                dispatch(setrqtDenypopup(false))
                setRejectLoader(false)
            })

        dispatch(setDenyRqtObj(null))
    }
    return (

        <div className='denipop-mainContainer'>
            <div className='card-deny-rt' >
                <div className='denyReason'>
                    Mention reason to deny request
                </div>
                <div className='rqt-deny-ipt'>
                    <input type='text' placeholder='Enter your reason' onChange={(e) => handldenyReason(e)} />
                </div>

                <div className='rqt-deny-btns'>
                    <button className='rqt-deny-btn-send' onClick={() => {
                        handleSendRqt()
                    }}>{rejectLoader  ? <div className="approvedLoaderReject"></div> : 'Send'}</button>
                    <button className='rqt-deny-btn-cancel' onClick={() => {
                        dispatch(setrqtDenypopup(false))
                        dispatch(setDenyRqtObj(null))
                    }}>Cancel</button>
                </div>
            </div>
        </div>

    )
}

export default DenyPopUp
