import React, { useEffect, useRef, useState } from 'react'
import './AttendenceMustered.css'
import moment from 'moment';
import { Calendar as RouteCalender } from 'primereact/calendar';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import profile from '../../../../assets/images/profile.png'
import { useDispatch } from 'react-redux';
import { setAttendenceAnalyticsDate, setAttendenceMontViewFlag, setMusterdDate, setMusteredObj, setView } from '../../../../erpStore/actions';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function AttendenceMustered() {

    const dispatch = useDispatch()
    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);
    const selfInfo = useSelector((state) => state.data.selfData)
    const url = useSelector((state) => state.data.apiUrl)
    const [daysList, setDaysList] = useState([])
    let nDate = []
    const currentDate = new Date();
    nDate = moment(currentDate)
    const [currentMonth, setCurrentMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [isDateRoute, setIsDateRoute] = useState(false);
    const [routeDate, setRouteDate] = useState(new Date());
    const mothDateRouteWrapper = useRef(null);
    const [musteredList, setMusteredList] = useState([])
    const [attendenceDates, setAttedenceDates] = useState([])

    const [tempMusteredList, setTempMusteredList] = useState([])
    const [selectedTeaser, setSelectedTester] = useState(null)

    const [loader, setLoader] = useState(false)

    const getMonthDays = (data) => {
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const result = [];
    
        // Ensure the month parameter is 0-indexed (0 = January, 1 = February, ..., 11 = December)
        var i = 0;
        while (i < data[0]?.attendance?.length) {
            const date = new Date(data[0]?.attendance[i].date);
            const day = date.getDate();
            const weekDay = daysOfWeek[date.getDay()];
            const yearTemp = date.getFullYear();
            const monthTemp = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
            const dayTemp = String(date.getDate()).padStart(2, '0');
            const formattedDate = `${yearTemp}-${monthTemp}-${dayTemp}`;    
                result.push({
                    date: formattedDate,
                    day: day.toString(),
                    weekDay: weekDay
                });
            i++;
        }
        return result;
    };
    
        
    const getMonthNumber = (monthName) => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return monthNames.indexOf(monthName) + 1;
    }

        const getMusterdData = () => {
            setLoader(true)
            setTempMusteredList([])
            axios.post(`${url}attendance/for-month`, { "userId": selfInfo.userId, "monthId": getMonthNumber(currentMonth) , "year":  currentYear}, { headers: storedHeaders })
            .then(response => {
                if (response.request.status === 200) {
                    var temp = response.data
                    // temp.map((obj, index) => {
                    //     const filteredAttendance = obj.attendance.filter((obj, index) => !isSunday(obj.date));
                    //     obj.attendance = filteredAttendance
                    //     temp[index] = obj
                    // })
                    setDaysArray(getMonthDays(response.data))
                    setMusteredList(temp)
                    setTempMusteredList(temp)
                    setLoader(false)
                }
            })
            .catch(error => {
                if(error.request.status ===  422){
                    setTempMusteredList([])
                    setMusteredList([])
                    setDaysArray([])
                    toast.error('Attendence is not allowed for this month', { autoClose: 2000 })
                    setLoader(false)

                }
            })
        }

    const [dayArray, setDaysArray] = useState([])
    useEffect(() => {
        getMusterdData()
    }, [currentMonth, currentYear])



    const navigatePrevMonth = () => {
        const newDate = new Date(routeDate.setMonth(routeDate.getMonth() - 1));
        setRouteDate(newDate);
        setCurrentMonth(newDate.toLocaleString('default', { month: 'long' }));
        setCurrentYear(newDate.getFullYear());
        dispatch(setAttendenceAnalyticsDate(routeDate))

    };

    const navigateNextMonth = () => {
        const newDate = new Date(routeDate.setMonth(routeDate.getMonth() + 1));
        setRouteDate(newDate);
        setCurrentMonth(newDate.toLocaleString('default', { month: 'long' }));
        setCurrentYear(newDate.getFullYear());
        dispatch(setAttendenceAnalyticsDate(routeDate))
    };

    const handleDateRoute = (date) => {
        setRouteDate(date.value);
        setCurrentMonth(date.value.toLocaleString('default', { month: 'long' }));
        setCurrentYear(date.value.getFullYear());
        setIsDateRoute(false);
        dispatch(setAttendenceAnalyticsDate(routeDate))

    };

    useEffect(() => {
        function handleOutsideClick(event) {
            if (mothDateRouteWrapper && mothDateRouteWrapper.current && !mothDateRouteWrapper.current.contains(event.target)) {
                setIsDateRoute(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])

    const handleSearchEmpTrns = (e) => {
        const searchQuery = e.target.value.toLowerCase();
        const results = musteredList.filter(employee =>
          employee.empName.toLowerCase().includes(searchQuery));
    
        if (e.target.value === '') {
            setTempMusteredList(musteredList)
        } else {
            setTempMusteredList(results)
        }
      }

    function isSunday(dateString) {
        const date = new Date(dateString);
        return date.getDay() === 0;
    }


    
    const getPreviousMonth = (currentMonth) => {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return months[currentMonth - 2]
    }

    const getNextMonth = (currentMonth) => {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return months[currentMonth]
    }

    const createJsonData = () => {
        return tempMusteredList.map(employee => {
            const baseObject = {
                empId: employee.userId,
                name: employee.empName,
            };
    
            dayArray.forEach(day => {
                const dateKey = day.date.padStart(10, '0'); // Ensure date format "YYYY-MM-DD"
                const key = `${day.day} ${day.weekDay}`;

    
                const attendanceRecord = employee.attendance.find(record => record.date === dateKey.toString());
                baseObject[key] = attendanceRecord
                ? attendanceRecord.status !== null  
                    ? attendanceRecord.status === 'Holiday' 
                        ? 'hl' 
                        : attendanceRecord.status === 'Present' 
                            ? 'P' 
                            : attendanceRecord.status !== 'Absent' 
                                ? attendanceRecord.leaveFormat === 'FULLDAY' 
                                    ? attendanceRecord.leaveType === 'Excused' 
                                        ? 'ef' 
                                        : 'uf' 
                                    : attendanceRecord.leaveType === 'Excused' 
                                        ? 'eh' 
                                        : 'uh' 
                                : 'A' 
                    : '-'
                : '-'
            });
    
            baseObject["half day"] = employee.halfDayLeaves || '0';
            baseObject["full day"] = employee.fullDayLeaves || '0';
            baseObject["present"] = employee.totalPresentDays || '0';
            baseObject["working days"] = employee.workingDays || '0';
            baseObject["Total Leaves"] = employee.totalLeaves || '0';
            baseObject["Unpaid Leaves"] = employee.unpaidLeaves || '0';
            baseObject[`Carry forwards leaves (${getNextMonth(getMonthNumber(currentMonth))})`] = employee.nextMonthCarryLeaves || '0';
            baseObject[`Previous Carry leaves (${getPreviousMonth(getMonthNumber(currentMonth))})`] = employee.prevMonthCarryLeaves || '0';
    
            return baseObject;
        });
    };
    
    
    
    
    const jsonData = createJsonData();

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'data.xlsx');
    };

    const getMusteredStatus = (obj) => {
        return obj ? obj.status !== null ? obj.status === 'Holiday' ? 'hl' : (obj.status === 'Present' ? 'P' : (obj.status !== 'Absent') ? (obj.leaveFormat === 'FULLDAY' ? obj.leaveType === 'Excused' ? 'ef' : 'uf'  : obj.leaveType === 'Excused' ? 'eh' : 'uh') : 'A') : '-' : ''
    }   

    const getStatusTitle = (obj) => {
        return obj ?   obj?.status !== null ? obj?.status === 'Holiday' ? obj?.holidayTitle : (obj?.status === 'Present' ? 'Card scanned' : (obj?.status !== 'Absent') ? obj?.leaveReason : 'Card not scanned') : 'No attendence' : ''
    }

    const getStatusColor = (obj) => {
        return obj ? obj?.status ?  obj?.status === 'Holiday' ? 'rgba(38, 50, 56, 1)' : (obj?.status === 'Present' ? 'green' :  'red') : 'black' : ''
  
    }

    return (
        <div className='mustredMainContainer'>
            <div className='musteredHeader'>
                <div className='headingAttendence'>Attendences</div>
                <div className='searchInputAttend'>
                    <input type='text' placeholder='search...' onChange={(e) => handleSearchEmpTrns(e)} />
                </div>
            </div>
            <div className='viewContainer'>
                <div className='musterdBtnHolders'>
                <div className='label'>
                <svg onClick={navigatePrevMonth} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.19231 0L6 0.916667L1.73077 5.5L6 10.0833L5.19231 11L4.76837e-07 5.5L5.19231 0Z" fill="#FF8402" />
                </svg>
                &nbsp;
                <div className='label-container' onClick={() => setIsDateRoute(true)}>{`${currentMonth}, ${currentYear}`}</div>
                &nbsp;
                <svg onClick={navigateNextMonth} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.807692 0L0 0.916667L4.26923 5.5L0 10.0833L0.807692 11L6 5.5L0.807692 0Z" fill="#FF8402" />
                </svg>
                <div className='date-holder-dashboard month-dateRoute'>
                    {
                        isDateRoute ? (
                            <div className='calender-holder-dashboard_mustered' ref={mothDateRouteWrapper}>
                                <RouteCalender 
                                    value={routeDate} 
                                    inline 
                                    view='month' 
                                    unstyled={false} 
                                    onChange={handleDateRoute} 
                                />
                            </div>
                        ) : null
                    }
                </div>
            </div>
                    <div className='musteredButtonsHolder'>
                        <button style={{ background: '#FF8402', border: 'none', borderRadius: '3px', marginLeft: '20px', color: '#fff', paddingLeft: '10px', paddingRight: '10px', cursor: 'pointer'}} onClick={ () => {downloadExcel()}}>Download XLS</button>
                        <button onClick={() => {
                            const newDate = new Date();
                            setRouteDate(newDate);
                            setCurrentMonth(newDate.toLocaleString('default', { month: 'long' }));
                            setCurrentYear(newDate.getFullYear());
                        }} style={{ backgroundColor: ((currentMonth.toString() === new Date().toLocaleString('default', { month: 'long' }).toString() &&  currentYear.toString() ===  new Date().getFullYear().toString() )) ? 'none' : 'rgb(255, 246, 223)', borderRadius: '3px', marginLeft: '30px', border: '0.5px solid grey', width: 'max-content', paddingLeft: '10px', paddingRight: '10px', cursor: 'pointer' }}>Month</button>
                    </div>
                </div> 
                <div className='musteredTableUI'>
                    <div className='empNameHeadHolderMusterd'>
                        <div className='rowHeadingHolderMusterd'>
                            <div className='empHolders' style={{ width: '100px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#FFF6DF',}}>empId</div>
                            <div className='empNameHolders' style={{ width: '300px', height: '50px', background: 'red', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', background: '#FFF6DF', paddingLeft: '20px', position: 'sticky', left: '107px'}}>Name</div>
                            {dayArray && dayArray.length > 0 && dayArray.map((obj, index) => (
                                <div className='musteredateHolder' style={{background: '#fff'}}>
                                    <div className='musteredate' style={{ width: '70px', height: '100%', display: 'flex', flexDirection: 'column', cursor: 'pointer', background: (index % 2) === 0 ? 'rgb(255, 246, 223, 0.6)' : 'rgb(128 128 128 / 12%)' }} onClick={() => {
                                        // dispatch(setMusterdDate(obj.date))
                                    }}>
                                        <div style={{height: '50%', width: '100%'}}>{obj.day}</div>
                                        <div style={{height: '50%', width: '100%'}}>{obj.weekDay}</div>
                                    </div>
                                </div>

                            ))}
                            <div className='musteredate' style={{ width: '50px', height: '100%', display: 'flex', flexDirection: 'column', background: '#FFF6DF', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer' }} >
                                <div>Half day</div>
                            </div>

                            <div className='musteredate' style={{ width: '50px', height: '100%', display: 'flex', flexDirection: 'column', background: '#FFF6DF', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer' }} >
                                <div>Full day</div>
                            </div>

                            <div className='musteredate' style={{ width: '100px', height: '100%', display: 'flex', flexDirection: 'column', background: '#FFF6DF', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer', justifyContent:'center', alignItems: 'center'}} >
                                <div>Total Leaves</div>
                            </div>
                            
                            <div className='musteredate' style={{ width: '100px', height: '100%', display: 'flex', flexDirection: 'column', background: '#FFF6DF', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer', justifyContent:'center', alignItems: 'center'}} >
                                <div>Present</div>
                            </div>

                            <div className='musteredate' style={{ width: '100px', height: '100%', display: 'flex', flexDirection: 'column', background: '#FFF6DF', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer', justifyContent:'center', alignItems: 'center'}} >
                                <div>Working days</div>
                            </div>

                            <div className='musteredate' style={{ width: '100px', height: '100%', display: 'flex', flexDirection: 'column', background: '#FFF6DF', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer', justifyContent:'center', alignItems: 'center'}} >
                                <div>Unpaid Leaves</div>
                            </div>

                            <div className='musteredate' style={{ width: '180px', height: '100%', display: 'flex', flexDirection: 'column', background: '#FFF6DF', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer', justifyContent:'center', alignItems: 'center'}} >
                                <div>{`Carry forwards leaves (${getNextMonth(getMonthNumber(currentMonth))})` }</div>
                            </div>

                            <div className='musteredate' style={{ width: '180px', height: '100%', display: 'flex', flexDirection: 'column', background: '#FFF6DF', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer', justifyContent:'center', alignItems: 'center'}} >
                                <div>{`Previous Carry leaves (${getPreviousMonth(getMonthNumber(currentMonth))})`}</div>
                            </div>
                        </div>

            
                        {tempMusteredList && tempMusteredList.length > 0 && tempMusteredList.map((musteredObj, index) => (
                            <div className='rowHeadingHolder datarowHolder' style={{marginLeft: '5px'}}>
                                <div className='empHolders' style={{ width: '100px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{musteredObj.userId}</div>
                                <div className='empNameHolders' style={{ width: '300px', height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '20px', cursor: 'pointer', position: 'sticky', left: '107px', background: '#fff'}} onClick={() => {
                                    dispatch(setView('month'))
                                    dispatch(setAttendenceMontViewFlag(true))
                                    dispatch(setMusteredObj(musteredObj))
                                    dispatch(setAttendenceAnalyticsDate(routeDate))
                                }}>
                                    <div className="taskOwnerImage" >
                                        <img id="taskOwnerImage" src={musteredObj.empImg ? musteredObj.empImg : profile} alt="" />
                                    </div>
                                    <div className='nameHolder'>
                                        {musteredObj.empName}
                                    </div>
                                </div>
                                {musteredObj.attendance && musteredObj.attendance.length > 0 && musteredObj.attendance.map((obj, index) => (
                                        <div title={getStatusTitle(obj)} key={index} className='musteredate' style={{ width: '70px', height: '100%', display: 'flex', flexDirection: 'column', cursor: 'pointer',  background: (index % 2) === 0 ? 'rgb(255, 246, 223, 0.6)' : 'rgb(128 128 128 / 12%)', boxSizing: 'border-box', marginLeft: '0px'}}>
                                            <div style={{color: getStatusColor(obj), width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} title={obj.remark}>
                                                {getMusteredStatus(obj)}
                                            </div>
                                        </div>
                                ))}

                                <div className='musteredate' style={{ width: '50px', height: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer' }}>
                                    <div>{musteredObj.halfDayLeaves}</div>
                                </div>

                                <div className='musteredate' style={{ width: '50px', height: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer' }}>
                                    <div>{musteredObj.fullDayLeaves}</div>
                                </div>

                                <div className='musteredate' style={{ width: '100px', height: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer' }}>
                                    <div>{musteredObj.totalLeaves}</div>
                                </div>

                                <div className='musteredate' style={{ width: '100px', height: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer' }}>
                                    <div>{musteredObj.totalPresentDays}</div>
                                </div>

                                <div className='musteredate' style={{ width: '100px', height: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer' }}>
                                    <div>{musteredObj.workingDays}</div>
                                </div>



                                <div className='musteredate' style={{ width: '100px', height: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer' }}>
                                    <div>{musteredObj.unpaidLeaves}</div>
                                </div>

                                
                                <div className='musteredate' style={{ width: '180px', height: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer' }}>
                                    <div>{musteredObj.nextMonthCarryLeaves}</div>
                                </div>

                                <div className='musteredate' style={{ width: '180px', height: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer' }}>
                                    <div>{musteredObj.prevMonthCarryLeaves}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                        {
                            loader && <div class="loaderIdProjectsLoad">
                                <div class="mustered-loader"></div>``
                            </div>
                        }
                </div>
            </div>
        </div>
    )
}

export default AttendenceMustered