import React from 'react'
import './DenyPreAskOptn.css'
import { useDispatch, useSelector } from 'react-redux'
import { setrqtDenypopup, setPreAskOption, setDenyRqtObj, setReqObj } from '../../../erpStore/actions';
import axios from 'axios';

function DenyPreAskOptn() {
    const url = useSelector((state) => state.data.apiUrl)
    const self = useSelector((state) => state.data.selfData)
    const rqtObj = useSelector((state) => state.data.denyRequestObj)
    const reqIndex = useSelector((state) => state.data.reqRejectI)
    const dispatch = useDispatch()
    const requestObj = useSelector((state) => state.data.reqObj)
    return (
        <div className='denipop-mainContainer  denyAskpop-mainContainer' onClick={() => {
            dispatch(setPreAskOption(false))
        }}>
            <div className='card-deny-rt card-deny-rt-option' onClick={(e) => {
                e.stopPropagation()
            }}>
                <div className='denyReason'>
                    Do you want to add reason?
                </div>

                <div className='rqt-deny-btns'>
                    <button className='rqt-deny-btn-send' onClick={() => {
                        dispatch(setrqtDenypopup(true))
                        dispatch(setPreAskOption(false))
                    }}>Yes</button>
                    <button className='rqt-deny-btn-cancel' onClick={() => {
                        const stringHeaders = window.localStorage.getItem('myHeaders');
                        const storedHeaders = JSON.parse(stringHeaders);
                        axios.put(`${url}task/req/status/change`, { "requestId": rqtObj.requestId, "empId": self.empId, "statusId": 2, "requestRemark": null }, {
                            headers: storedHeaders
                        })
                            .then(response => {
                                if (response && response.status === 200) {
                                    const updatedReqObj = requestObj.filter(obj => obj.requestId !== reqIndex);
                                    dispatch(setReqObj(updatedReqObj))
                                }
                            })
                            .catch(error => {})
                        dispatch(setPreAskOption(false))
                    }}>Reject</button>
                </div>
            </div>
        </div>
    )
}

export default DenyPreAskOptn
