import React, { useState } from 'react'
import "./styleAddPhase.css"
import { setAddPhase } from '../../../../erpStore/actions'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';

function AddPhase() {
  const dispatch = useDispatch();
  const apiUrl = useSelector((state) => state.data.apiUrl)
  const stringHeaders = window.localStorage.getItem('myHeaders');
  const storedHeaders = JSON.parse(stringHeaders);
  const milestoneObj = useSelector((state) => state.data.milestoneObj)
  const [mObj, setMObj] = useState(
    {
      phaseName: "",
      phaseStartDate: "",
      phaseDescription: "",
      phaseMileStone: milestoneObj.milestoneId,
      phaseEndDate: "",
      phaseDays: ""
    }

  );
  const calculateDays = (start, end) => {
    var startDate = new Date(start);
    var endDate = new Date(end);

    var difference = endDate - startDate;
    var daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));
    if (daysDifference < 0) {
      return "0";
    }
    setMObj({
      ...mObj,
      phaseDays: daysDifference + ""
    })
  }
  const submiMilestone = () => {
    if (mObj.phaseName === "") {
      toast.error('Please Input valid Phase name', { autoClose: 2000 })
    } else if (mObj.phaseStartDate === "") {
      toast.error('Please Enter valid start date', { autoClose: 2000 })
    } else if (mObj.phaseEndDate === "") {
      toast.error('Please Enter valid end date', { autoClose: 2000 })
    } else if (mObj.phaseDays === "") {
      toast.error('Enter valid days', { autoClose: 2000 })
    }
    axios.post(`${apiUrl}project/phase/add`, mObj, {
      headers: storedHeaders
    })
      .then((response) => {
        if (response.data) {
          dispatch(setAddPhase("none"))
          toast.success('Added Successfully', { autoClose: 2000 })
        }
      })
      .catch((error) => {
        toast.error('try again', { autoClose: 2000 })
      })
  }
  return (
    <div className='add-phase-form-container'>
      <div className='add-phase-form'>
        <div className='add-phase-heading'>Add Phase</div>
        <div className='phase-form'>
          <input className='phase-header'
            value={mObj.phaseName}
            onChange={(event) => setMObj((prev) => ({ ...prev, phaseName: event.target.value }))}
            type='text' placeholder='Enter Phase Name' />
          <div className='phase-description'>
            <label>Description</label>
            <textarea value={mObj.phaseDescription


            }
              onChange={(event) => setMObj((prev) => ({ ...prev, phaseDescription: event.target.value }))}></textarea>
          </div>
          <div className='phase-dates'>
            <div className='phase-date-part'>
              <label>Start Date</label>
              <input type='date'
                value={mObj.phaseStartDate}
                onChange={(event) => setMObj((prev) => ({ ...prev, phaseStartDate: event.target.value }))}
                placeholder='DD-MM-YYYY'></input>
            </div>
            <div className='phase-date-part'>
              <label>End Date</label>
              <input type='date'
                value={mObj.phaseEndDate}
                onChange={(event) => {
                  const endDate = event.target.value;
                  calculateDays(mObj.phaseStartDate, endDate);
                  setMObj((prev) => ({ ...prev, phaseEndDate: endDate }));
                }}
                placeholder='DD-MM-YYYY'></input>
            </div>
          </div>
          <div className='phase-day-count'>
            <label>Number of days</label>
            <input type='number'
              value={mObj.phaseDays}
              onChange={(event) => {
                const strVal = event.target.value + "";
                if (strVal.length > 3) {
                  return
                }
                setMObj((prev) => ({ ...prev, phaseDays: event.target.value }))
              }} disabled={true}
            ></input>
          </div>
        </div>
        <div className='add-phase-buttons'>
          <div className='add-phase-button' onClick={submiMilestone}>Add Phase</div>
          <div className='cancel-phase-button' onClick={() => {
            dispatch(
              setAddPhase("none"))
          }}>Cancel</div>
        </div>
      </div>
    </div>
  )
}

export default AddPhase
