import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyClo6gGfyaOx2GeQippoP2lsrT_IwQmWbA",
    authDomain: "erp-attachments.firebaseapp.com",
    projectId: "erp-attachments",
    storageBucket: "erp-attachments.appspot.com",
    messagingSenderId: "554376067568",
    appId: "1:554376067568:web:2b4ffd0fc21dea75ecc535",
    measurementId: "G-3J85XZMWRH"
  };

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app) 
