import React, { useEffect, useRef, useState } from 'react'
import './Notification.css'
import { setNotifications, setUnRdNotiCount } from '../../../erpStore/actions';
import { useDispatch, useSelector } from 'react-redux';
import axios, { all } from 'axios';
import shadows from '@material-ui/core/styles/shadows';


function Notification() {

  const stringHeaders = window.localStorage.getItem('myHeaders');
  const storedHeaders = JSON.parse(stringHeaders);
  const dispatch = useDispatch()
  const self = useSelector((state) => state.data.selfData)
  const url = useSelector((state) => state.data.apiUrl);
  const [allNotification, setAllNotification] = useState([]);
  const [markreadContainer, setMarkReadContainer] = useState(false);
  useEffect(() => {
    axios.get(`${url}notification/logs/get`, { headers: storedHeaders })
      .then(response => {
        setAllNotification(response.data)
      })
      .catch(error =>{})
  }, [])


  const timeCalculator = (lastUpdated) => {
    const parsedDate = new Date(lastUpdated);
    const formattedDate = `${parsedDate.getUTCFullYear()}-${(parsedDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getUTCDate().toString().padStart(2, '0')}T${parsedDate.getUTCHours().toString().padStart(2, '0')}:${parsedDate.getUTCMinutes().toString().padStart(2, '0')}:${parsedDate.getUTCSeconds().toString().padStart(2, '0')}Z`;
    const currentDateTime = new Date();
    const lastUpdatedDateTime = new Date(formattedDate);
    const timeDifference = (currentDateTime - lastUpdatedDateTime) / 1000;

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800;
    const secondsInYear = 31557600;

    if (timeDifference >= secondsInYear) {
      const yearsAgo = Math.floor(timeDifference / secondsInYear);
      return `${yearsAgo} ${yearsAgo === 1 ? 'yr' : 'yrs'} ago`;
    } else if (timeDifference >= secondsInMonth) {
      const monthsAgo = Math.floor(timeDifference / secondsInMonth);
      return `${monthsAgo} ${monthsAgo === 1 ? 'mth' : 'mths'} ago`;
    } else if (timeDifference >= secondsInWeek) {
      const weeksAgo = Math.floor(timeDifference / secondsInWeek);
      return `${weeksAgo} ${weeksAgo === 1 ? 'wk' : 'wks'} ago`;
    } else if (timeDifference >= secondsInDay) {
      const daysAgo = Math.floor(timeDifference / secondsInDay);
      return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
    } else if (timeDifference >= secondsInHour) {
      const hoursAgo = Math.floor(timeDifference / secondsInHour);
      return `${hoursAgo} ${hoursAgo === 1 ? 'hr' : 'hrs'} ago`;
    } else {
      const minutesAgo = Math.floor(timeDifference / secondsInMinute);
      return `${minutesAgo} ${minutesAgo === 1 ? 'min' : 'min'} ago`;
    }
  };
  const readWrapper = useRef(null)
  useEffect(() => {
    function handleOutsideClick(event) {
      if (readWrapper && readWrapper.current && !readWrapper.current.contains(event.target)) {
        setMarkReadContainer(false)
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])


  const handleMarked = async () => {
    try {
      const response = await axios.put(`${url}notification/logs/all/read?userId=${self.userId}`, {}, {
        headers: storedHeaders
      })
      if (response.request.status === 200) {
        const updatedNotifications = [...allNotification];
        const updatedObj = updatedNotifications && updatedNotifications.map(obj => ({ ...obj, notificationReadStatus: "true" }));
        setAllNotification(updatedObj);
        dispatch(setUnRdNotiCount(0))
        setMarkReadContainer(false)
      }
    } catch (err) {
      setMarkReadContainer(false)
    }
  }

  useEffect(() => {
    setAllNotification(allNotification)
  }, [allNotification])
  const notifactionCont = useSelector((state) => state.data.unRdNotiCount)
  return (
    <div className='Notification_Main_Container' onClick={() => {
      const unloadElement = document.getElementById('unLoadNotifications');
      if (unloadElement) {
        unloadElement.classList.add('fade-out');
      }
      setTimeout(function () {
        dispatch(setNotifications(false))
      }, 1000)
      // dispatch(setNotifications(false))
    }}>
      <div className='Notification_Float' >
        <div className='Notification_Sub_Container' onClick={(e) => {
          e.stopPropagation()
        }} id="unLoadNotifications">

          <div className='notifications-heading-container'>
            <h1 className='notification_heading'>Notifications</h1>
            <div className='mark-all-as-read' onClick={() => setMarkReadContainer(true)} ref={readWrapper}><svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="1.5898" cy="2.25395" r="1.57027" transform="rotate(-90 1.5898 2.25395)" fill="#263238" fill-opacity="0.7" />
              <circle cx="7.86715" cy="2.25395" r="1.57027" transform="rotate(-90 7.86715 2.25395)" fill="#263238" fill-opacity="0.7" />
              <circle cx="14.1523" cy="2.25395" r="1.57027" transform="rotate(-90 14.1523 2.25395)" fill="#263238" fill-opacity="0.7" />
            </svg>
              {
                markreadContainer ? <div className='markRead' onClick={handleMarked}> <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.825 3.15435C12.94 3.03249 13.0025 2.8713 12.9996 2.70476C12.9966 2.53822 12.9283 2.37931 12.809 2.26153C12.6898 2.14375 12.5289 2.07628 12.3603 2.07334C12.1917 2.0704 12.0285 2.13222 11.9052 2.24578L7.08223 7.00892L5.45694 5.34949C5.39764 5.28829 5.32668 5.23926 5.24813 5.20524C5.16959 5.17122 5.08501 5.15286 4.99926 5.15123C4.91351 5.1496 4.82828 5.16473 4.74847 5.19575C4.66866 5.22677 4.59585 5.27306 4.53421 5.33196C4.47257 5.39087 4.42333 5.46123 4.38932 5.539C4.3553 5.61677 4.33718 5.70041 4.336 5.78512C4.33482 5.86983 4.3506 5.95393 4.38243 6.03259C4.41427 6.11126 4.46153 6.18293 4.5215 6.24349L6.6067 8.37263C6.6667 8.4341 6.73849 8.48314 6.81789 8.5169C6.89728 8.55066 6.98269 8.56846 7.06913 8.56926C7.15556 8.57006 7.24129 8.55384 7.32132 8.52155C7.40134 8.48926 7.47404 8.44155 7.53519 8.38121L12.825 3.15435Z" fill="#FF8402" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.7355 10.7143C1.62043 10.7143 1.51007 10.6691 1.4287 10.5888C1.34734 10.5084 1.30162 10.3994 1.30162 10.2857V1.71429C1.30162 1.60062 1.34734 1.49161 1.4287 1.41124C1.51007 1.33087 1.62043 1.28571 1.7355 1.28571H9.76218C9.93478 1.28571 10.1003 1.21799 10.2224 1.09743C10.3444 0.976867 10.413 0.813353 10.413 0.642857C10.413 0.472361 10.3444 0.308848 10.2224 0.188289C10.1003 0.0677294 9.93478 2.54059e-09 9.76218 0H1.7355C1.27522 0 0.833785 0.180612 0.508316 0.502103C0.182847 0.823594 0 1.25963 0 1.71429V10.2857C0 10.7404 0.182847 11.1764 0.508316 11.4979C0.833785 11.8194 1.27522 12 1.7355 12H10.413C10.8733 12 11.3147 11.8194 11.6402 11.4979C11.9656 11.1764 12.1485 10.7404 12.1485 10.2857V7.5C12.1485 7.3295 12.0799 7.16599 11.9579 7.04543C11.8358 6.92487 11.6703 6.85714 11.4977 6.85714C11.3251 6.85714 11.1595 6.92487 11.0375 7.04543C10.9154 7.16599 10.8469 7.3295 10.8469 7.5V10.2857C10.8469 10.3994 10.8012 10.5084 10.7198 10.5888C10.6384 10.6691 10.5281 10.7143 10.413 10.7143H1.7355Z" fill="#FFC180" />
                </svg>
                  Mark all as read</div> : null
              }
            </div>
          </div>

          <div className='Notification_List_Container'>
            <div className='notification_List_View'>
              {allNotification && allNotification.length > 0 && allNotification.map((obj, index) => (
                <div className='notification_card' style={{ backgroundColor: obj.notificationReadStatus === 'false' ? null : "rgba(244, 244, 245, 1)", boxShadow: obj.notificationReadStatus === 'false' ? "1px 1px 12px 0px #0000001a" : null }} onClick={() => {
                  if (obj.notificationReadStatus === 'false') {
                    axios.put(`${url}notification/logs/update/read`, { "notificationId": obj.notificationId, "userId": self.userId }, { headers: storedHeaders })
                      .then(response => {
                        if (response.request.status === 200) {
                          obj.notificationReadStatus = 'true'
                          const updatedNotifications = [...allNotification];
                          updatedNotifications[index] = obj;
                          setAllNotification(updatedNotifications);
                          dispatch(setUnRdNotiCount(notifactionCont - 1))
                        }
                      })
                      .catch(error =>{})

                  }
                }}>
                  <div className='Notification_Card_Administartion_heading'>
                    <div className='Notification_img_and_Admin'>
                      <img style={{ borderRadius: '50px' }} src={obj.notificationSendersImage} alt='/' />
                      <p >{obj.notificationFrom}</p>
                    </div>
                    <div className='notification-time' href="#" style={{ color: 'rgb(128 133 136)' }} title={obj.notificationTime}>{timeCalculator(`${obj.notificationTime}`)}{obj.notificationReadStatus === 'false' ? <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="4.5" cy="4.5" r="4.5" fill="#EC0000" />
                    </svg> : null
                    }
                    </div>
                  </div>
                  <div className='notification_subject'>
                    <h4 style={{ color: obj.notificationReadStatus === 'true' ? "#263238de" : null }}>{obj.notificationText}</h4>
                    <p >{obj.detail}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notification