// actionTypes.js
export const SET_API_URL = 'SET_API_URL';
export const SET_DATA_OBJECT = 'SET_DATA_OBJECT';
export const SET_INITIAL_DATA_OBJECT = "SET_INITIAL_DATA_OBJECT";
export const SET_RESET_OBJECT = 'SET_RESET_OBJECT'
export const SET_VIEW_OBJECT = 'SET_VIEW_OBJECT';
export const SET_VIEW_ADDTASK = 'SET_VIEW_ADDTASK';
export const SET_FULLDATE = 'SET_FULLDATE';
export const SET_NEWTASK = 'SET_NEWTASK';
export const SET_SELF = 'SET_SELF';
export const SET_DATE = 'SET_DATE';
export const SET_RESET = 'SET_RESET';
export const SET_EMPINFO = "SET_EMPINFO";
export const SET_EMPVIEW = "EMP_VIEW";
export const SET_HEADER = "SET_HEADER";
export const SET_EDITFORM = "SET_EDITFORM";
export const SET_CLOSURE = 'SET_CLOSURE';
export const SET_ADDEMP = 'SET_ADDEMP';
export const SET_ROUTE = 'SET_ROUTE';
export const SET_ADDPROJECT = 'SET_ADDPROJECT';
export const SET_PROJECTLIST = 'SET_PROJECTLIST';
export const SET_ADD_PHASE = 'SET_ADD_PHASE';
export const SET_ADD_MILESTONE = 'SET_ADD_MILESTONE';
export const SET_ADD_SPRINT = 'SET_ADD_SPRINT';
export const SET_SEARCH_FOR_MONTH = 'SET_SEARCH_FOR_MONTH'
export const SET_GETLOGS = 'SET_GETLOGS';
export const SET_PROJECT = 'SET_PROJECT';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_REQUEST_OBJECT = 'SET_REQUEST_OBJECT';
export const SET_MILESTONEOBJ = 'SET_MILESTONEOBJ';
export const SET_ADDMEETING = 'SET_ADDMEETING';
export const SET_PROJECTTASKFLOW = 'SET_PROJECTTASKFLOW';;
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_REMINDERS = 'SET_REMINDERS';
export const SET_MESSAGE = 'SET-MESSAGE';
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_MTIID = 'SET_MTIID';
export const SET_UPDATEEMP = "SET_UPDATEEMP";
export const SET_EDITEKEY = 'SET_EDITEKEY';
export const SET_DEFAULT_TAG = 'SET_DEFAULT_TAG'
export const SET_RANDOMLINK = 'SET_RANDOMLINK';
export const SET_RQTDENYPOPUP = 'SET_RQTDENYPOPUP';
export const SET_RQTDENYOBJ = ' SET_RQTDENYOBJ'
export const SET_PREASkOPTN = 'SET_PREASkOPTN';
export const SET_ALLEMPLOYEE = 'SET_ALLEMPLOYEE';
export const SET_SEARCHEDDATE = 'SET_SEARCHEDDATE';
export const SET_SEARCHEDTASKMONTH = 'SET_SEARCHEDTASKMONTH';
export const SET_LEVELSEARCH = 'SET_LEVELSEARCH';
export const SET_SETTING = 'SET_SETTING';
export const SET_SETTINGVIEW = 'SET_SETTINGVIEW';
export const SET_CHANGEPASS = 'SET_CHANGEPASS';
export const SET_LOADER = 'SET_LOADER';
export const SET_ALL_EMPLOYEE = 'SET_ALL_EMPLOYEE';
export const SET_WEEKDATA = 'SET_WEEKDATA';
export const SET_ALL_DISPATCH_TASK = 'SET_ALL_DISPATCH_TASK'
export const SET_STATUSLIST = 'SET_STATUSLIST';
export const SET_ISEDITSTATUS = 'SET_ISEDITSTATUS';
export const SET_USEDLIST = 'SET_USEDLIST';
export const SET_UNUSEDLIST = 'SET_UNUSEDLIST';
export const SET_PRIORITYLIST = 'SET_PRIORITYLIST'
export const SET_PROJECTLISTUPDATE = 'SET_PROJECTLISTUPDATE'
export const SET_TAGSLIST = 'SET_TAGSLIST'
export const SET_ASSINEELIST = 'SET_ASSINEELIST'
export const SET_VERIFIEdLIST = 'SET_VERIFIEdLIST';
export const SET_PRIORITYCOLORLIST = 'SET_PRIORITYCOLORLIST';
export const SET_ISEDITPRIORITY = "SET_ISEDITPRIORITY";
export const SET_ISEDITDEPARTMENT = "SET_ISEDITDEPARTMENT";
export const SET_DEPARTMENTCOLORLIST = "SET_DEPARTMENTCOLORLIST";
export const SET_ISEDITVERIFICATION = "SET_ISEDITVERIFICATION";
export const SET_VERIFICATIONCOLORLIST = "SET_VERIFICATIONCOLORLIST";
export const SET_UNRDNOTICOUNT = "SET_UNRDNOTICOUNT";
export const SET_REQOBJ = "SET_REQOBJ";
export const SET_REQREJECTI = "SET_REQREJECTI";
export const SET_TASKDTLOBJ = 'SET_TASKDTLOBJ';
export const SET_TASKDTLFLAG = 'SET_TASKDTLFLAG';
export const SET_MONTHDATA = 'SET_MONTHDATA';
export const SET_EMPLOADMORE = 'SET_EMPLOADMORE';
export const SET_CLOSUREPROCEDURE = 'SET_CLOSUREPROCEDURE';
export const SET_ISCLOSUREKEY = 'SET_CLOSUREKEY';
export const SET_ANALYTICSDATA = 'SET_ANALYTICSDATA';
export const SET_ANALYTICSEMP = 'SET_ANALYTICSEMP';
export const SET_ANALYTICSEMPLIST = 'SET_ANALYTICSEMPLIST';
export const SET_DESIGNATIONLIST = 'SET_DESIGNATIONLIST';
export const SET_ISEDITDESIGNATION = 'SET_ISEDITDESIGNATION';
export const SET_ISDETAILANALYTICS = 'SET_ISDETAILANALYTICS';
export const SET_ANALYTICSSTARTDATE = "SET_ANALYTICSSTARTDATE";
export const SET_ANALYTICSENDDATE = "SET_ANALYTICSENDDATE";
export const SET_LEVELINFOOBJECT = 'SET_LEVELINFOOBJECT';
export const SET_UPDATEPRJFLAG = 'SET_UPDATEPRJFLAG';
export const SET_REQUESTCOUNT = 'SET_REQUESTCOUNT';;
export const SET_UPDATE_PROJECT = 'SET_UPDATE_PROJECT';
export const SET_TASKEMP = 'SET_TASKEMP';
export const SET_INITIALSTATE = 'SET_INITIALSTATE';
export const SET_EMPJOININGDATE = 'SET_EMPJOININGDATE';
export const SET_REQCOUNTDATA = 'SET_REQCOUNTDATA';
export const SET_PRJUPDATEFLAG = "SET_PRJUPDATEFLAG";
export const SET_INFOSHOW = "SET_INFOSHOW";
export const SET_ANALYTICSTYPE = 'SET_ANALYTICSTYPE'
export const SET_PRJALLTSKFLAG = 'SET_PRJALLTSKFLAG';
export const SET_ISCOMMENTVIEW = 'SET_ISCOMMENTVIEW';
export const SET_LEAVECLANEDERVIEWFLAG = 'SET_LEAVECLANEDERVIEWFLAG';
export const SET_ADDHOLIDAYFLAG = 'SET_ADDHOLIDAYFLAG';
export const SET_ADDLEAVEFLAG = 'SET_ADDLEAVEFLAG';
export const SET_ADDEDITEFLAG = 'SET_ADDEDITEFLAG';
export const SET_LEAVEDAYVIEWFLAG = 'SET_LEAVEDAYVIEWFLAG';
export const SET_SCANNERVIEWFLAG = 'SET_SCANNERVIEWFLAG';
export const SET_ATTENDENCEMONTHVIEWFLAG = 'SET_ATTENDENCEMONTHVIEWFLAG';
export const SET_MYLEAVESTATUSFLAG = 'SET_MYLEAVESTATUSFLAG';
export const SET_SCANNERATTENDENCELIST = 'SET_SCANNERATTENDENCELIST'
export const SET_ATTENDMUSTEREDFLAG = 'SET_ATTENDMUSTEREDFLAG';
export const SET_ONLEAVEDATE = 'SET_ONLEAVEDATE';
export const SET_UPDATELEAVEOBJ = 'SET_UPDATELEAVEOBJ';
export const SET_EDITELEAVEFLAG = 'SET_EDITELEAVEFLAG'
export const SET_HOLIDAYLISTFLAG = 'SET_HOLIDAYLISTFLAG';
export const SET_EDITEHOLIDAY = 'SET_EDITEHOLIDAY'
export const SET_HOLIDAYCRUDOBJ = 'SET_HOLIDAYCRUDOBJ'
export const SET_MUSTEREDDATE = 'SET_MUSTEREDDATE'
export const SET_MUSTEREDOBJ = 'SET_MUSTEREDOBJ'
export const SET_ISHOLIDAYSTATUS= 'SET_ISHOLIDAYSTATUS';
export const SET_EMPISLEAVESTATUS = 'SET_EMPISLEAVESTATUS';
export const SET_ATTANALAYTICSDATE = 'SET_ATTANALAYTICSDATE';
export const SET_LEAVELIST = 'SET_LEAVELIST';
export const SET_LEAVEMANAGEHOLIDAYDAY = 'SET_LEAVEMANAGEHOLIDAYDAY'
export const SET_ISPRESENTDAY = 'SET_ISPRESENTDAY';
export const SET_LEVELADDTASKFLAG = 'SET_LEVELADDTASKFLAG';
export const SET_MONTHADDTASKFLAG = 'SET_MONTHADDTASKFLAG';
export const SET_WEEKADDTASKFLAG = 'SET_WEEKADDTASKFLAG';
export const SET_STOREDATEARRAY = 'SET_STOREDATEARRAY';