import React, { useEffect, useState } from 'react';
import "./styleAnalytics.css";
import { Doughnut, Line } from 'react-chartjs-2';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setAnalyticsData } from '../../../erpStore/actions';
import { useNavigate } from 'react-router';

function Analytics() {
    const analyticsEmp = useSelector((state) => state.data.analyticsEmp)
    const startDate = useSelector((state) => state.data.analyticsStartDate)
    const endDate = useSelector((state) => state.data.analyticsEndDate)
    const apiUrl = useSelector((state) => state.data.apiUrl)
    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const analyticsDataGlobal = useSelector((state) => state.data.analyticsData)
    const analyticsData = useSelector((state) => state.data.analyticsData)
    const selfInfo = useSelector((state) => state.data.selfData)
    const header = useSelector((state) => state.data.header);
    const analyticsType = useSelector((state) => state.data.analyticsType)
    const [loader, setLoader] = useState(false)
    
    useEffect(() => {
        const fetch = async () => {
            try {
                const resp = await axios.post(`${apiUrl}emp/analytics/get-single`, {
                    userId: analyticsEmp,
                    startDate: startDate,
                    endDate: endDate,
                    type: analyticsType
                }, {
                    headers: storedHeaders
                })
                if (resp && resp.data) {
                    dispatch(setAnalyticsData(resp.data))
                }
            } catch (err) {
                if (err && err.response && err.response.status === 500) {
                    navigate("/server-error")
                }
            }
        }

        fetch();
    }, [])
    useEffect(() => {
        setAnalyticsData(analyticsDataGlobal)
    }, [analyticsDataGlobal])

    //for overall
    const [radialData, setRadialData] = useState({
        series: [0], // Series data
        options: {
            chart: {
                type: 'radialBar',
                background: '#ffffff', // Set the background color here
                height: 50, // Set the height dynamically
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '40%',
                        color: "#ffffff"
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: -10,
                            show: false,
                            color: '#888',
                            fontSize: '12px',
                        },
                        value: {
                            color: '#111',
                            fontSize: '12px',
                            offsetY: 5,
                            show: true,
                            formatter: function (val) {
                                return val + '%';
                            },
                        },
                    },
                    // track: {
                    //     background: "#ffffff",
                    //     strokeWidth: '97%',
                    //     margin: 5, // margin is in pixels
                    // },

                },

            }, fill: {
                opacity: 1.5,
                colors: ['rgba(255, 132, 2, 1)'],
            },

            labels: ['Progress'], // Labels for each data point
        },
    });
    useEffect(() => {
        let performanceRate = analyticsData ? parseInt(((analyticsData.summary.completedOnTime + analyticsData.summary.completedOverDue) / analyticsData.summary.totalTask) * 100) : 0
        if (isNaN(performanceRate) || !performanceRate) {
            performanceRate = 0
        }
        setRadialData({
            series: [performanceRate], // Series data
            options: {
                chart: {
                    type: 'radialBar',
                    background: '#ffffff', // Set the background color here
                    height: 50, // Set the height dynamically
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: '40%',
                            color: "#ffffff"
                        },
                        dataLabels: {
                            showOn: 'always',
                            name: {
                                offsetY: -10,
                                show: false,
                                color: '#888',
                                fontSize: '12px',
                            },
                            value: {
                                color: '#111',
                                fontSize: '12px',
                                offsetY: 5,
                                show: true,
                                formatter: function (val) {
                                    return val + '%';
                                },
                            },
                        },
                        // track: {
                        //     background: "#ffffff",
                        //     strokeWidth: '97%',
                        //     margin: 5, // margin is in pixels
                        // },

                    },

                }, fill: {
                    opacity: 1.5,
                    colors: ['rgba(255, 132, 2, 1)'],
                },

                labels: ['Progress'], // Labels for each data point
            },
        })
    }, [analyticsData])
    const data = {
        labels: ["Assign Tasks", "Self Assign Tasks"],
        datasets: [
            {
                data: [analyticsData && analyticsData.summary.assignTask, analyticsData && analyticsData.summary.selfAssignTask],
                backgroundColor: [
                    "rgba(132, 151, 223, 1)",
                    "rgba(227, 229, 248, 1)",
                ],
                hoverBackgroundColor: ["rgba(132, 151, 223, 1)", "rgba(227, 229, 248, 1)"]
            }
        ],

        plugins: {
            labels: {
                render: "percentage",
                fontColor: ["green", "white", "red"],
                precision: 2
            },
        },
        text: "23%",
    };

    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [tasksMonths, setTasksMonths] = useState([]);

    useEffect(() => {
        if (analyticsData) {
            const newData1 = [];
            const newData2 = [];
            const newTaskMonths = [];
            analyticsData.totalTask.forEach(item => {
                newData1.push(item.assignedTask);
                newData2.push(item.selfAssignedTask);
                newTaskMonths.push(item.month);
            });
            setData1(newData1);
            setData2(newData2);
            setTasksMonths(newTaskMonths)
        }
    }, [analyticsData]);

    const barData = {
        labels: tasksMonths,
        datasets: [
            {
                label: 'Assigned Task',
                data: data1,
                backgroundColor: "rgba(132, 151, 223, 1)",
                borderColor: 'rgba(132, 151, 223, 1)',
                borderWidth: 1,
                cornerRadius: 20
            },
            {
                label: 'Self Assigned Task',
                data: data2,
                backgroundColor: "rgba(227, 229, 248, 1)",
                borderColor: 'rgba(227, 229, 248, 1)',
                borderWidth: 1,
                borderRadius: 20,
                cornerRadius: 20
            },
        ],
    };
    // Adjust options as needed
    const barOptions = {
        scales: {
            xAxes: [{
                barPercentage: 1.0, // Set bar percentage to 1 to remove gaps between bars
                // categoryPercentage: 1.0, // Set category percentage to 1 to remove gaps between bars
            }],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        stepSize: 1
                    },
                },
            ],
        },

        cornerRadius: 20, // Set corner radius for bars
        legend: { display: false, position: "right" },
        bar: {
            borderRadius: 500, // Adjust this value to change the roundness of the corners
        }
    };

    const [completedData1, setCompletedData1] = useState([]);
    const [completedData2, setCompletedData2] = useState([]);
    const [lineLabels, setLineLabels] = useState([])
    useEffect(() => {
        if (analyticsData) {
            const newData1 = [];
            const newData2 = [];
            const newLineLables = [];
            analyticsData.completedList.forEach(item => {
                newData1.push(item.onTimeTask);
                newData2.push(item.overDueTask);
                newLineLables.push(item.month);
            });
            setCompletedData1(newData1);
            setCompletedData2(newData2);
            setLineLabels(newLineLables)
        }
    }, [analyticsData]);




    const lineData = {
        labels: lineLabels,
        datasets: [
            {
                label: 'On Time',
                data: completedData1,
                fill: false,
                borderColor: 'rgba(132, 151, 223, 1)',
                tension: 0.1
            },
            {
                label: 'Overdue',
                data: completedData2, // Double the data points
                fill: false,
                borderColor: 'rgb(248, 135, 149)', // Adjust the color as needed
                tension: 0.1
            }
        ]
    };

    const completedData = {
        labels: ["On Time", "OverDue"],
        datasets: [
            {
                data: [analyticsData && analyticsData.summary.completedOnTime, analyticsData && analyticsData.summary.completedOverDue],
                backgroundColor: [
                    "rgba(132, 151, 223, 1)",
                    "rgb(248, 135, 149)",
                ],
                hoverBackgroundColor: ["rgba(132, 151, 223, 1)", "rgb(248, 135, 149)"]
            }
        ],

        plugins: {
            labels: {
                render: "percentage",
                fontColor: ["green", "white", "red"],
                precision: 2
            },
        },
        text: "23%",
    };



    const [deletedData1, setDeletedData1] = useState([]);
    const [shiftedData2, setShiftedData2] = useState([]);
    const [pendingMonths, setPendingMonths] = useState([]);

    useEffect(() => {
        if (analyticsData) {
            setDeletedData1(prevDeletedData1 => {
                const newData1 = [];
                analyticsData.deletedShifted.forEach(item => {
                    newData1.push(item.deletedTask);
                });
                return newData1;
            });
            setShiftedData2(prevShiftedData2 => {
                const newData2 = [];
                analyticsData.deletedShifted.forEach(item => {
                    newData2.push(item.shiftedTask);
                });
                return newData2;
            });
            setPendingMonths(prevPendingMonths => {
                const newData3 = [];
                analyticsData.deletedShifted.forEach(item => {
                    newData3.push(item.month);
                });
                return newData3;
            });
        }
        setChartData({
            series: [
                {
                    name: 'Deleted Tasks',
                    data: deletedData1,
                    color: 'rgba(254, 172, 167, 1)',
                    hoverColor: 'rgba(254, 172, 167, 0.7)'
                },
                {
                    name: 'Shifted Tasks',
                    data: shiftedData2,
                    color: 'rgba(106, 209, 203, 1)',
                    hoverColor: 'rgba(106, 209, 203, 0.7)'
                }
            ],
            options: {
                chart: {
                    type: 'rangeBar',
                    height: 350
                },
                dataLabels: {
                    enabled: false,
                },
                yaxis: {
                    title: {
                        text: 'Growth',
                    },
                    labels: {
                        formatter: function (y) {
                            return y.toFixed(0);
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        isDumbbell: true,
                        columnWidth: 3,
                        dumbbellColors: [['#008FFB', '#00E396']]
                    }
                },

                xaxis: {
                    type: 'category',
                    categories: pendingMonths,
                    labels: {
                        rotate: -90
                    }

                }
            }
        })
    }, [analyticsData]);



    const [chartData, setChartData] = useState({
        series: [
            {
                name: 'Deleted Tasks',
                data: deletedData1,
                color: 'rgba(254, 172, 167, 1)',
                hoverColor: 'rgba(254, 172, 167, 0.7)'
            },
            {
                name: 'Shifted Tasks',
                data: shiftedData2,
                color: 'rgba(106, 209, 203, 1)',
                hoverColor: 'rgba(106, 209, 203, 0.7)'
            }
        ],
        options: {
            chart: {
                type: 'rangeBar',
                height: 350
            },
            dataLabels: {
                enabled: false,
            },
            yaxis: {
                title: {
                    text: 'Growth',
                },
                labels: {
                    formatter: function (y) {
                        return y.toFixed(0);
                    }
                }
            },
            plotOptions: {
                bar: {
                    isDumbbell: true,
                    columnWidth: 10,
                    dumbbellColors: [['#008FFB', '#00E396']],


                }
            },

            xaxis: {
                type: 'category',
                categories: pendingMonths,
                labels: {
                    rotate: -90
                }

            }
        }
    });
    useEffect(() => {
        setChartData({
            series: [
                {
                    name: 'Deleted Tasks',
                    data: deletedData1,
                    color: 'rgba(254, 172, 167, 1)',
                    hoverColor: 'rgba(254, 172, 167, 0.7)'
                },
                {
                    name: 'Shifted Tasks',
                    data: shiftedData2,
                    color: 'rgba(106, 209, 203, 1)',
                    hoverColor: 'rgba(106, 209, 203, 0.7)'
                }
            ],
            options: {
                chart: {
                    type: 'rangeBar',
                    height: 350
                },
                dataLabels: {
                    enabled: false,
                },
                yaxis: {
                    title: {
                        text: 'Growth',
                    },
                    labels: {
                        formatter: function (y) {
                            return y.toFixed(0);
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        isDumbbell: true,
                        columnWidth: 10,
                        dumbbellColors: [['#008FFB', '#00E396']],


                    }
                },

                xaxis: {
                    type: 'category',
                    categories: pendingMonths,
                    labels: {
                        rotate: -90
                    }

                }
            }
        })
    }, [shiftedData2]);

    const [pendingRadial, setPendingRadial] = useState({
        series: [analyticsData && isNaN(parseInt((analyticsData.summary.pendingTask / analyticsData.summary.totalTask) * 100)) ? parseInt((analyticsData.summary.pendingTask / analyticsData.summary.totalTask) * 100) : 0], // Series data
        options: {
            chart: {
                type: 'radialBar',
                background: '#ffffff', // Set the background color here
                height: 200, // Set the height dynamically
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '50%',
                        color: "#ffffff"
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: -10,
                            show: false,
                            color: '#888',
                            fontSize: '12px',
                        },
                        value: {
                            color: '#111',
                            fontSize: '12px',
                            offsetY: 5,
                            show: false,
                            formatter: function (val) {
                                return val + '%';
                            },
                        },
                    },
                    track: {
                        background: "rgba(199, 237, 233, 1)",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels
                    },

                },

            }, fill: {
                opacity: 1.5,
                colors: ['rgba(106, 209, 203, 1)'],
            },

            labels: ['Progress'], // Labels for each data point
        },
    });

    useEffect(() => {
        setPendingRadial({
            series: [analyticsData && !isNaN(parseInt((analyticsData.summary.pendingTask / analyticsData.summary.totalTask) * 100)) ? parseInt((analyticsData.summary.pendingTask / analyticsData.summary.totalTask) * 100) : 0], // Series data
            options: {
                chart: {
                    type: 'radialBar',
                    background: '#ffffff', // Set the background color here
                    height: 1000, // Set the height dynamically
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: '50%',
                            color: "#ffffff"
                        },
                        dataLabels: {
                            showOn: 'always',
                            name: {
                                offsetY: -10,
                                show: false,
                                color: '#888',
                                fontSize: '12px',
                            },
                            value: {
                                color: '#111',
                                fontSize: '12px',
                                offsetY: 5,
                                show: false,
                                formatter: function (val) {
                                    return val + '%';
                                },
                            },
                        },
                        track: {
                            background: "rgba(199, 237, 233, 1)",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                        },

                    },

                }, fill: {
                    opacity: 1.5,
                    colors: ['rgba(106, 209, 203, 1)'],
                },

                labels: ['Progress'], // Labels for each data point
            },
        })
    }, [analyticsData])


    const [rollOverData1, setRollOverData1] = useState([]);
    const [rollOverData2, setRollOverData2] = useState([]);

    useEffect(() => {
        if (analyticsData) {
            const newData1 = [];
            const newData2 = [];
            analyticsData.rollOverTaskList.forEach(item => {
                newData1.push(item.completedTask);
                newData2.push(item.pendingTask);
            });
            setRollOverData1(newData1);
            setRollOverData2(newData2);
            // setRollOver({

            //     series: [{
            //         data: newData1,
            //         color: 'rgba(251, 169, 207, 1)',
            //         name: "Completed (Overdue)"
            //     }, {
            //         data: newData2,
            //         color: 'rgba(255, 220, 235, 1)',
            //         name: "Pending"
            //     }],
            //     options: {
            //         chart: {
            //             type: 'bar',
            //             height: 430
            //         },
            //         plotOptions: {
            //             bar: {
            //                 horizontal: true,
            //                 dataLabels: {
            //                     position: 'top',
            //                 },
            //             }
            //         },
            //         dataLabels: {
            //             enabled: false,
            //             offsetX: -6,
            //             style: {
            //                 fontSize: '12px',
            //                 colors: ['#121212'],
            //             }
            //         },
            //         stroke: {
            //             show: true,
            //             width: 1,
            //             colors: ['#fff']
            //         },
            //         tooltip: {
            //             shared: true,
            //             intersect: false
            //         },
            //         xaxis: {
            //             categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            //         },
            //     },


            // })
        }
    }, [analyticsData]);

    // const [rollOver, setRollOver] = useState({

    //     series: [{
    //         data: [],
    //         color: 'rgba(251, 169, 207, 1)',
    //         hoverColor: 'rgba(251, 169, 207, 1)',
    //         name: "Completed (Overdue)"
    //     }, {
    //         data: [],
    //         color: 'rgba(255, 220, 235, 1)',
    //         hoverColor: 'rgba(251, 169, 207, 1)',
    //         name: "Pending"
    //     }],
    //     options: {
    //         chart: {
    //             type: 'bar',
    //             height: 430
    //         },
    //         plotOptions: {
    //             bar: {
    //                 horizontal: true,
    //                 dataLabels: {
    //                     position: 'top',
    //                 },
    //             }
    //         },
    //         dataLabels: {
    //             enabled: false,
    //             offsetX: -6,
    //             style: {
    //                 fontSize: '12px',
    //                 colors: ['#121212'],
    //             }
    //         },
    //         stroke: {
    //             show: true,
    //             width: 1,
    //             colors: ['#fff']
    //         },
    //         tooltip: {
    //             shared: true,
    //             intersect: false
    //         },
    //         xaxis: {
    //             categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //         },
    //     },


    // })
    const [rollData1, setRollData1] = useState([]);
    const [rollData2, setRollData2] = useState([]);
    const [months, setMonths] = useState([])

    useEffect(() => {
        if (analyticsData) {
            const newData1 = [];
            const newData2 = [];
            const newMonths = [];
            analyticsData.rollOverTaskList.forEach(item => {
                newData1.push(item.completedTask);
                newData2.push(item.pendingTask);
                newMonths.push(item.month)
            });
            setRollData1(newData1);
            setRollData2(newData2);
            setMonths(newMonths);
        }
    }, [analyticsData]);
    const [rollOver, setRollOver] = useState({
        labels: months,
        datasets: [
            {
                label: 'Completed (Overdue)',
                data: rollData1,
                borderColor: 'rgb(251, 169, 207)',
                backgroundColor: 'rgb(251, 169, 207)',
                borderRadius: 5
            },
            {
                label: 'Pending',
                data: rollData2,
                borderColor: 'rgb(255, 220, 235)',
                backgroundColor: 'rgb(255, 220, 235)',
                borderRadius: 5
            }
        ]
    })

    useEffect(() => {
        setRollOver({
            labels: months,
            datasets: [
                {
                    label: 'Completed (Overdue)',
                    data: rollData1,
                    borderColor: 'rgb(251, 169, 207)',
                    backgroundColor: 'rgb(251, 169, 207)',
                    axis: 'y',
                    borderRadius: 5,
                    cornerRadius: 10
                },
                {
                    label: 'Pending',
                    data: rollData2,
                    borderColor: 'rgb(255, 220, 235)',
                    backgroundColor: 'rgb(255, 220, 235)',
                    axis: 'y',
                    borderRadius: 10,// Adjust this value to change the roundness of the corners
                    cornerRadius: 10
                }
            ]
        })
    }, [rollData2])
    const rollOptions = {
        scales: {
            xAxes: [{
                ticks: {
                    beginAtZero: true,
                    stepSize: 1
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        cornerRadius: 10, // Set corner radius for bars
        legend: { display: false, position: "right" },
        elements: {
            rectangle: {
                borderRadius: 10,// Adjust this value to change the roundness of the corners
                cornerRadius: 10
            }
        }
    };

    // const [rollOverPie, setRollOverPie] = useState({
    //     series: [analyticsData ? analyticsData.summary.completedOverDue : null, analyticsData ? analyticsData.summary.rollOverTask - analyticsData.summary.totalTask : null],
    //     options: {
    //         chart: {
    //             type: 'donut',
    //         },
    //         responsive: [{
    //             breakpoint: 480,
    //             options: {
    //                 chart: {
    //                     width: 200
    //                 },
    //                 legend: {
    //                     position: 'bottom',
    //                     show: false
    //                 }
    //             }
    //         }],
    //         dataLabels: {
    //             enabled: false,
    //             offsetX: -6,
    //             style: {
    //                 fontSize: '12px',
    //                 colors: ['rgba(251, 169, 207, 1)', 'rgba(255, 220, 235, 1)'],
    //                 hoverColor: ['rgba(251, 169, 207, 1)', 'rgba(255, 220, 235, 1)']
    //             }
    //         },
    //         legend: {
    //             position: 'bottom',
    //             show: false
    //         },
    //         plotOptions: {
    //             pie: {
    //                 hollow: {
    //                     margin: 0,
    //                     size: '80%',
    //                     color: "#ffffff"
    //                 },
    //                 states: {
    //                     hover: {
    //                         brightness: 0.1, // Increase brightness on hover
    //                         opacity: 1 // Adjust opacity on hover
    //                     }
    //                 }
    //             }
    //         },
    //         fill: {
    //             opacity: 10,
    //             colors: ['rgba(251, 169, 207, 1)', 'rgba(255, 220, 235, 1)'],
    //             hoverColor: ['rgba(251, 169, 207, 1)', 'rgba(255, 220, 235, 1)']
    //         },
    //         tooltip: {
    //             enabled: false,

    //             y: {
    //                 formatter: function (value, { seriesIndex }) {
    //                     return seriesIndex === 0 ? `${value}` : `${value}`;
    //                 }
    //             }
    //         },
    //         labels: ['Completed (Overdue)', 'Pending'],

    //     }
    // });

    const [rollOverPie, setRollOverPie] = useState({
        series: [analyticsData ? (analyticsData.summary.rollOverTask / analyticsData.summary.totalTask) * 100 : null],
        options: {
            chart: {
                type: 'radialBar',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'center',
                        show: false
                    }
                }
            }],
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                    colors: ['rgba(251, 169, 207, 1)'],
                    hoverColor: ['rgba(251, 169, 207, 1)']
                }
            },
            legend: {
                position: 'bottom',
                show: false
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '50%',
                        color: "#ffffff"
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#888',
                            fontSize: '12px',
                        },
                        value: {
                            color: '#111',
                            fontSize: '12px',
                            offsetY: 5,
                            show: true,
                            formatter: function (val) {
                                return val + '%';
                            },
                        },
                    },
                    track: {
                        background: "rgba(199, 237, 233, 1)",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels
                    },

                },

            },
            fill: {
                opacity: 1.5,
                colors: ['rgba(106, 209, 203, 1)'],
            },

            labels: ['Progress'], // Labels for each data point
        },
    });

    // useEffect(() => {
    //     setRollOverPie({
    //         series: [analyticsData ? analyticsData.summary.completedOverDue : null, analyticsData ? analyticsData.summary.rollOverTask - analyticsData.summary.completedOverDue : null],
    //         options: {
    //             chart: {
    //                 type: 'donut',
    //             },
    //             responsive: [{
    //                 breakpoint: 480,
    //                 options: {
    //                     chart: {
    //                         width: 200
    //                     },
    //                     legend: {
    //                         position: 'bottom',
    //                         show: false
    //                     }
    //                 }
    //             }],
    //             dataLabels: {
    //                 enabled: false,
    //                 offsetX: -6,
    //                 style: {
    //                     fontSize: '12px',
    //                     colors: ['rgba(251, 169, 207, 1)', 'rgba(255, 220, 235, 1)'],
    //                     hoverColor: ['rgba(251, 169, 207, 1)', 'rgba(255, 220, 235, 1)']
    //                 }
    //             },
    //             legend: {
    //                 position: 'bottom',
    //                 show: false
    //             },
    //             plotOptions: {
    //                 pie: {
    //                     hollow: {
    //                         margin: 0,
    //                         size: '80%',
    //                         color: "#ffffff"
    //                     },
    //                     states: {
    //                         hover: {
    //                             brightness: 0.1, // Increase brightness on hover
    //                             opacity: 1 // Adjust opacity on hover
    //                         }
    //                     }
    //                 }
    //             },
    //             fill: {
    //                 opacity: 10,
    //                 colors: ['rgba(251, 169, 207, 1)', 'rgba(255, 220, 235, 1)'],
    //                 hoversColor: ['rgba(251, 169, 207, 1)', 'rgba(255, 220, 235, 1)']
    //             },
    //             tooltip: {
    //                 enabled: false,
    //                 y: {
    //                     formatter: function (value, { seriesIndex }) {
    //                         return seriesIndex === 0 ? `${value}` : `${value}`;
    //                     }
    //                 }
    //             },
    //             labels: ['Completed (Overdue)', 'Pending'],

    //         }
    //     })
    // }, [analyticsData])

    useEffect(() => {
        setRollOverPie({
            series: [analyticsData ? (analyticsData.summary.rollOverTask / analyticsData.summary.totalTask) * 100 : null],
            options: {
                chart: {
                    type: 'radialBar',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'center',
                            show: false
                        }
                    }
                }],
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['rgba(255, 220, 235, 1)'],
                        hoverColor: ['rgba(255, 220, 235, 1)']
                    }
                },
                legend: {
                    position: 'bottom',
                    show: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: '50%',
                            color: "#ffffff"
                        },
                        dataLabels: {
                            showOn: 'always',
                            name: {
                                offsetY: -10,
                                show: false,
                                color: '#888',
                                fontSize: '12px',
                            },
                            value: {
                                color: '#111',
                                fontSize: '12px',
                                offsetY: 5,
                                show: false,
                                formatter: function (val) {
                                    return val + '%';
                                },
                            },
                        },
                        track: {
                            background: "#ebb86a7d",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                        },

                    },

                },
                fill: {
                    opacity: 1.5,
                    colors: ['rgba(235, 184, 106, 1)'],
                },

                labels: ['Progress'], // Labels for each data point
            },
        })
    }, [analyticsData])


    return (
        <div className='analytics-ui'>
            <div className='analytics-container'>
                <div className='analytics-count-container'>
                    <div className='analytics-card my-analytics-card emp' >
                        <div className='analytics-title-head-container'>
                            <div className='my-analytics-heading'>{header === "Analytics" || selfInfo?.empFullName === header ? "My Performance" : `Performance`}</div>
                            < div className='my-analytics-count'>{analyticsData ? analyticsData.summary.completedOnTime + analyticsData.summary.completedOverDue : 0}<span>/{analyticsData ? analyticsData.summary.totalTask : 0}</span></div>
                        </div>
                        <div className='analytics-count-donught-container'>
                            <div className='my-analytics-donught'><ReactApexChart options={radialData.options} series={radialData.series} type="radialBar" /></div>
                        </div>
                    </div>

                    <div className='analytics-card total-task-part' style={{ background: "#0cf50c30" }}>
                        <div className='analytics-title'>Completed Tasks (On Time)</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.completedOnTime}</div>
                    </div>
                    <div className='analytics-card total-task-part' style={{ background: "#0cf50c30" }}>
                        <div className='analytics-title'>Completed Tasks (Overdue)</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.completedOverDue}</div>
                    </div>
                    <div className='analytics-card total-task-part' >
                        <div className='analytics-title'>Submitted Tasks</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.submittedTask}</div>
                    </div>
                    <div className='analytics-card total-task-part'>
                        <div className='analytics-title'>Current Tasks</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.currentTask}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>{`Pending(rollovered)Task`}</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.pendingTask}</div>
                    </div>

                    <div className='analytics-card'>
                        <div className='analytics-title'>Upcomming Tasks</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.upcomingTask}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>Deleted Tasks</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.totalDeleted}</div>
                    </div>
                    <div className='analytics-card' title={`Total Task is 
${"Completed Tasks (On Time)+Completed Tasks (Overdue)"}
${"+Submitted Tasks+Current Tasks+Pending Tasks"}
${"+Upcomming Tasks+Deleted Tasks"}`}>
                        <div className='analytics-title'>Total Tasks</div>
                        <div className='analytics-count'>{analyticsData && (analyticsData.summary.totalTask + analyticsData.summary.totalDeleted + analyticsData.summary.upcomingTask)}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>Shifted Tasks</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.totalShifted}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>Rollover Tasks</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.rollOverTask}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>Assign Tasks</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.assignTask}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>Self Assign Tasks</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.selfAssignTask}</div>
                    </div>

                    <div className='analytics-card'>
                        <div className='analytics-title'>Sent Requests</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.sentRequest}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>Received Requests</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.receivedRequest}</div>
                    </div>
                    <div className='analytics-card'>
                        <div className='analytics-title'>Rejected Requests</div>
                        <div className='analytics-count'>{analyticsData && analyticsData.summary.myRejectedRequest}</div>
                    </div>

                </div>
                <div className='total-task-analytics-container'>
                    <div className='total-task-analytics-container-heading'>
                        Total Tasks <span>(Assign Tasks & Self Assign Tasks)</span>
                    </div>
                    <div className='total-task-analytics-view'>
                        <div className='total-task-analytics-view-slot1'>
                            <div className='total-task-label-container'>
                                <div className='total-task-label'>
                                    <div className='total-task-label-box' style={{ backgroundColor: "rgba(132, 151, 223, 1)" }}></div>
                                    <div className='total-task-label-name'>Assign Tasks</div>
                                </div>
                                <div className='total-task-label'>
                                    <div className='total-task-label-box' style={{ backgroundColor: "rgba(227, 229, 248, 1)" }}></div>
                                    <div className='total-task-label-name'>Self Assign Tasks</div>
                                </div>
                            </div>
                            <div className='chart-holder'>
                                <div className='pending-count-holder'>{analyticsData ? analyticsData.summary.selfAssignTask + analyticsData.summary.assignTask : 0}</div>
                                <Doughnut
                                    data={data}
                                    options={{

                                        elements: {

                                            center: {
                                                legend: { display: true, position: "right" },
                                                text: "Red is 2/3 the total numbers",
                                                color: "#FF6384", // Default is #000000
                                                fontStyle: "Arial", // Default is Arial
                                                sidePadding: 20, // Default is 20 (as a percentage)
                                                minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
                                                lineHeight: 25, // Default is 25 (in px), used for when text wraps
                                                maxFontSize: 11
                                            }
                                        },
                                        legend: { display: false, position: "right" }

                                    }}
                                /></div>
                        </div>
                        <div className='total-task-analytics-view-slot2'>
                            <div className='total-task-label-container'>
                                Assign Tasks & Self Assign Tasks
                            </div>
                            <Bar data={barData} options={barOptions} />
                        </div>
                    </div>
                </div>
                <div className='total-task-analytics-container'>
                    <div className='total-task-analytics-container-heading'>
                        Completed <span>(On Time & Overdue)</span>
                    </div>
                    <div className='total-task-analytics-view'>

                        <div className='total-task-analytics-view-slot2'>
                            <div className='total-task-label-container'>
                                <div className='total-task-label'>
                                    <div className='total-task-label-box' style={{ backgroundColor: "rgba(132, 151, 223, 1)" }}></div>
                                    <div className='total-task-label-name'>On Time</div>
                                </div>
                                <div className='total-task-label'>
                                    <div className='total-task-label-box' style={{ backgroundColor: "#F88795" }}></div>
                                    <div className='total-task-label-name'>Overdue</div>
                                </div>
                            </div>
                            <Line data={lineData} options={barOptions} />
                        </div>
                        <div className='total-task-analytics-view-slot1'>
                            <div className='total-task-label-container'>
                                Completed
                            </div>
                            <div className='completed-chart-container completed-holder'>
                                <div className='completed-count-holder'>{analyticsData ? analyticsData.summary.completedOnTime + analyticsData.summary.completedOverDue : 0}</div>
                                <Doughnut
                                    data={completedData}
                                    options={{

                                        elements: {

                                            center: {
                                                legend: { display: true, position: "right" },
                                                text: "Red is 2/3 the total numbers",
                                                color: "#FF6384", // Default is #000000
                                                fontStyle: "Arial", // Default is Arial
                                                sidePadding: 20, // Default is 20 (as a percentage)
                                                minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
                                                lineHeight: 25, // Default is 25 (in px), used for when text wraps
                                                maxFontSize: 11
                                            }
                                        },
                                        legend: { display: false, position: "right" }

                                    }}
                                />
                                <div className='total-task-label-container-completed'>
                                    <div className='total-task-label'>
                                        <div className='total-task-label-box' style={{ backgroundColor: "rgba(132, 151, 223, 1)" }}></div>
                                        <div className='total-task-label-name'>On Time</div>
                                    </div>
                                    <div className='total-task-label'>
                                        <div className='total-task-label-box' style={{ backgroundColor: "#F88795" }}></div>
                                        <div className='total-task-label-name'>OverDue</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='total-task-analytics-container'>
                    <div className='total-task-analytics-container-heading'>
                        Pending Tasks
                    </div>
                    <div className='total-task-analytics-view'>
                        <div className='total-task-analytics-view-slot1'>
                            {/* <div className='total-task-label-container'>
                                Completed
                            </div> */}
                            <div className='completed-chart-container'>
                                {/* <Doughnut
                                    data={completedData}
                                    options={{

                                        elements: {

                                            center: {
                                                legend: { display: true, position: "right" },
                                                text: "Red is 2/3 the total numbers",
                                                color: "#FF6384", // Default is #000000
                                                fontStyle: "Arial", // Default is Arial
                                                sidePadding: 20, // Default is 20 (as a percentage)
                                                minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
                                                lineHeight: 25, // Default is 25 (in px), used for when text wraps
                                                maxFontSize: 11
                                            }
                                        },
                                        legend: { display: false, position: "right" }

                                    }}
                                /> */}
                                <ReactApexChart options={pendingRadial.options} series={pendingRadial.series} type="radialBar" height={357} width={357} />
                                <div className='total-task-label-container-completed'>
                                    <div className='label-pending-task-analytics'>{analyticsData && !isNaN(parseInt((analyticsData.summary.pendingTask / analyticsData.summary.totalTask) * 100)) ? parseInt((analyticsData.summary.pendingTask / analyticsData.summary.totalTask) * 100) : 0}% <span>Tasks Pending</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='total-task-analytics-view-slot2'>
                            <div className='total-task-label-container'>
                                <div className='total-task-label'>
                                    <div className='total-task-label-box' style={{ backgroundColor: "rgba(254, 172, 167, 1)" }}></div>
                                    <div className='total-task-label-name'>Deleted Tasks</div>
                                </div>
                                <div className='total-task-label'>
                                    <div className='total-task-label-box' style={{ backgroundColor: "rgba(106, 209, 203, 1)" }}></div>
                                    <div className='total-task-label-name'>Shifted Tasks</div>
                                </div>
                            </div>
                            {/* <Line data={lineData} options={barOptions} /> */}{
                                chartData ?
                                    <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} /> : null
                            }

                        </div>
                    </div>
                </div>
                <div className='total-task-analytics-container'>
                    <div className='total-task-analytics-container-heading'>
                        Rollover Tasks <span>(Completed (Overdue) Tasks & Pending Tasks)</span>
                    </div>
                    <div className='total-task-analytics-view'>

                        <div className='total-task-analytics-view-slot2'>
                            <div className='total-task-label-container'>
                                <div className='total-task-label'>
                                    <div className='total-task-label-box' style={{ backgroundColor: "rgba(251, 169, 207, 1)" }}></div>
                                    <div className='total-task-label-name'>Completed (Overdue)</div>
                                </div>
                                <div className='total-task-label'>
                                    <div className='total-task-label-box' style={{ backgroundColor: "rgba(255, 220, 235, 1)" }}></div>
                                    <div className='total-task-label-name'>Pending</div>
                                </div>
                            </div>
                            {/* <ReactApexChart options={rollOver.options} series={rollOver.series} type="bar" height={400} /> */}
                            <HorizontalBar data={rollOver} options={rollOptions} />
                        </div>
                        <div className='total-task-analytics-view-slot1'>
                            <div className='total-task-label-container'>
                                Rollover Tasks
                            </div>
                            <div className='completed-chart-container'>
                                <div className='float-container'>
                                    <ReactApexChart options={rollOverPie.options} series={rollOverPie.series} type="radialBar" width={400} />
                                    <div className='valueRatio'>{analyticsData ? analyticsData.summary.rollOverTask : 0}<span>/{analyticsData ? analyticsData.summary.totalTask : 0}</span></div>
                                </div>
                                <div className='total-task-label-container-completed'>
                                    <div className='total-task-label'>
                                        <div className='total-task-label-box' style={{ backgroundColor: "#EBB86A" }}></div>
                                        <div className='total-task-label-name'>Rollover</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analytics
