import React, { useEffect, useRef, useState } from 'react';
import "./styleVerificationCrud.css"
import { useDispatch, useSelector } from 'react-redux';
import { Draggable } from "react-drag-reorder";
import { setIsEditVerification, setVerificationColorList } from '../../../../erpStore/actions';
import axios from 'axios';
import { toast } from 'react-toastify';

function VerificationCrud(props) {
    const verificationColorList = useSelector((state) => state.data.verificationColorList);
    const usedList = useSelector((state) => state.data.usedList)
    const unusedList = useSelector((state) => state.data.unusedList)
    const dispatch = useDispatch();
    const apiUrl = useSelector((state) => state.data.apiUrl)
    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);
    const [selectedunUsed, setSelectedunUsed] = useState();
    const [selectedUsed, setSelectedused] = useState();
    const [isDelete, setIsDelete] = useState(false)
    const [isAdd, setIsAdd] = useState(false)
    const [error, setError] = useState(false)

    const [updateEntity, setUpdateEntity] = useState();
    const [isUpdate, setIsUpdate] = useState(false);
    const updateRef = useRef(null)

    const [reqBody, setReqBody] = useState({
        verificationStatus: "",
        verificationLevel: verificationColorList ? verificationColorList.length : 0,
        verificationColor: "",
        verificationColorCode: ""
    }
    );

    useEffect(() => {
        function handleOutsideClick(event) {
            if (updateRef && updateRef.current && !updateRef.current.contains(event.target)) {
                setIsUpdate(false)
                setSelectedunUsed("")
                // if (updateEntity !== "") {
                //     setUpdateEntity("")
                // }

            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])

    const updateVerification = async (obj) => {
        try {
            const response = await axios.put(`${apiUrl}task/verification/update`, obj, {
                headers: storedHeaders
            })
            if (response && response.data) {
            }
        } catch (error) {
        }

    }
    const getChangedPos = (currentPos, newPos) => {
        const newVerificationList = [...verificationColorList];
        const [removed] = newVerificationList.splice(currentPos, 1);
        newVerificationList.splice(newPos, 0, removed);
        const updatedVerificationList = newVerificationList.map((item, index) => ({ ...item, verificationLevel: index }))
        dispatch(setVerificationColorList(newVerificationList.map((item, index) => ({ ...item, verificationLevel: index }))))
        updateVerification(updatedVerificationList)
    };
    const handleSubmit = async () => {
        if (reqBody.verificationStatus === "") {
            toast.error("Please Enter Verification Name", { autoClose: 2000 })
            setError(true)
            return
        }
        if (reqBody.verificationColorCode === "") {
            setError(true)
            toast.error("Please Select color for verification", { autoClose: 2000 })
            return
        }
        try {
            const response = await axios.post(`${apiUrl}task/verification/add`, reqBody, {
                headers: storedHeaders
            })
            if (response.data) {
                toast.success("Verification Added Successfully", { autoClose: 2000 })
                dispatch(setIsEditVerification(false))
                setReqBody({
                    ...reqBody,
                    verificationstatusId: response.data.verificationStatusId

                });
                const resData = [{ ...reqBody, verificationStatusId: parseInt(response.data.verificationStatusId) }];
                const updatedVerificationList = [...verificationColorList, resData[0]];
                dispatch(setVerificationColorList(updatedVerificationList));

            }
        } catch (error) {
            if (error.response && error.response.data.status === 409) {
                toast.error("This Verification has already been added", { autoClose: 2000 })
            }
        }
    }
    const [deleteEntity, setDeleteEntity] = useState('')
    const handleDelete = async (e) => {
        try {
            const response = await axios.delete(`${apiUrl}task/verification/delete?id=${deleteEntity.verificationStatusId}`, {
                headers: storedHeaders
            })
            if (response && response.data) {
                if (response.status === 200) {
                    const updatedStatusObj = verificationColorList.filter(obj => obj.verificationStatus !== deleteEntity.verificationStatus);
                    dispatch(setVerificationColorList(updatedStatusObj))
                    dispatch(setIsEditVerification(false))
                }
            }

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.status === 403) {
                toast.error("This verification status is already mapped with multiple tasks", { autoClose: 2000 })
            }
            setIsDelete(false)
        }
    }
    const updateHandleData = (e) => {
        setUpdateEntity(e)
        setSelectedunUsed(e.verificationColorCode)
        setIsUpdate(true)

    }
    const handleUpdate = async () => {
        const updateData = [updateEntity]
        if (updateData[0].verificationStatus === '') {
            toast.error('please add the verification title', { autoClose: 2000 })
            return
        }
        try {
            const response = await axios.put(`${apiUrl}task/verification/update`, updateData, {
                headers: storedHeaders
            })
            if (response && response.request.status === 200) {
                const updatedData = verificationColorList.map(item => {
                    if (item.verificationStatusId === updateEntity.verificationStatusId) {
                        return updateEntity;
                    }
                    return item;
                });
                dispatch(setVerificationColorList(updatedData))
                dispatch(setIsEditVerification(false))
            }
        } catch (error) {
            toast.error('Please enter verification', { autoClose: 2000 })

        }
    }
    return (
        <div className='verification-crud' onClick={() => dispatch(setIsEditVerification(false))}>
            {
                isDelete ? <div className='confirmation-messege-background' onClick={(e) => {
                    e.stopPropagation()
                    setIsDelete(false)
                }} >
                    <div className='confirmation-messege-container' onClick={(e) => e.stopPropagation()}>
                        <div className='confirmation-messege'>Are you sure to Delete this Verification?</div>
                        <div className='confirmation-messege-buttons'>
                            <div className='add-verification' onClick={handleDelete}>Delete</div>
                            <div className='cancel-verification' onClick={() => setIsDelete(false)}>Cancel</div>
                        </div>
                    </div>
                </div> : null
            }

            <div className='verification-crud-container' onClick={(e) => e.stopPropagation()}>
                <div className='verification-crud-list'>
                    <div className='verification-header'>
                        <div className='verification-heading'>Verification</div>
                        <div className='verification-crud-cancel'><svg onClick={() => dispatch(setIsEditVerification(false))} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7248_1372)">
                                <path d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="#263238" fill-opacity="0.1" />
                                <path d="M15.125 6.87109L6.875 15.1211" stroke="#263238" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.875 6.87109L15.125 15.1211" stroke="#263238" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7248_1372">
                                    <rect width="22" height="22" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        </div>
                    </div>
                    <div className='verification-add-operation-button-container'>{
                        isAdd ? <div className='verification-add-operation'>
                            <div className='add-verification-name'>
                                <input type='text' style={{ border: error && reqBody.verificationStatus === "" ? "1px solid red" : null }} alt='/' value={reqBody.verificationStatus} onChange={(event) => setReqBody((prev) => ({ ...prev, verificationStatus: event.target.value }))} placeholder='Enter Verification Name' maxLength={20} />
                            </div>
                            <div className='verification-unused-colors'>
                                <p>Color</p>
                                <div className='used-colors'>
                                    {
                                        unusedList && unusedList.length > 0 ? unusedList.map((color, index) => <div className='color-info-holder' onClick={() => {
                                            setSelectedunUsed(index)
                                            setReqBody({
                                                ...reqBody,
                                                verificationColor: color.verificationColor,
                                                verificationColorCode: color.verificationColorCode
                                            });
                                        }}>
                                            <div className='color-pallet-holder'>
                                                <div className='color-pallet' style={{ background: color.verificationColorCode }}></div> {color.verificationColor}
                                            </div>{
                                                selectedunUsed === index ?
                                                    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11 1L4.125 8L1 4.81818" stroke="#FF8402" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg> : null
                                            }


                                        </div>) : null
                                    }
                                </div>
                            </div>
                            <div className='verification-used-colors'>
                                <p>*These colors are already selected*</p>
                                <div className='used-colors'>
                                    {
                                        usedList && usedList.length > 0 ? usedList.map((color, index) => <div className='color-info-holder non-select-color' onClick={() => setSelectedused(index)}>
                                            <div className='color-pallet-holder'>
                                                <div className='color-pallet' style={{ background: color.verificationColorCode }}>
                                                    <div className='faint-color'>
                                                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4 0C1.79107 0 0 1.79107 0 4C0 6.20893 1.79107 8 4 8C6.20893 8 8 6.20893 8 4C8 1.79107 6.20893 0 4 0ZM4 7.32143C2.16607 7.32143 0.678571 5.83393 0.678571 4C0.678571 3.20536 0.958036 2.475 1.42411 1.90357L6.09643 6.57589C5.525 7.04196 4.79464 7.32143 4 7.32143ZM6.57589 6.09643L1.90357 1.42411C2.475 0.958036 3.20536 0.678571 4 0.678571C5.83393 0.678571 7.32143 2.16607 7.32143 4C7.32143 4.79464 7.04196 5.525 6.57589 6.09643Z" fill="#263238" />
                                                        </svg>

                                                    </div>
                                                </div> {color.verificationColor}
                                            </div>

                                        </div>) : null
                                    }
                                </div>
                            </div>
                            <div className='verification-add-operation-button-holder'>
                                <div className='add-verification' onClick={handleSubmit}>Add Verification</div>
                                <div className='cancel-verification' onClick={() => setIsAdd(false)}>Cancel</div>
                            </div>
                        </div> : <div className='verification-add-operation-button' onClick={() => setIsAdd(true)}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7248_1362)">
                                    <path d="M9.00391 16.498C13.146 16.498 16.5039 13.1402 16.5039 8.99805C16.5039 4.85591 13.146 1.49805 9.00391 1.49805C4.86177 1.49805 1.50391 4.85591 1.50391 8.99805C1.50391 13.1402 4.86177 16.498 9.00391 16.498Z" stroke="#FF8402" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 5.99805V11.998" stroke="#FF8402" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6.00391 9H12.0039" stroke="#FF8402" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7248_1362">
                                        <rect width="18" height="18" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            Add New Verification
                        </div>
                    }
                    </div>{

                    }
                    <div className='verification-list-container'>
                        <div className='verification-list'>
                            {verificationColorList && verificationColorList.length > 0 ?
                                <Draggable onPosChange={getChangedPos} >
                                    {
                                        verificationColorList && verificationColorList.length > 0 && verificationColorList.map((verification, index) => <div className='verification-properties'>
                                            <div className='verification-info'>
                                                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 1H9" stroke="#263238" stroke-linecap="round" />
                                                    <path d="M1 7H9" stroke="#263238" stroke-linecap="round" />
                                                </svg>

                                                <div className='verification-name' onClick={(e) => e.stopPropagation()}>
                                                    <div className='color-code' style={{ background: verification.verificationColorCode }}></div>
                                                    {verification.verificationStatus}
                                                </div>
                                            </div>
                                            <div className='verification-crud-properties' onClick={(e) => e.stopPropagation()}>
                                                <svg onClick={(e) => {
                                                    updateHandleData(verification)
                                                }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="26" height="26" rx="4" fill="#D9D9D9" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5898 19.2654C12.5898 18.861 12.9176 18.5332 13.322 18.5332H19.9115C20.3159 18.5332 20.6437 18.861 20.6437 19.2654C20.6437 19.6697 20.3159 19.9975 19.9115 19.9975H13.322C12.9176 19.9975 12.5898 19.6697 12.5898 19.2654Z" fill="#263238" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7147 7.46433C17.4969 7.46433 17.2881 7.55083 17.1341 7.7048L8.12547 16.7135L7.73845 18.2616L9.28653 17.8745L18.2952 8.86585C18.3714 8.78962 18.4319 8.69911 18.4732 8.59951C18.5144 8.4999 18.5357 8.39314 18.5357 8.28533C18.5357 8.17751 18.5144 8.07075 18.4732 7.97114C18.4319 7.87154 18.3714 7.78103 18.2952 7.7048C18.219 7.62856 18.1285 7.56809 18.0289 7.52683C17.9292 7.48557 17.8225 7.46433 17.7147 7.46433ZM16.0987 6.66936C16.5273 6.24077 17.1086 6 17.7147 6C18.0148 6 18.312 6.05911 18.5892 6.17396C18.8665 6.28881 19.1184 6.45714 19.3306 6.66936C19.5429 6.88157 19.7112 7.1335 19.826 7.41077C19.9409 7.68804 20 7.98521 20 8.28533C20 8.58544 19.9409 8.88261 19.826 9.15988C19.7112 9.43715 19.5429 9.68908 19.3306 9.90129L10.1786 19.0534C10.0847 19.1472 9.96716 19.2138 9.83842 19.246L6.90976 19.9781C6.66025 20.0405 6.39632 19.9674 6.21446 19.7855C6.03261 19.6037 5.9595 19.3397 6.02188 19.0902L6.75404 16.1616C6.78623 16.0328 6.85279 15.9153 6.94663 15.8214L16.0987 6.66936Z" fill="#263238" />
                                                </svg>
                                                <svg onClick={(e) => {
                                                    setDeleteEntity(verification)
                                                    setIsDelete(true)
                                                }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="26" height="26" rx="4" fill="#D9D9D9" />
                                                    <path d="M7 9H8.33333H19" stroke="#263238" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M17.6654 9.00065V18.334C17.6654 18.6876 17.5249 19.0267 17.2748 19.2768C17.0248 19.5268 16.6857 19.6673 16.332 19.6673H9.66536C9.31174 19.6673 8.9726 19.5268 8.72256 19.2768C8.47251 19.0267 8.33203 18.6876 8.33203 18.334V9.00065M10.332 9.00065V7.66732C10.332 7.3137 10.4725 6.97456 10.7226 6.72451C10.9726 6.47446 11.3117 6.33398 11.6654 6.33398H14.332C14.6857 6.33398 15.0248 6.47446 15.2748 6.72451C15.5249 6.97456 15.6654 7.3137 15.6654 7.66732V9.00065" stroke="#263238" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.668 12.334V16.334" stroke="#263238" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.332 12.334V16.334" stroke="#263238" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            </div>
                                        </div>)
                                    }
                                </Draggable> : null

                            }
                        </div>
                    </div>
                    {isUpdate ?
                        <div className='update-container-floating' ref={updateRef}>
                            <div className='status-add-operation'>
                                <div className='add-status-name'>
                                    <input type='text' style={{ border: updateEntity.verificationStatus === "" ? "1px solid red" : null }} alt='/' value={updateEntity.verificationStatus} onChange={(event) => setUpdateEntity((prev) => ({ ...prev, verificationStatus: event.target.value }))} placeholder='Enter Verification Name' maxLength={20} />
                                </div>
                                <div className='status-unused-colors'>
                                    <p>Color</p>
                                    <div className='used-colors'>
                                        {
                                            unusedList && unusedList.length > 0 ? unusedList.map((color, index) => <div className='color-info-holder' onClick={() => {
                                                setSelectedunUsed(color.verificationColorCode)
                                                setUpdateEntity({
                                                    ...updateEntity,
                                                    verificationColor: color.verificationColor,
                                                    verificationColorCode: color.verificationColorCode
                                                });
                                            }}>
                                                <div className='color-pallet-holder'>
                                                    <div className='color-pallet' style={{ background: color.verificationColorCode }}></div> {color.verificationColor}
                                                </div>{
                                                    selectedunUsed === color.verificationColorCode ?
                                                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11 1L4.125 8L1 4.81818" stroke="#FF8402" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg> : null
                                                }


                                            </div>) : null
                                        }
                                    </div>
                                </div>
                                <div className='status-used-colors'>
                                    <p>*These colors are already selected*</p>
                                    <div className='used-colors'>
                                        {
                                            usedList && usedList.length > 0 ? usedList.map((color, index) => <div className='color-info-holder non-select-color' onClick={() => setSelectedused(index)}>
                                                <div className='color-pallet-holder'>
                                                    <div className='color-pallet' style={{ background: color.verificationColorCode }}>
                                                        <div className='faint-color'>
                                                            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 0C1.79107 0 0 1.79107 0 4C0 6.20893 1.79107 8 4 8C6.20893 8 8 6.20893 8 4C8 1.79107 6.20893 0 4 0ZM4 7.32143C2.16607 7.32143 0.678571 5.83393 0.678571 4C0.678571 3.20536 0.958036 2.475 1.42411 1.90357L6.09643 6.57589C5.525 7.04196 4.79464 7.32143 4 7.32143ZM6.57589 6.09643L1.90357 1.42411C2.475 0.958036 3.20536 0.678571 4 0.678571C5.83393 0.678571 7.32143 2.16607 7.32143 4C7.32143 4.79464 7.04196 5.525 6.57589 6.09643Z" fill="#263238" />
                                                            </svg>

                                                        </div>

                                                    </div> {color.verificationColor}
                                                </div>{
                                                    selectedunUsed === color.verificationColorCode ?
                                                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11 1L4.125 8L1 4.81818" stroke="#FF8402" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg> : null
                                                }

                                            </div>) : null
                                        }
                                    </div>
                                </div>
                                <div className='status-add-operation-button-holder'>
                                    <div className='add-status' onClick={handleUpdate}>Save</div>
                                    <div className='cancel-status' onClick={() => setIsUpdate(false)}>Cancel</div>
                                </div>
                            </div>
                        </div> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default VerificationCrud
