import React from 'react';
import "./styleLogout.css"
import { useNavigate } from 'react-router-dom';
import { setLogout } from '../../../erpStore/actions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function Logout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiUrl = useSelector((state) => state.data.apiUrl);
    const selfInfo = useSelector((state) => state.data.selfData)
    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);

    return (
        <div className='logout-container'>
            <div className='logout'>
                <div className='text-logout'>Are you sure to Confirm Logout?</div>
                <div className='logout-btns'>
                    <div className='logout-confirm' onClick={() => {
                        window.localStorage.setItem("myHeaders", "");
                        navigate("/login")
                        toast.success('Log out successfully', { autoClose: 2000})
                            axios.post(`${apiUrl}notification/logs/remove-token`, {'userId' : selfInfo.userId, 'type': 'web'}, {headers: storedHeaders})
                            .then(response => {
                                if(response.request.status === 200){
                                }
                            })
                            .catch(error => {
                                
                            })
                    }}>Logout</div>
                    <div className='cancel-logout' onClick={() => dispatch(setLogout(false))}>Cancel</div>
                </div>
            </div>
        </div>
    )
}

export default Logout
