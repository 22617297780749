import React, { useEffect, useState, useMemo } from 'react';
import './styleWeekView.css';
// import axios from 'axios';
import moment from 'moment';
import { setAddTask, setDate, setFullDate, setSearchedDate, setView } from '../../../erpStore/actions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
const WeekView = (props) => {

    const dispatch = useDispatch();
    const dateString = props.props.date.toString();
    const taskUpdate = useSelector((state) => state.data.newTask);
    const weekDataAll = useSelector((state) => state.data.weekData)
    const empView = useSelector((state) => state.data.empView)
    const self = useSelector((state) => state.data.selfData)
    const empInfo = useSelector((state) => state.data.empInfo)
    const dateParts = dateString.split(' ');
    const day = dateParts[0];
    const selfInfo = empView ? empInfo : self
    const view = useSelector((state) => state.data.view)
    const selfInfoEmp = useSelector((state) => state.data.taskEmp)
    const dateSplit = dateString.split(" ");
    const calendarDay = dateSplit[2].toString();
    const newDate = new Date();
    const listDate = newDate.toString().split(" ");
    const getToday = listDate[2].toString();
    const currentMonth = dateSplit[1].toString() + dateSplit[3].toString();
    const todayMonth = listDate[1].toString() + listDate[3].toString();
    const isToday = calendarDay === getToday && currentMonth === todayMonth;
    const [isExpand, setIsExpand] = useState(false);
    const [isExpandSelf, setIsExpandSelf] = useState(false)
    const [weekData, setWeekData] = useState({});
    const [selfData, setSelfData] = useState({});
    const [leaveMap, setLeaveMap] = useState({})
    const [holidayMap, setHolidayMap] = useState(null)
    const [assignData, setAssignData] = useState(null);
    const [analytics, setAnalytics] = useState({});
    const x = moment(dateString).isoWeek()
    const y = moment().isoWeek()
    const dispatchAllTask = useSelector((state) => state.data.dispatchAllTask)

    const covertStringFormat = (stringFormat) => {
        return stringFormat === 'FULLDAY' ? 'Full Day Leave' : stringFormat === 'HALFDAY1' ? 'First Half Leave' : 'Second Half Leave'
    }


    const getMonthNumber = (monthName) => {
        const monthMoment = moment().month(monthName).startOf('month');
        return monthMoment.format('MM');
    };
    const gtDate = (parseInt(getMonthNumber(dateSplit[1])) === parseInt(getMonthNumber(listDate[1])) && parseInt(dateSplit[2]) < parseInt(listDate[2]));
    const month = getMonthNumber(dateSplit[1])
    const fullDate = dateSplit[3] + "-" + (month.length > 1 ? month : '0' + month) + "-" + dateSplit[2]

    useEffect(() => {
        const fetchData = async () => {
            const month = getMonthNumber(dateSplit[1])
            const fullDate = dateSplit[3] + "-" + (month.length > 1 ? month : '0' + month) + "-" + dateSplit[2]
            try {
                document.getElementById('weekView').removeAttribute('disabled');
                // Set attributes
                var elementsHeader = document.querySelectorAll('.rbc-header');
                // elementsHeader[6].setAttribute("style", "flex:0 0; min-width:12.5%");
                var elementsBorder = document.querySelectorAll('.weekView-Content');

                elementsBorder[6]?.setAttribute("style", "border-right:1px solid #26323842;");

                if (x !== y) {
                    elementsHeader[0].setAttribute("style", "flex:0 0; max-width:14.2%");
                    elementsHeader[0].setAttribute("style", "flex:0 0; max-width:14.2%");
                } else {
                    elementsHeader[0].setAttribute("style", "flex:0 0; max-width:14.2%");
                    elementsHeader[0].setAttribute("style", "flex:0 0; max-width:14.2%");
                }
                dispatch(setSearchedDate(fullDate))
                if (weekDataAll && weekDataAll[fullDate]) {
                    setLeaveMap(weekDataAll[fullDate].leave)
                    setHolidayMap(weekDataAll[fullDate].holiday)
                    const updatedSelfData = weekDataAll[fullDate].task.filter(task => task.taskOwner === selfInfoEmp.empFullName);
                    setSelfData(updatedSelfData);
                    const updatedAssignData = weekDataAll[fullDate].task.filter(task => task.taskOwner !== selfInfoEmp.empFullName);
                    setAssignData(updatedAssignData);
                    const analytics = weekDataAll[fullDate].task.filter(task => task.status === "Completed");
                    setAnalytics(analytics)

                } else {
                    setSelfData({});
                    setAssignData({});
                    setAnalytics({})
                }
                setWeekData(weekDataAll[fullDate]);


            } catch (error) {
            }
        };

        fetchData();
    }, [taskUpdate, x === y, weekDataAll, dispatchAllTask]);

    const handleClick = (e) => {
        e.stopPropagation();
    }
    // if (day === 'Sun') {
    //     return (
    //         <div className='weekView'>
    //             <div className='weekView-container' onClick={(e) => {
    //                 e.stopPropagation();

    //             }}>
    //                 {day} <span>{dateParts[2]}</span>
    //             </div>
    //             <div className='weekView-Content' style={{ background: '#263238' }}>
    //             </div>
    //         </div>
    //     );
    // }
    const isDateLessThanCurrent = (date) => {
        const givenDate = moment(date); // Convert string to Date object
        const currentDate = moment(selfInfoEmp?.empJoiningDate); // Get current date
        // Compare the dates
        return givenDate < currentDate;
    };
    return (
        <div className='weekView' id='weekView'>
            <style>
                {`.rbc-time-header {
            height: 100%;
            }
            .rbc-time-header-content {
            height: 100%;
            }
            .rbc-time-view .rbc-row {
            height: 100%;
            .rbc-button-link {
                width:100%;
            }
            .rbc-time-header-content .rbc-header {
                padding: 0 0px;
            }
            .rbc-button-link {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
            }
            .rbc-header{
                min-width:14.2%;
                max-width:14.2%;
            }
            .rbc-today{
                min-width:14.2% !important;
                max-width:14.2% !important;
            }
            `
                }
            </style>
            <div className='weekView-container'>
                <div className='day-text-containt' onClick={(e) => {
                    if (isDateLessThanCurrent(dateString)) {
                        toast.warning("No task available before employee's joining date");
                        return
                    }   
                    e.stopPropagation();
                    const previousDate = moment(dateString)
                    dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                    dispatch(setView('day'))
                    window.sessionStorage.setItem("view", 'day')
                }}>
                    {day} <span>{dateParts[2]}</span>
                </div>
                <div className='progressDiv' style={{ background: isToday ? "#F2F2F2" : null }}>
                    <div className='progressBar' style={{
                        width: weekData && weekData.length > 0 ? `${(analytics.length / weekData.length) * 100}%` : "0%",
                        background: isToday ? '#21CD85' : null
                    }}></div>
                </div>
            </div>

                { weekData?.holiday !== null && 
                    <div className='weekView-Content-holiday'>
                        <div className='leaveStatusForFullday' style={{background: 'rgba(75, 85, 90, 1)'}} title={weekData?.holiday?.holidayDescription}>
                            <div className='contentActive-deaactive' style={{color: 'rgba(255, 255, 255, 1)'}}>
                                {}
                                {weekData?.holiday?.holidayTitle.length > 15 ? weekData?.holiday?.holidayTitle.substring(0, 25) + '...' : weekData?.holiday?.holidayTitle}
                            </div>
                        </div>
                        <div className='addTask-Div' onClick={(e) => {
                                e.stopPropagation();
                                dispatch(setDate(fullDate))
                                dispatch(setAddTask(true))
                                dispatch(setFullDate(fullDate))
                            }}
                                style={{
                                    display: isDateLessThanCurrent(dateString) ? "none" : null
                                }}
                            >
                                <svg width="8" height="8" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.60002 0.428571C3.60002 0.191878 3.3314 0 3.00002 0C2.66865 0 2.40002 0.191878 2.40002 0.428571V2.40002H0.428571C0.191878 2.40002 0 2.66865 0 3.00002C0 3.3314 0.191878 3.60002 0.428571 3.60002H2.40002V5.57143C2.40002 5.80812 2.66865 6 3.00002 6C3.3314 6 3.60002 5.80812 3.60002 5.57143V3.60002H5.57143C5.80812 3.60002 6 3.3314 6 3.00002C6 2.66865 5.80812 2.40002 5.57143 2.40002H3.60002V0.428571Z" fill="#263238" />
                                </svg> Add Tasks
                            </div>
                    </div>
                }

                { weekData?.holiday === null && weekData?.leave !== null &&  weekData?.leave?.leaveFormat === 'FULLDAY' && 
                    <div className='weekView-Content-holiday-fullday' style={{background: 'rgba(231, 0, 0, 0.1)'}}>  
                        <div className='leaveStatusForFullday' style={{background: '#fff'}}>
                            <div className='discActive' style={{background: 'rgba(231, 0, 0, 1)'}}></div>
                            <div className='contentActive-deaactive' style={{color: 'rgba(231, 0, 0, 1)'}}>{'Full Day Leave'}</div>
                        </div>
                        <div className='addTask-Div' onClick={(e) => {
                                e.stopPropagation();
                                dispatch(setDate(fullDate))
                                dispatch(setAddTask(true))
                                dispatch(setFullDate(fullDate))
                            }}
                                style={{
                                    display: isDateLessThanCurrent(dateString) ? "none" : null
                                }}
                            >
                                <svg width="8" height="8" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.60002 0.428571C3.60002 0.191878 3.3314 0 3.00002 0C2.66865 0 2.40002 0.191878 2.40002 0.428571V2.40002H0.428571C0.191878 2.40002 0 2.66865 0 3.00002C0 3.3314 0.191878 3.60002 0.428571 3.60002H2.40002V5.57143C2.40002 5.80812 2.66865 6 3.00002 6C3.3314 6 3.60002 5.80812 3.60002 5.57143V3.60002H5.57143C5.80812 3.60002 6 3.3314 6 3.00002C6 2.66865 5.80812 2.40002 5.57143 2.40002H3.60002V0.428571Z" fill="#263238" />
                                </svg> Add Tasks
                            </div>
                    </div>
                }
                
                { (weekData?.holiday === null && (weekData?.leave !== null ? (weekData?.leave?.leaveFormat !== 'FULLDAY' ? true : false) : true)) && 
                    <div className='weekView-Content' style={{
                        background: isToday ? '#FFF6DF' : 'none',
                        background: isToday ? '#FFF6DF' : '',
                        opacity: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "1" : "1",
                        borderLeft: day === "sun" ? "1px solid black" : null,
                    }} onClick={(e) => {
                        if (isDateLessThanCurrent(dateString)) {
                            toast.warning("No task available before employee's joining date");
                            return
                        }
                        e.stopPropagation();
                        const previousDate = moment(dateString)
                        dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                        dispatch(setView('day'))
                        window.sessionStorage.setItem("view", 'day')
                    }}>
                        { (weekData?.holiday === null) && (weekData?.leave === null || (weekData?.leave?.leaveFormat !== 'FULLDAY')) && 
                            <div className='active-deactive-leave-status'>
                                { weekData?.isPresent !== null && 
                                    <div className='present-absent' style={{background: weekData?.isPresent === false ? 'rgba(231, 0, 0, 0.1)' : 'rgba(1, 159, 55, 0.1)'}}>
                                        <div className='discActive' style={{background: weekData?.isPresent === false ? 'rgb(234, 22, 22)' : 'rgba(1, 159, 55, 1)'}}></div>
                                        <div className='contentActive-deaactive' style={{color: weekData?.isPresent === false ? 'rgb(234, 22, 22)' : 'rgba(1, 159, 55, 1)'}}>{ weekData?.isPresent === false ? 'Absent' : 'Active'}</div>
                                    </div>
                                }

                                { (weekData?.leave !== null && weekData?.leave?.leaveFormat !== 'FULLDAY') &&  
                                    <div className='leaveStatus' style={{background: 'rgb(232, 226, 243)'}}>
                                        <div className='discActive' style={{background: 'rgb(98, 63, 173)'}}></div>
                                        <div className='contentActive-deaactive' style={{color: 'rgb(98, 63, 173)'}}>{weekData?.leave?.leaveFormat === 'HALFDAY1' ? 'First half leave' : 'Second half leave'}</div>
                                    </div>
                                }
                            </div>
                        }

                        <div className='week-assigned-task'>

                            {assignData && assignData.length > 0 ?
                                <div className='task-category-heading'>
                                    <p style={{ fontWeight: '700' }}>Assigned Task</p>
                                    <hr></hr>
                                </div> : null
                            }

                            { 
                                assignData && assignData.length > 0 && assignData.slice(0, 4).map(wdata => (
                                    <div className='week-task' onClick={(e) => { e.stopPropagation() }}>
                                        <div className='task'>
                                            <div className='status-container'>
                                                <div className="statusW" title={wdata.status} style={{ backgroundColor: wdata.statusColorCode }}></div>
                                            </div>
                                            <div className='taskInner'>
                                                <div className='task-holder'>
                                                    <p style={wdata.status === 'Completed' || wdata.status === 'Submitted' ? { 'text-decoration': 'line-through' } : {}} title={wdata.taskTitle}>{wdata.taskTitle}</p>
                                                </div>
                                                <div className='priorityW' title={wdata.priority} style={{ backgroundColor: wdata.priorityColorCode }} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            {
                                isExpand && assignData && assignData.length > 0 && assignData.slice(4, assignData.length).map(wdata => (
                                    <div className='week-task'>
                                        <div className='task'>
                                            <div className='status-container'>
                                                <div className="statusW" title={wdata.status} style={{ backgroundColor: wdata.statusColorCode }}></div>
                                            </div>
                                            <div className='taskInner'>
                                                <div className='title-holder'>
                                                    <p style={wdata.status === 'Completed' || wdata.status === 'Submitted' ? { 'text-decoration': 'line-through' } : {}} title={wdata.taskTitle}>{wdata.taskTitle}</p>
                                                </div>
                                                <div className='priorityW' title={wdata.priority} style={{ backgroundColor: wdata.priorityColorCode }} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            {assignData && assignData.length > 0 ?
                                <div className='innerDiv-more' style={{ cursor: 'pointer' }}>
                                    {assignData && assignData.length >= 5 ?
                                        <div className='moreTask'>
                                            {/* {
                                                isExpand ? <p></p> : <p>+{assignData.length - 5} more</p>
                                            } */}
                                            {isExpand ?
                                                <p onClick={(e) => {
                                                    e.stopPropagation()
                                                    setIsExpand(!isExpand)
                                                }}>Collaps <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.33845 5.40975C4.43036 5.51581 4.42765 5.67407 4.33215 5.77691C4.22131 5.89628 4.03159 5.89365 3.92409 5.77127L2.25 3.86538L0.575908 5.77127C0.468411 5.89365 0.278686 5.89628 0.167848 5.77691C0.0723491 5.67407 0.0696354 5.51581 0.161553 5.40975L2.25 3L4.33845 5.40975Z" fill="#263238" />
                                                        <path d="M4.33845 2.40975C4.43036 2.51581 4.42765 2.67407 4.33215 2.77691C4.22131 2.89628 4.03159 2.89365 3.92409 2.77127L2.25 0.865385L0.575908 2.77127C0.468411 2.89365 0.278686 2.89628 0.167848 2.77691C0.0723491 2.67407 0.0696354 2.51581 0.161553 2.40975L2.25 0L4.33845 2.40975Z" fill="#263238" />
                                                    </svg>

                                                </p> : <p onClick={(e) => {
                                                    e.stopPropagation()
                                                    setIsExpand(!isExpand)
                                                }}>Expand <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.33845 0.590253C4.43036 0.484195 4.42765 0.325932 4.33215 0.223087C4.22131 0.103723 4.03159 0.106345 3.92409 0.228726L2.25 2.13462L0.575908 0.228726C0.468411 0.106345 0.278686 0.103723 0.167848 0.223087C0.0723491 0.325932 0.0696354 0.484195 0.161553 0.590253L2.25 3L4.33845 0.590253Z" fill="#263238" />
                                                        <path d="M4.33845 3.59025C4.43036 3.48419 4.42765 3.32593 4.33215 3.22309C4.22131 3.10372 4.03159 3.10635 3.92409 3.22873L2.25 5.13462L0.575908 3.22873C0.468411 3.10635 0.278686 3.10372 0.167848 3.22309C0.0723491 3.32593 0.0696354 3.48419 0.161553 3.59025L2.25 6L4.33845 3.59025Z" fill="#263238" />
                                                    </svg>
                                                </p>}
                                        </div> : null
                                    }
                                </div> : null
                            }
                            <hr></hr>

                            {selfData && selfData.length > 0 ?
                                <div className='task-category-heading'>
                                    <p style={{ fontWeight: '700' }}>Self Task</p>
                                    <hr></hr>
                                </div> : null
                            }

                            {
                                selfData && selfData.length > 0 && selfData.slice(0, 4).map(wdata => (
                                    <div className='week-task' onClick={(e) => handleClick(e)}>
                                        <div className='task'>
                                            <div className='status-container'>
                                                <div className="statusW" title={wdata.status} style={{ backgroundColor: wdata.statusColorCode }}></div>
                                            </div>
                                            <div className='taskInner'>
                                                <div className='title-holder'>
                                                    <p style={wdata.status === 'Completed' || wdata.status === 'Submitted' ? { 'text-decoration': 'line-through' } : {}} title={wdata.taskTitle}>{wdata.taskTitle}</p>
                                                </div>
                                                <div className='priorityW' title={wdata.priority} style={{ backgroundColor: wdata.priorityColorCode }} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                isExpandSelf && selfData && selfData.length > 0 && selfData.slice(4, selfData.length).map(wdata => (
                                    <div className='week-task'>
                                        <div className='task'>
                                            <div className='status-container'>
                                                <div className="statusW" title={wdata.status} style={{ backgroundColor: wdata.statusColorCode }}></div>
                                            </div>
                                            <div className='taskInner'>
                                                <div className='title-holder'>
                                                    <p style={wdata.status === 'Completed' || wdata.status === 'Submitted' ? { 'text-decoration': 'line-through' } : {}} title={wdata.taskTitle}>{wdata.taskTitle}</p>
                                                </div>
                                                <div className='priorityW' title={wdata.priority} style={{ backgroundColor: wdata.priorityColorCode }} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            {selfData && selfData.length > 0 ?
                                <div className='innerDiv-more' style={{ cursor: 'pointer' }}>
                                    {selfData && selfData.length >= 5 ?
                                        <div className='moreTask'>
                                            {/* {
                                            isExpand ? <p></p> : <p>+{selfData.length - 4} more</p>
                                        } */}
                                            {isExpandSelf ?
                                                <p onClick={(e) => {
                                                    e.stopPropagation()
                                                    setIsExpandSelf(!isExpandSelf)
                                                }}>Collaps <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.33845 5.40975C4.43036 5.51581 4.42765 5.67407 4.33215 5.77691C4.22131 5.89628 4.03159 5.89365 3.92409 5.77127L2.25 3.86538L0.575908 5.77127C0.468411 5.89365 0.278686 5.89628 0.167848 5.77691C0.0723491 5.67407 0.0696354 5.51581 0.161553 5.40975L2.25 3L4.33845 5.40975Z" fill="#263238" />
                                                        <path d="M4.33845 2.40975C4.43036 2.51581 4.42765 2.67407 4.33215 2.77691C4.22131 2.89628 4.03159 2.89365 3.92409 2.77127L2.25 0.865385L0.575908 2.77127C0.468411 2.89365 0.278686 2.89628 0.167848 2.77691C0.0723491 2.67407 0.0696354 2.51581 0.161553 2.40975L2.25 0L4.33845 2.40975Z" fill="#263238" />
                                                    </svg>

                                                </p> : <p onClick={(e) => {
                                                    e.stopPropagation()
                                                    setIsExpandSelf(!isExpandSelf)
                                                }}>Expand <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.33845 0.590253C4.43036 0.484195 4.42765 0.325932 4.33215 0.223087C4.22131 0.103723 4.03159 0.106345 3.92409 0.228726L2.25 2.13462L0.575908 0.228726C0.468411 0.106345 0.278686 0.103723 0.167848 0.223087C0.0723491 0.325932 0.0696354 0.484195 0.161553 0.590253L2.25 3L4.33845 0.590253Z" fill="#263238" />
                                                        <path d="M4.33845 3.59025C4.43036 3.48419 4.42765 3.32593 4.33215 3.22309C4.22131 3.10372 4.03159 3.10635 3.92409 3.22873L2.25 5.13462L0.575908 3.22873C0.468411 3.10635 0.278686 3.10372 0.167848 3.22309C0.0723491 3.32593 0.0696354 3.48419 0.161553 3.59025L2.25 6L4.33845 3.59025Z" fill="#263238" />
                                                    </svg>
                                                </p>}
                                        </div> : null
                                    }
                                </div> : null
                            }

                            <div className='addTask-Div' onClick={(e) => {
                                e.stopPropagation();
                                dispatch(setDate(fullDate))
                                dispatch(setAddTask(true))
                                dispatch(setFullDate(fullDate))
                            }}
                                style={{
                                    display: isDateLessThanCurrent(dateString) ? "none" : null
                                }}
                            >
                                <svg width="8" height="8" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.60002 0.428571C3.60002 0.191878 3.3314 0 3.00002 0C2.66865 0 2.40002 0.191878 2.40002 0.428571V2.40002H0.428571C0.191878 2.40002 0 2.66865 0 3.00002C0 3.3314 0.191878 3.60002 0.428571 3.60002H2.40002V5.57143C2.40002 5.80812 2.66865 6 3.00002 6C3.3314 6 3.60002 5.80812 3.60002 5.57143V3.60002H5.57143C5.80812 3.60002 6 3.3314 6 3.00002C6 2.66865 5.80812 2.40002 5.57143 2.40002H3.60002V0.428571Z" fill="#263238" />
                                </svg> Add Tasks
                            </div>
                        </div>
                </div>
                }
        </div >
    );
};

export default WeekView;



// { weekData?.holiday !== null && 
//     <div className='week-holiday-container'>
//         <div className='weekHolidayContent' title={weekData?.holiday?.holidayDescription}>{weekData?.holiday?.holidayTitle}</div>
//         <div className='addTask-Div' onClick={(e) => {
//                 e.stopPropagation();
//                 dispatch(setDate(fullDate))
//                 dispatch(setAddTask(true))
//                 dispatch(setFullDate(fullDate))
//             }}
//                 style={{
//                     display: isDateLessThanCurrent(dateString) ? "none" : null
//                 }}
//             >
//                 <svg width="8" height="8" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path fill-rule="evenodd" clip-rule="evenodd" d="M3.60002 0.428571C3.60002 0.191878 3.3314 0 3.00002 0C2.66865 0 2.40002 0.191878 2.40002 0.428571V2.40002H0.428571C0.191878 2.40002 0 2.66865 0 3.00002C0 3.3314 0.191878 3.60002 0.428571 3.60002H2.40002V5.57143C2.40002 5.80812 2.66865 6 3.00002 6C3.3314 6 3.60002 5.80812 3.60002 5.57143V3.60002H5.57143C5.80812 3.60002 6 3.3314 6 3.00002C6 2.66865 5.80812 2.40002 5.57143 2.40002H3.60002V0.428571Z" fill="#263238" />
//                 </svg> Add Tasks
//         </div>
//     </div>
// }

// { (weekData?.leave !== null && weekData?.leave?.leaveFormat === 'FULLDAY' &&  weekData?.holiday === null) &&  
//     <div className='week-holiday-container'>
//         <div className='levelTaskListCountContainer'>
//             <div  className='wekkViewtaslLeaveStatuDiv' style={{ backgroundColor:  weekData?.leave.leaveFormat === 'FULLDAY'  ? '#fff' : '#E8E2F3' }} title={weekData?.leave.leaveReason} >
//                 <div className='leaveStatusDisc' style={{ backgroundColor:  weekData?.leave.leaveFormat === 'FULLDAY'  ? '#EA1616' : '#623FAD' }}></div>
//                 <div style={{ color: weekData?.leave?.leaveFormat === 'FULLDAY' ? '#EA1616' : '#263238'}}>{covertStringFormat(weekData?.leave.leaveFormat)}</div>
//             </div>
//         </div>
//     </div>
// }
