import React, { useEffect } from 'react'
import "./stylePageNotFound.css";
function PageNotFound() {
  return (
    <div className='page-not-found'>
      <div className='page-not-found-container'>
        <div className='pnp-containt-container'>
          <h1>Page Not Found</h1>
          <button onClick={() => window.history.back()} >Go Back</button>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
