import React, { useEffect, useRef, useState } from 'react';
import "./styleProjectList.css"
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ProjectInfo from '../projectsInfo/ProjectInfo';
import { setUpdateProjectFlag, setProjectToUpadte, setPrjUpdListFlag, setRoute, setPrjAllTaskFlg, setScannerAttendenceList, setScannerViewFlag, setAttendenceMontViewFlag } from '../../../../erpStore/actions';
import noProject from "../../../../assets/images/no-project.png"
import { toast } from 'react-toastify';


function ProjectList() {
    const apiUrl = useSelector((state) => state.data.apiUrl)
    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);
    const [upcoming, setUpcoming] = useState();
    const projectList = useSelector((state) => state.data.projectlist)
    const [pInfo, setPInfo] = useState(false);
    const dispatch = useDispatch();
    const [prjIndex, setPrjIndex] = useState(false)
    const [prjCrudFlag, setPrjCrudFalg] = useState(false);

    const [completed, setCompleted] = useState();
    const [firstthreeCompleted, setFristThreeCompleted] = useState([])
    const [tempCompletedList, setTempCompletedList] = useState([])

    const [onGoing, setOnGoing] = useState([]);
    const [firstthreeOngoing, setFristThreeOngoing] = useState([])
    const [tempOngoing, setTempOnGoing] = useState([])

    const [upComing, setUpComing] = useState([]);
    const [firstthreeUpcoming, setFristThreeUpComing] = useState([])
    const [tempUpcoming, setTempUpcoming] = useState([])
    const upComingRef = useRef(null)
    const [upComingCrdFlag, setUpcomingCrdFlag] = useState(false)

    const prjCrudRef = useRef(null)
    const [deletePrjAskFlag, setDeleteAskFlag] = useState(false)
    const url = useSelector((state) => state.data.apiUrl)
    const [prjId, setPrjId] = useState(null)

    const [onGoingCrd, setOngoingCrdFlag] = useState(false)
    const onGoingCrdRef = useRef(null)

    const [displPassFlag, setDisplPassFlag] = useState(false)

    const self = useSelector((state) => state.data.selfData)

    const updatePrjFlagList = useSelector((state) => state.data.updatePrjFlagList)
    const [InPrprjDueDateHover, setPrgprjDueDateHover] = useState(false)
    const [ComprjDueDateHover, setComprjDueDateHover] = useState(false)
    const [UPprjDueDateHover, setUpprjDueDateHover] = useState(false)
    const [routeS, setRouteS] = useState('')

    const [isProjectLoad, setIsProjectLoad] = useState(false)

    useEffect(() => {
        setIsProjectLoad(true)
        axios.get(`${apiUrl}project/get/all`, {
            headers: storedHeaders
        })
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    
                    let filteredDataOngoing = response.data.filter(item => item.projectStatus && item.projectStatus.projectStatus === "Ongoing");
                    setOnGoing(filteredDataOngoing);

                    const firstThreeOngoingProjects = filteredDataOngoing.slice(0, 3);
                    setFristThreeOngoing(firstThreeOngoingProjects)
                    setTempOnGoing(firstThreeOngoingProjects)

                    let filteredDataCompleted = response.data.filter(item => item.projectStatus && item.projectStatus.projectStatus === "Completed");
                    setCompleted(filteredDataCompleted);

                    const firstThreeCompletedProjects = filteredDataCompleted.slice(0, 3);
                    setFristThreeCompleted(firstThreeCompletedProjects)
                    setTempCompletedList(firstThreeCompletedProjects)

                    let filteredDataUpcoming = response.data.filter(item => item.projectStatus && item.projectStatus.projectStatus === "Upcoming");
                    setUpcoming(filteredDataUpcoming)

                    const firstThreeUpcomingProjects = filteredDataUpcoming.slice(0, 3);
                    setFristThreeUpComing(firstThreeUpcomingProjects)
                    setTempUpcoming(firstThreeUpcomingProjects)

                    dispatch(setPrjUpdListFlag(false))
                    setIsProjectLoad(false)

                } else {
                    setOnGoing([]);
                    setCompleted([]);
                    setUpcoming([]);
                }

            })
            .catch((error) => {
                setIsProjectLoad(false)
            })
    }, [projectList, updatePrjFlagList])

    const calculateDays = (start, end) => {
        var startDate = new Date(start);
        var endDate = new Date(end);

        var difference = endDate - startDate;
        var daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));
        return parseInt(daysDifference);
    }
    const departmentColor = (departmentId) => {
        if (departmentId === 1) {
            return 'rgba(166, 42, 14, 0.15)'
        } else if (departmentId === 2) {
            return 'rgba(0, 194, 255, 0.15)'
        } else if (departmentId === 3) {
            return 'rgba(255, 0, 229, 0.15)'
        } else if (departmentId === 4) {
            return 'rgba(255, 1, 92, 0.15)'
        } else {
            return 'rgba(110, 0, 182, 0.15)'
        }
    }
    const [isMore1, setMore1] = useState(false)
    const [isMore2, setMore2] = useState(false)
    const [isMore3, setMore3] = useState(false)

    const [errPass, setPassErr] = useState('Enter Password')

    useEffect(() => {
        function handleOutsideClick(event) {
            if (prjCrudRef && prjCrudRef.current && !prjCrudRef.current.contains(event.target)) {
                setPrjCrudFalg(false)
            } else if (onGoingCrdRef && onGoingCrdRef.current && !onGoingCrdRef.current.contains(event.target)) {
                setOngoingCrdFlag(false)
            } else if (upComingRef && upComingRef.current && !upComingRef.current.contains(event.target)) {
                setUpcomingCrdFlag(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])

    const [logPass, setLogPass] = useState('')

    return (
        <div className='project-list-container'>
            {pInfo === 1 ? <ProjectInfo /> :

                ((completed && completed.length > 0) || (onGoing && onGoing.length > 0) || (upcoming && upcoming.length > 0) ? <div className='project-list'>
                    <div className='project-list-part' id='current-projects'>
                        <div className='plist-label'><div className='plist-label-text'>On Going</div><div className='plist-show-more' onClick={() => {
                            setTempOnGoing(prevList => prevList.length === 3 ? onGoing : firstthreeOngoing)
                        }}>

                            {
                                onGoing && onGoing.length > 3 && (
                                    tempOngoing && tempOngoing.length === 3 ?
                                        <p>See All</p> :
                                        <p>Collapse</p>
                                )
                            }


                            {onGoing && onGoing.length > 3 && (
                                tempOngoing && tempOngoing.length === 3 ?
                                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1.5L6 6.5L1 1.5" stroke="#019F37" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg> :
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 6L6 1L1 6" stroke="#019F37" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                            )}
                        </div></div>
                        <div className='plist-container' style={{ background: " rgba(0, 102, 197, 0.15)" }}>
                            {/* <div className='plist-scroll-container'> */}

                            {tempOngoing && tempOngoing.length > 0 && tempOngoing.map((project, index) => (
                                <div className='project-container' onClick={() => {
                                    // dispatch(setProject(project))
                                    // setPInfo(1)
                                    if (onGoingCrd === false) {
                                        dispatch(setProjectToUpadte(project))
                                        dispatch(setPrjAllTaskFlg(true))
                                    }

                                }} key={index}>
                                    <div className='projectTitle-crud-hld' >
                                        <h1 title={project.projectName}>{project.projectName.length > 27 ? project.projectName.substring(0, 25) + "..." : project.projectName}</h1>
                                        {(self.role[0].role === "ROLE_SUPERADMIN" || self.role[0].role === "ROLE_ADMIN" || self.empId === project.projectManager.empId) &&
                                            <div className='threeDotPrjEdite' onClick={(e) => {
                                                e.stopPropagation()
                                                setPrjIndex(index)
                                                setOngoingCrdFlag(true)
                                            }}>
                                                <svg style={{ cursor: 'pointer' }} width="5" height="15" viewBox="0 0 3 13" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                    <circle cx="1.5" cy="11.5" r="1.5" transform="rotate(-90 1.5 11.5)" fill="#263238" fill-opacity="0.8" />
                                                    <circle cx="1.5" cy="6.5" r="1.5" transform="rotate(-90 1.5 6.5)" fill="#263238" fill-opacity="0.8" />
                                                    <circle cx="1.5" cy="1.5" r="1.5" transform="rotate(-90 1.5 1.5)" fill="#263238" fill-opacity="0.8" />
                                                </svg>
                                            </div>
                                        }
                                    </div>

                                    <div className='ptags-container'>
                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.58132 0.371307C4.83221 0.132789 5.16293 6.28555e-05 5.50651 0H7.81473C8.12908 0 8.43056 0.126894 8.65284 0.352768C8.87512 0.578641 9 0.88499 9 1.20442V3.52965C9.00001 3.71045 8.96496 3.88949 8.89686 4.05653C8.82876 4.22357 8.72894 4.37534 8.6031 4.50317L4.59927 8.57171C4.32936 8.84594 3.96331 9 3.58163 9C3.19995 9 2.8339 8.84594 2.56399 8.57171L0.421702 6.3948C0.285176 6.25611 0.177503 6.09093 0.10509 5.90908C0.0326777 5.72724 -0.00299453 5.53245 0.000196678 5.33631C0.00338788 5.14017 0.0453773 4.94669 0.123666 4.76738C0.201955 4.58807 0.314943 4.42659 0.455905 4.29256L4.58132 0.371307ZM6.79879 3.09709C7.02332 3.09709 7.23867 3.00645 7.39744 2.84511C7.55621 2.68377 7.64541 2.46495 7.64541 2.23679C7.64541 2.00862 7.55621 1.7898 7.39744 1.62846C7.23867 1.46712 7.02332 1.37648 6.79879 1.37648C6.57425 1.37648 6.35891 1.46712 6.20014 1.62846C6.04136 1.7898 5.95217 2.00862 5.95217 2.23679C5.95217 2.46495 6.04136 2.68377 6.20014 2.84511C6.35891 3.00645 6.57425 3.09709 6.79879 3.09709Z" fill="#FF8402" />
                                        </svg>
                                        <div className='pDepartment-container'>
                                            {project.projectDepartments && project.projectDepartments.length > 0 && project.projectDepartments.map((department, index) => (
                                                <div className='departmentName' style={{ background: departmentColor(department.departmentId) }} key={index}>
                                                    {department.departmentName.length > 8 ? department.departmentName.substring(0, 8) + "..." : department.departmentName}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='pUpdate-time'>
                                            <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.42885 6.6C7.52622 6.59995 7.62197 6.57595 7.707 6.53029C7.79203 6.48463 7.86352 6.41881 7.9147 6.33908C7.96588 6.25936 7.99504 6.16837 7.99942 6.07475C8.0038 5.98113 7.98325 5.88798 7.93973 5.80415L6.92484 3.85L7.93973 1.89585C7.98325 1.81202 8.0038 1.71887 7.99942 1.62525C7.99504 1.53163 7.96588 1.44064 7.9147 1.36092C7.86352 1.28119 7.79203 1.21537 7.707 1.16971C7.62197 1.12405 7.52622 1.10005 7.42885 1.1H1.1429V0.55C1.1429 0.404131 1.08269 0.264236 0.975527 0.161091C0.868359 0.0579462 0.723008 0 0.57145 0C0.419892 0 0.274542 0.0579462 0.167374 0.161091C0.0602063 0.264236 0 0.404131 0 0.55V10.45C0 10.5959 0.0602063 10.7358 0.167374 10.8389C0.274542 10.9421 0.419892 11 0.57145 11C0.723008 11 0.868359 10.9421 0.975527 10.8389C1.08269 10.7358 1.1429 10.5959 1.1429 10.45V6.6H7.42885Z" fill="#FF8402" />
                                            </svg>
                                            <div className='p' onMouseEnter={() => {
                                                setPrgprjDueDateHover(true)
                                                setPrjIndex(index)
                                            }} onMouseLeave={() => {
                                                setPrgprjDueDateHover(false)
                                            }}>
                                                {calculateDays(new Date(), project.projectEndDate) >= 0 ? `${calculateDays(new Date(), project.projectEndDate)} days left` : `${calculateDays(new Date(), project.projectEndDate) * -1  } days Overdue`}
                                                { InPrprjDueDateHover && prjIndex === index && 
                                                <div className='hoverPrjDueDte'>
                                                    {project.projectEndDate}
                                                </div>
                                                }
                                                </div>
                                        </div>
                                    </div>

                                    {/* <div className='pprogress-info'>
                                            <div className='pprogressbar-container'>
                                                <div className='p-progress' style={{ background: "rgba(255, 132, 2, 1)", width: `${0}%` }}></div>
                                            </div>
                                            <div className='pprogress-value'><span>{0}%</span> completed</div>
                                        </div> */}

                                    {prjIndex === index && onGoingCrd &&
                                        <div className='project-crud' ref={onGoingCrdRef}>
                                            <div className='project-editecrd' onClick={(e) => {
                                                e.stopPropagation()
                                                dispatch(setProjectToUpadte(project))
                                                dispatch(setUpdateProjectFlag(true))
                                                setPrjCrudFalg(false)
                                                setOngoingCrdFlag(false)
                                                dispatch(setPrjAllTaskFlg(false))
                                                dispatch(setAttendenceMontViewFlag(false))

                                            }}>
                                                <svg width="15" height="15" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.78813 0.954054C10.2602 0.492902 10.8951 0.236434 11.5551 0.240277C12.215 0.24412 12.8469 0.507963 13.3135 0.974582C13.7802 1.4412 14.0441 2.07297 14.0479 2.73285C14.0518 3.39274 13.7953 4.02753 13.3341 4.49956L12.7702 5.06336L9.22425 1.51786L9.78813 0.954054ZM8.62834 2.11454L1.88624 8.85579C1.61036 9.13197 1.41886 9.48101 1.33416 9.86205L0.594127 13.191C0.578648 13.2602 0.580917 13.3323 0.600723 13.4004C0.620528 13.4686 0.657229 13.5306 0.707419 13.5808C0.75761 13.631 0.819665 13.6677 0.887826 13.6875C0.955987 13.7073 1.02805 13.7096 1.09732 13.6941L4.40642 12.9584C4.80041 12.8707 5.16121 12.6724 5.44652 12.387L12.1743 5.66004L8.62834 2.11454Z" fill="#FF8402" />
                                                </svg>
                                                <p style={{ fontSize: '15px' }}>Edit Project</p>
                                            </div>

                                            <div className='project-editecrd' onClick={(e) => {
                                                e.stopPropagation()
                                                setDeleteAskFlag(true)
                                                setPrjId(project.projectId)
                                                dispatch(setPrjAllTaskFlg(false))
                                            }}>

                                                <svg width="15" height="15" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.5935 12.3586C1.5935 13.1814 2.30167 13.8546 3.16721 13.8546H9.46207C10.3276 13.8546 11.0358 13.1814 11.0358 12.3586V4.87862C11.0358 4.05582 10.3276 3.38262 9.46207 3.38262H3.16721C2.30167 3.38262 1.5935 4.05582 1.5935 4.87862V12.3586ZM11.0358 1.13862H9.06864L8.50997 0.607545C8.36834 0.472905 8.16375 0.390625 7.95917 0.390625H4.67011C4.46553 0.390625 4.26094 0.472905 4.11931 0.607545L3.56064 1.13862H1.5935C1.16073 1.13862 0.806641 1.47522 0.806641 1.88662C0.806641 2.29803 1.16073 2.63462 1.5935 2.63462H11.0358C11.4686 2.63462 11.8226 2.29803 11.8226 1.88662C11.8226 1.47522 11.4686 1.13862 11.0358 1.13862Z" fill="#FF8402" />
                                                </svg>

                                                <p style={{ fontSize: '15px' }}>Delete Project</p>

                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}

                            {/* </div> */}
                        </div>
                    </div>

                    <div className='project-list-part' id='completed-projects'>
                        <div className='plist-label'><div className='plist-label-text'>Completed</div><div className='plist-show-more' onClick={() => setTempCompletedList(prevList => prevList.length === 3 ? completed : firstthreeCompleted)}>
                            {
                                completed && completed.length > 3 && (
                                    tempCompletedList && tempCompletedList.length === 3 ?
                                        <p>See All</p> :
                                        <p>Collapse</p>
                                )
                            }


                            {completed && completed.length > 3 && (
                                tempCompletedList && tempCompletedList.length === 3 ?
                                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1.5L6 6.5L1 1.5" stroke="#019F37" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg> :
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 6L6 1L1 6" stroke="#019F37" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                            )}
                        </div></div>

                        <div className='plist-container' style={{ background: "rgba(1, 159, 55, 0.15)" }}>
                            {tempCompletedList && tempCompletedList.length > 0 && tempCompletedList.map((project, index) => (
                                <div className='project-container' key={index}
                                    onClick={() => {
                                        // dispatch(setProject(project))
                                        // setPInfo(1)
                                        if (prjCrudFlag === false) {
                                            dispatch(setProjectToUpadte(project))
                                            dispatch(setScannerViewFlag(false))
                                            dispatch(setPrjAllTaskFlg(true))
                                        }
                                    }}>

                                    <div className='projectTitle-crud-hld' >
                                        <h1 title={project.projectName}>{project.projectName.length > 27 ? project.projectName.substring(0, 25) + "..." : project.projectName}</h1>

                                        {(self.role[0].role === "ROLE_SUPERADMIN" || self.role[0].role === "ROLE_ADMIN" || self.empId === project.projectManager.empId) &&

                                            <svg style={{ cursor: 'pointer' }} width="5" height="15" viewBox="0 0 3 13" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => {
                                                e.stopPropagation()
                                                setPrjIndex(index)
                                                setPrjCrudFalg(true)
                                            }}>
                                                <circle cx="1.5" cy="11.5" r="1.5" transform="rotate(-90 1.5 11.5)" fill="#263238" fill-opacity="0.8" />
                                                <circle cx="1.5" cy="6.5" r="1.5" transform="rotate(-90 1.5 6.5)" fill="#263238" fill-opacity="0.8" />
                                                <circle cx="1.5" cy="1.5" r="1.5" transform="rotate(-90 1.5 1.5)" fill="#263238" fill-opacity="0.8" />
                                            </svg>
                                        }

                                    </div>
                                    <div className='ptags-container'>
                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.58132 0.371307C4.83221 0.132789 5.16293 6.28555e-05 5.50651 0H7.81473C8.12908 0 8.43056 0.126894 8.65284 0.352768C8.87512 0.578641 9 0.88499 9 1.20442V3.52965C9.00001 3.71045 8.96496 3.88949 8.89686 4.05653C8.82876 4.22357 8.72894 4.37534 8.6031 4.50317L4.59927 8.57171C4.32936 8.84594 3.96331 9 3.58163 9C3.19995 9 2.8339 8.84594 2.56399 8.57171L0.421702 6.3948C0.285176 6.25611 0.177503 6.09093 0.10509 5.90908C0.0326777 5.72724 -0.00299453 5.53245 0.000196678 5.33631C0.00338788 5.14017 0.0453773 4.94669 0.123666 4.76738C0.201955 4.58807 0.314943 4.42659 0.455905 4.29256L4.58132 0.371307ZM6.79879 3.09709C7.02332 3.09709 7.23867 3.00645 7.39744 2.84511C7.55621 2.68377 7.64541 2.46495 7.64541 2.23679C7.64541 2.00862 7.55621 1.7898 7.39744 1.62846C7.23867 1.46712 7.02332 1.37648 6.79879 1.37648C6.57425 1.37648 6.35891 1.46712 6.20014 1.62846C6.04136 1.7898 5.95217 2.00862 5.95217 2.23679C5.95217 2.46495 6.04136 2.68377 6.20014 2.84511C6.35891 3.00645 6.57425 3.09709 6.79879 3.09709Z" fill="#FF8402" />
                                        </svg>
                                        <div className='pDepartment-container'>
                                            {project.projectDepartments && project.projectDepartments.length > 0 && project.projectDepartments.map((department, index) => (
                                                <div className='departmentName' style={{ background: departmentColor(department.departmentId) }} key={index}>
                                                    {department.departmentName.length > 8 ? department.departmentName.substring(0, 8) + "..." : department.departmentName}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='pUpdate-time'>
                                            <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.42885 6.6C7.52622 6.59995 7.62197 6.57595 7.707 6.53029C7.79203 6.48463 7.86352 6.41881 7.9147 6.33908C7.96588 6.25936 7.99504 6.16837 7.99942 6.07475C8.0038 5.98113 7.98325 5.88798 7.93973 5.80415L6.92484 3.85L7.93973 1.89585C7.98325 1.81202 8.0038 1.71887 7.99942 1.62525C7.99504 1.53163 7.96588 1.44064 7.9147 1.36092C7.86352 1.28119 7.79203 1.21537 7.707 1.16971C7.62197 1.12405 7.52622 1.10005 7.42885 1.1H1.1429V0.55C1.1429 0.404131 1.08269 0.264236 0.975527 0.161091C0.868359 0.0579462 0.723008 0 0.57145 0C0.419892 0 0.274542 0.0579462 0.167374 0.161091C0.0602063 0.264236 0 0.404131 0 0.55V10.45C0 10.5959 0.0602063 10.7358 0.167374 10.8389C0.274542 10.9421 0.419892 11 0.57145 11C0.723008 11 0.868359 10.9421 0.975527 10.8389C1.08269 10.7358 1.1429 10.5959 1.1429 10.45V6.6H7.42885Z" fill="#FF8402" />
                                            </svg>
                                                <div className='p' onMouseEnter={() => {
                                                setComprjDueDateHover(true)
                                                setPrjIndex(index)
                                            }} onMouseLeave={ () => {
                                                setComprjDueDateHover(false)
                                            }}>
                                                {calculateDays(new Date(), project.projectEndDate) >= 0 ? `${calculateDays(new Date(), project.projectEndDate)} days left` : `${calculateDays(new Date(), project.projectEndDate) * -1  } days Overdue`}
                                                { ComprjDueDateHover && prjIndex === index && 
                                                <div className='hoverPrjDueDte'>
                                                    {project.projectEndDate}
                                                </div>
                                                }
                                                </div>
                                                
                                        </div>
                                    </div>
                                    {/* <div className='pprogress-info'>
                                            <div className='pprogressbar-container'>
                                                <div className='p-progress' style={{ background: "rgba(255, 132, 2, 1)", width: `${0}%` }}></div>
                                            </div>
                                            <div className='pprogress-value'><span>{0}%</span> completed</div>
                                        </div> */}
                                    {prjIndex === index && prjCrudFlag &&
                                        <div className='project-crud' ref={prjCrudRef}>
                                            <div className='project-editecrd' onClick={(e) => {
                                                e.stopPropagation()
                                                dispatch(setUpdateProjectFlag(true))
                                                dispatch(setProjectToUpadte(project))
                                                dispatch(setAttendenceMontViewFlag(false))
                                                setPrjCrudFalg(false)
                                            }}>
                                                <svg width="15" height="15" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.78813 0.954054C10.2602 0.492902 10.8951 0.236434 11.5551 0.240277C12.215 0.24412 12.8469 0.507963 13.3135 0.974582C13.7802 1.4412 14.0441 2.07297 14.0479 2.73285C14.0518 3.39274 13.7953 4.02753 13.3341 4.49956L12.7702 5.06336L9.22425 1.51786L9.78813 0.954054ZM8.62834 2.11454L1.88624 8.85579C1.61036 9.13197 1.41886 9.48101 1.33416 9.86205L0.594127 13.191C0.578648 13.2602 0.580917 13.3323 0.600723 13.4004C0.620528 13.4686 0.657229 13.5306 0.707419 13.5808C0.75761 13.631 0.819665 13.6677 0.887826 13.6875C0.955987 13.7073 1.02805 13.7096 1.09732 13.6941L4.40642 12.9584C4.80041 12.8707 5.16121 12.6724 5.44652 12.387L12.1743 5.66004L8.62834 2.11454Z" fill="#FF8402" />
                                                </svg>
                                                <p style={{ fontSize: '15px' }}>Edit Project</p>

                                            </div>

                                            <div className='project-editecrd' onClick={(e) => {
                                                e.stopPropagation()
                                                setDeleteAskFlag(true)
                                                setPrjId(project.projectId)
                                            }}>

                                                <svg width="15" height="15" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.5935 12.3586C1.5935 13.1814 2.30167 13.8546 3.16721 13.8546H9.46207C10.3276 13.8546 11.0358 13.1814 11.0358 12.3586V4.87862C11.0358 4.05582 10.3276 3.38262 9.46207 3.38262H3.16721C2.30167 3.38262 1.5935 4.05582 1.5935 4.87862V12.3586ZM11.0358 1.13862H9.06864L8.50997 0.607545C8.36834 0.472905 8.16375 0.390625 7.95917 0.390625H4.67011C4.46553 0.390625 4.26094 0.472905 4.11931 0.607545L3.56064 1.13862H1.5935C1.16073 1.13862 0.806641 1.47522 0.806641 1.88662C0.806641 2.29803 1.16073 2.63462 1.5935 2.63462H11.0358C11.4686 2.63462 11.8226 2.29803 11.8226 1.88662C11.8226 1.47522 11.4686 1.13862 11.0358 1.13862Z" fill="#FF8402" />
                                                </svg>

                                                <p style={{ fontSize: '15px' }}>Delete Project</p>

                                            </div>
                                        </div>
                                    }

                                </div>
                            ))}

                        </div>
                    </div>

                    <div className='project-list-part' id='upcoming-projects'>
                        <div className='plist-label'><div className='plist-label-text'>Upcoming</div><div className='plist-show-more' onClick={() => setTempUpcoming(prevList => prevList.length === 3 ? upComing : firstthreeUpcoming)}>
                            {
                                upComing && upComing.length > 3 && (
                                    tempUpcoming && tempUpcoming.length === 3 ?
                                        <p>See All</p> :
                                        <p>Collapse</p>
                                )
                            }


                            {upComing && upComing.length > 3 && (
                                tempUpcoming && tempUpcoming.length === 3 ?
                                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1.5L6 6.5L1 1.5" stroke="#019F37" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg> :
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 6L6 1L1 6" stroke="#019F37" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                            )}
                        </div>
                        </div>

                        <div className='plist-container' style={{ background: "rgba(255, 132, 2, 0.15)" }}>
                            {/* <div className='plist-scroll-container'> */}
                            {tempUpcoming && tempUpcoming.length > 0 && tempUpcoming.map((project, index) => (
                                <div className='project-container' key={index}
                                    onClick={() => {
                                        // dispatch(setProject(project))
                                        // setPInfo(1)
                                        if (upComingCrdFlag === false) {
                                            dispatch(setProjectToUpadte(project))
                                            dispatch(setPrjAllTaskFlg(true))
                                            dispatch(setScannerViewFlag(false))
                                        }
                                    }}>
                                    <div className='projectTitle-crud-hld' >
                                        <h1 title={project.projectName}>{project.projectName.length > 27 ? project.projectName.substring(0, 25) + "..." : project.projectName}</h1>

                                        {(self.role[0].role === "ROLE_SUPERADMIN" || self.role[0].role === "ROLE_ADMIN" || self.empId === project.projectManager.empId) &&
                                            <svg style={{ cursor: 'pointer' }} width="5" height="15" viewBox="0 0 3 13" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => {
                                                e.stopPropagation()
                                                setPrjIndex(index)
                                                setUpcomingCrdFlag(true)
                                            }}>
                                                <circle cx="1.5" cy="11.5" r="1.5" transform="rotate(-90 1.5 11.5)" fill="#263238" fill-opacity="0.8" />
                                                <circle cx="1.5" cy="6.5" r="1.5" transform="rotate(-90 1.5 6.5)" fill="#263238" fill-opacity="0.8" />
                                                <circle cx="1.5" cy="1.5" r="1.5" transform="rotate(-90 1.5 1.5)" fill="#263238" fill-opacity="0.8" />
                                            </svg>
                                        }

                                    </div>
                                    <div className='ptags-container'>
                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.58132 0.371307C4.83221 0.132789 5.16293 6.28555e-05 5.50651 0H7.81473C8.12908 0 8.43056 0.126894 8.65284 0.352768C8.87512 0.578641 9 0.88499 9 1.20442V3.52965C9.00001 3.71045 8.96496 3.88949 8.89686 4.05653C8.82876 4.22357 8.72894 4.37534 8.6031 4.50317L4.59927 8.57171C4.32936 8.84594 3.96331 9 3.58163 9C3.19995 9 2.8339 8.84594 2.56399 8.57171L0.421702 6.3948C0.285176 6.25611 0.177503 6.09093 0.10509 5.90908C0.0326777 5.72724 -0.00299453 5.53245 0.000196678 5.33631C0.00338788 5.14017 0.0453773 4.94669 0.123666 4.76738C0.201955 4.58807 0.314943 4.42659 0.455905 4.29256L4.58132 0.371307ZM6.79879 3.09709C7.02332 3.09709 7.23867 3.00645 7.39744 2.84511C7.55621 2.68377 7.64541 2.46495 7.64541 2.23679C7.64541 2.00862 7.55621 1.7898 7.39744 1.62846C7.23867 1.46712 7.02332 1.37648 6.79879 1.37648C6.57425 1.37648 6.35891 1.46712 6.20014 1.62846C6.04136 1.7898 5.95217 2.00862 5.95217 2.23679C5.95217 2.46495 6.04136 2.68377 6.20014 2.84511C6.35891 3.00645 6.57425 3.09709 6.79879 3.09709Z" fill="#FF8402" />
                                        </svg>
                                        <div className='pDepartment-container'>
                                            {project.projectDepartments && project.projectDepartments.length > 0 && project.projectDepartments.map((department, index) => (
                                                <div className='departmentName' style={{ background: departmentColor(department.departmentId) }} key={index}>
                                                    {department.departmentName.length > 8 ? department.departmentName.substring(0, 8) + "..." : department.departmentName}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='pUpdate-time'>
                                            <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.42885 6.6C7.52622 6.59995 7.62197 6.57595 7.707 6.53029C7.79203 6.48463 7.86352 6.41881 7.9147 6.33908C7.96588 6.25936 7.99504 6.16837 7.99942 6.07475C8.0038 5.98113 7.98325 5.88798 7.93973 5.80415L6.92484 3.85L7.93973 1.89585C7.98325 1.81202 8.0038 1.71887 7.99942 1.62525C7.99504 1.53163 7.96588 1.44064 7.9147 1.36092C7.86352 1.28119 7.79203 1.21537 7.707 1.16971C7.62197 1.12405 7.52622 1.10005 7.42885 1.1H1.1429V0.55C1.1429 0.404131 1.08269 0.264236 0.975527 0.161091C0.868359 0.0579462 0.723008 0 0.57145 0C0.419892 0 0.274542 0.0579462 0.167374 0.161091C0.0602063 0.264236 0 0.404131 0 0.55V10.45C0 10.5959 0.0602063 10.7358 0.167374 10.8389C0.274542 10.9421 0.419892 11 0.57145 11C0.723008 11 0.868359 10.9421 0.975527 10.8389C1.08269 10.7358 1.1429 10.5959 1.1429 10.45V6.6H7.42885Z" fill="#FF8402" />
                                            </svg>
                                       
                                                <div className='p' onMouseEnter={() => {
                                                setUpprjDueDateHover(true)
                                                setPrjIndex(index)
                                            }} onMouseLeave={ () => {
                                                setUpprjDueDateHover(false)
     
                                            }}>
                                                {calculateDays(new Date(), project.projectEndDate) >= 0 ? `${calculateDays(new Date(), project.projectEndDate)} days left` : `${calculateDays(new Date(), project.projectEndDate) * -1  } days Overdue`}
                                                { UPprjDueDateHover && prjIndex === index && 
                                                <div className='hoverPrjDueDte'>
                                                    {project.projectEndDate}
                                                </div>
                                                }
                                                </div>
                                        
                                        </div>
                                    </div>
                                    {/* <div className='pprogress-info'>
                                            <div className='pprogressbar-container'>
                                                <div className='p-progress' style={{ background: "rgba(255, 132, 2, 1)", width: `${0}%` }}></div>
                                            </div>
                                            <div className='pprogress-value'><span>{0}%</span> completed</div>
                                        </div> */}
                                    {prjIndex === index && upComingCrdFlag &&
                                        <div className='project-crud' ref={upComingRef} >
                                            <div className='project-editecrd' onClick={(e) => {
                                                e.stopPropagation()
                                                dispatch(setUpdateProjectFlag(true))
                                                dispatch(setProjectToUpadte(project))
                                                setUpcomingCrdFlag(false)
                                                dispatch(setAttendenceMontViewFlag(false))

                                            }}>
                                                <svg width="15" height="15" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.78813 0.954054C10.2602 0.492902 10.8951 0.236434 11.5551 0.240277C12.215 0.24412 12.8469 0.507963 13.3135 0.974582C13.7802 1.4412 14.0441 2.07297 14.0479 2.73285C14.0518 3.39274 13.7953 4.02753 13.3341 4.49956L12.7702 5.06336L9.22425 1.51786L9.78813 0.954054ZM8.62834 2.11454L1.88624 8.85579C1.61036 9.13197 1.41886 9.48101 1.33416 9.86205L0.594127 13.191C0.578648 13.2602 0.580917 13.3323 0.600723 13.4004C0.620528 13.4686 0.657229 13.5306 0.707419 13.5808C0.75761 13.631 0.819665 13.6677 0.887826 13.6875C0.955987 13.7073 1.02805 13.7096 1.09732 13.6941L4.40642 12.9584C4.80041 12.8707 5.16121 12.6724 5.44652 12.387L12.1743 5.66004L8.62834 2.11454Z" fill="#FF8402" />
                                                </svg>
                                                <p style={{ fontSize: '15px' }}>Edit Project</p>

                                            </div>

                                            <div className='project-editecrd' onClick={(e) => {
                                                e.stopPropagation()
                                                setDeleteAskFlag(true)
                                                setPrjId(project.projectId)
                                            }}>

                                                <svg width="15" height="15" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.5935 12.3586C1.5935 13.1814 2.30167 13.8546 3.16721 13.8546H9.46207C10.3276 13.8546 11.0358 13.1814 11.0358 12.3586V4.87862C11.0358 4.05582 10.3276 3.38262 9.46207 3.38262H3.16721C2.30167 3.38262 1.5935 4.05582 1.5935 4.87862V12.3586ZM11.0358 1.13862H9.06864L8.50997 0.607545C8.36834 0.472905 8.16375 0.390625 7.95917 0.390625H4.67011C4.46553 0.390625 4.26094 0.472905 4.11931 0.607545L3.56064 1.13862H1.5935C1.16073 1.13862 0.806641 1.47522 0.806641 1.88662C0.806641 2.29803 1.16073 2.63462 1.5935 2.63462H11.0358C11.4686 2.63462 11.8226 2.29803 11.8226 1.88662C11.8226 1.47522 11.4686 1.13862 11.0358 1.13862Z" fill="#FF8402" />
                                                </svg>

                                                <p style={{ fontSize: '15px' }}>Delete Project</p>

                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div> : 
                    isProjectLoad !== true && 
                    <div className='no-project-container'>
                        <img className='no-project-img' src={noProject} alt='/' />
                        <div className='no-project-text'>No Data,<span> Please add Projects</span></div>
                        <div className='add-project'>Add Project</div>
                    </div>
                )
            }

            {
                deletePrjAskFlag &&
                <div className="deleteTaskPopup">
                    <div className="deleteTaskSurityCard deleteTaskSurityCarddelprj">
                        <div className="deleteTaskSurityCard_heading"><p>Do you want to delete Project?</p></div>
                        <div className='passwordField'>
                            <input type={displPassFlag === false ? 'password' : 'text'} value={logPass} className='passinput' style={{borderColor: (logPass === null || logPass === '') ?  'red' : "grey"}} placeholder='Enter your login password' onChange={(e) => {
                                setLogPass(e.target.value)
                            }} />

                            {displPassFlag === false ? (
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                    setDisplPassFlag(true)
                                }}>
                                    <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            ) : (

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                    setDisplPassFlag(false)
                                }}>
                                    <g clip-path="url(#clip0_6416_1216)">
                                        <path d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M1 1L23 23" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6416_1216">
                                            <rect width="24" height="24" fill="black" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            )
                            }
                            {logPass === '' && 
                                <div className='errMsgForPass'>{errPass}</div>
                            }
                 
                        </div>
                        <div className="confirm_btn_deletetask confirm_btnprj_deletetask">
                            <button style={{ backgroundColor: 'rgba(255, 132, 2, 1)', color: 'rgba(255, 255, 255, 1)' }} onClick={() => {
                                axios.put(`${url}project/delete`, { 'projectId': prjId, 'password': logPass }, { headers: storedHeaders })
                                    .then(response => {
                                        if (response.request.status === 200) {
                                            setDeleteAskFlag(false)
                                            setPrjId(null)
                                            setLogPass('')
                                            toast.success('Project deleted successfully', { autoClose: 2000 })
                                            dispatch(setPrjUpdListFlag(true))
                                        }
                                    })
                                    .catch(error => {
                                        switch (error.response.status) {
                                            case 400:
                                                setPassErr('Enter Valid Password')
                                                setLogPass('')
                                                toast.error('Missing field', { autoClose: 2000 })
                                                setDeleteAskFlag(false)
                                                break;

                                            case 403:
                                                toast.error('Project has been used in task', { autoClose: 2000 })
                                                setDeleteAskFlag(false)
                                                break;

                                            case 404:
                                                toast.error('Project not found', { autoClose: 2000 })
                                                setDeleteAskFlag(false)
                                                break;

                                            case 422:
                                                toast.error('Enter valid password', { autoClose: 2000 })
                                                setDeleteAskFlag(false)
                                                break;

                                            default:
                                                toast.error('Enter valid password', { autoClose: 2000 })
                                                setDeleteAskFlag(false)
                                                break
                                        }
                                    })
                            }}>Delete</button>
                            <button style={{ backgroundColor: 'rgba(232, 232, 232, 1)', color: 'rgba(38, 50, 56, 1)' }}
                                onClick={() => {
                                    setDeleteAskFlag(false)
                                    setPrjId(null)
                                    setLogPass('')
                                }}
                            >Cancel</button>
                        </div>
                    </div>
                </div>
            }
            {
                isProjectLoad && <div class="loaderIdProjectsLoad">
                    <div class="projectlist-loader"></div>
                </div>
            }
        </div>
    )
}

export default ProjectList
